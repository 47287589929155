import React, { Component } from 'react';
import { Link } from 'react-router-dom'; 
import { withTranslation } from 'react-i18next'; 

import IconRifgtArrowCircle from '../../images/icons/icono_arrow_circle_white.svg';
import IconDesarrolladoresMugitu from '../../images/ilustracion_desarrolladores.png';

class MugituDesarrolladoresComponent extends Component {
	constructor(props) {
	    super(props);

	    this.state = {  
	      version: "app_mugituDesarrolladores",
	      locale: "eu_ES"
	    	  
	    };
	  }

  render() {
	  const {t} = this.props;
	  
    return(
    		 
    		<React.Fragment>
	    			<h2 className="title" id="desarrolladores">{t('keys.mugituDesarrolladores')}</h2>
	    			<p className="description">{t('keys.nuestrasFuncionalidades')}</p>
	    			<Link className="link" to="/desarrolladores">
	                    {t('keys.saberMas')}
	                    <img src={IconRifgtArrowCircle} alt=""/>
	                </Link>
	                <img className="imagen_banner" alt="" src={IconDesarrolladoresMugitu}/>
	         </React.Fragment>
    );
  }
}
export default withTranslation()(MugituDesarrolladoresComponent);
