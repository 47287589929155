import React,  { Component } from 'react'; 
import { withTranslation } from 'react-i18next';
import CookiesUniversal from 'universal-cookie';

import '../../css/App.css';
import IconClose from '../../images/icons/close.png';
import ReactGA from 'react-ga4'; 
import { useState, useEffect } from 'react';

const cookies = new CookiesUniversal();
var dir = window.location.origin+"/"+"#";
const pathname = window.location.href.substring(dir.length);
const current = new Date();
const nextYear = new Date();
var dominio = "."+document.domain;
class CookiesComponent extends Component{
	constructor(props) {
		super(props);
		this.state = {
		    version: "app_cookies",
		    cookieNecesaria: true,
		    cookieAnalisis: false,
		    locale: "eu_ES"
		};
	
		this.aceptarTodasCookie = this.aceptarTodasCookie.bind(this)
		this.configurarCookie = this.configurarCookie.bind(this) 

		nextYear.setFullYear(current.getFullYear() + 1);
		
	}
 
	componentDidMount(){

		document.getElementById("cookieInicio").style.display = "block";
		document.getElementById("cookieConfiguracion").style.display = "none";
		
		if(this.state.cookieAnalisis === true || cookies.get('cookies_analisis_bizkaitik_mugitu') === "true"){ 
			document.getElementById("cookieAnalisis").checked = true;
		}else{
			document.getElementById("cookieAnalisis").checked = false;
		}
	}
	
	goConfiguration = () =>{
		document.getElementById("cookieConfiguracion").style.display = "block";
		document.getElementById("cookieInicio").style.display = "none";  
 	}
	
	checkNotificaciones = (value) => {
		
		var checkbox = document.getElementById("cookieAnalisis");
				
		if(checkbox !== null || checkbox !== undefined){
	            if (checkbox.checked){
	               this.state.cookieAnalisis = true;
	            }else{
	            	this.state.cookieAnalisis = false;
	            }
	     } 
 	}
	
	aceptarTodasCookie(){
		if(dominio === ".localhost"){
			dominio = "localhost"
		}else if(dominio.includes('www')){
			dominio = dominio.replace('www.','');
		}
		
		cookies.set('cookies_tecnicas_bizkaitik_mugitu', 'true', {path: '/', expires: nextYear, domain: dominio});
		cookies.set('cookies_analisis_bizkaitik_mugitu', 'true', {path: '/', expires: nextYear, domain: dominio});
		ReactGA.initialize(process.env.REACT_APP_GA_ID, {gaOptions: {name: 'defaultTracker'}});
		ReactGA.set({ anonymizeIP: true }, ['defaultTracker']);
//		ReactGA.pageview(pathname, ['defaultTracker']);
		ReactGA.send({ hitType: "pageview", page: pathname, tracking: 'defaultTracker' });

		this.state.cookieAnalisis = true;
	}
	
	configurarCookie(){ 
		if(!this.state.cookieAnalisis){
			if(dominio === ".localhost"){
				dominio = "localhost"
			}else if(dominio.includes('www')){
				dominio = dominio.replace('www.','');
			}
			if (window.ga) window.ga('remove');
			cookies.remove('_ga', { path: '/', domain: dominio });
			cookies.remove('_gid', { path: '/', domain: dominio });
			cookies.remove('_gat_defaultTracker', { path: '/', domain: dominio });
			
			cookies.set('cookies_tecnicas_bizkaitik_mugitu', 'true', {path: '/', expires: nextYear, domain: dominio});
			cookies.set('cookies_analisis_bizkaitik_mugitu', 'false', {path: '/', expires: nextYear, domain: dominio});
			
			ReactGA.initialize(process.env.REACT_APP_GA_ANONYMOUS, {gaOptions: {name: 'anonymousTracker', storage:'none', clientId: localStorage.getItem('varSesionLiferayGTMncd')}});
			ReactGA.set({ anonymizeIP: true }, ['anonymousTracker']);
//			ReactGA.pageview(pathname, ['anonymousTracker']);
			ReactGA.send({ hitType: "pageview", page: pathname, tracking: 'anonymousTracker' });

			this.state.cookieAnalisis = false;
		}else{		
			cookies.set('cookies_tecnicas_bizkaitik_mugitu', 'true', {path: '/', expires: nextYear, domain: dominio});
			cookies.set('cookies_analisis_bizkaitik_mugitu', 'true', {path: '/', expires: nextYear, domain: dominio});
			ReactGA.initialize(process.env.REACT_APP_GA_ID, {gaOptions: {name: 'defaultTracker'}});
			ReactGA.set({ anonymizeIP: true }, ['defaultTracker']);
//			ReactGA.pageview(pathname, ['defaultTracker']);

			ReactGA.send({ hitType: "pageview", page: pathname, tracking: 'defaultTracker' });

			this.state.cookieAnalisis = true;
		}
	}
	
	rechazarCookie(){
		if(dominio === ".localhost"){
			dominio = "localhost"
		}else if(dominio.includes('www')){
			dominio = dominio.replace('www.','');
		}
	
		cookies.set('cookies_tecnicas_bizkaitik_mugitu', 'true', {path: '/', expires: nextYear, domain: dominio});
		cookies.set('cookies_analisis_bizkaitik_mugitu', 'false', {path: '/', expires: nextYear, domain: dominio});
		ReactGA.initialize(process.env.REACT_APP_GA_ANONYMOUS, {gaOptions: {name: 'anonymousTracker', storage:'none', clientId: localStorage.getItem('varSesionLiferayGTMncd')}});
		ReactGA.set({ anonymizeIP: true }, ['anonymousTracker']);
//		ReactGA.pageview(pathname, ['anonymousTracker']);
		ReactGA.send({ hitType: "pageview", page: pathname, tracking: 'anonymousTracker' });
	}

	render() {
		const {t} = this.props; 
		
		return(
                <div className="modal_bm modal fade modal_cookies" id="modalOpenCookies" tabIndex="-1" role="dialog" aria-labelledby="modalOpenCookies" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document">
                      <div className="modal-content">

                          <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <img src={IconClose} alt=""/>
                          </button>

                          <div className="body_modal" id="cookieInicio">
                              <h2 className="title">{t('keys.sobreNuestrasCookies')}</h2>
                              <p className="description">{t('keys.danosInformacion')}</p>
                              <button type="button" className="button_modal" onClick={() => this.aceptarTodasCookie()} data-dismiss="modal">{t('keys.aceptarCookie')}</button>
                              <button type="button" className="button_modal gray" onClick={this.goConfiguration}>{t('keys.configurarCookie')}</button>
                              <button type="button" className="button_modal white" data-dismiss="modal" onClick={this.rechazarCookie}>{t('keys.rechazarCookie')}</button>
                          </div>

                          <div className="body_modal" id="cookieConfiguracion">
                                <h2 className="title">{t('keys.sobreNuestrasCookies')}</h2>
                                <p className="description">{t('keys.danosInformacion')}</p>
                                <fieldset>
                                   <legend className="sr-only">{t('keys.configuraNotificaciones')}</legend>
                                   <ul className="list_configuracion">
                                       <li className="item">
                                            <div className="text">
                                                <h2 className="subtitle">{t('keys.cookieNecesaria')}</h2>
                                                <p className="description">{t('keys.explicacionNecesarias')}</p>
                                            </div>
                                            <label className="switch">
                                              <input id="cookiesNecesarias" type="checkbox" checked disabled/>
                                              <span className="slider"></span>
                                            </label>
                                       </li>
                                       <li className="item">
                                            <div className="text">
                                                <h2 className="subtitle">{t('keys.cookieAnalitica')}</h2>
                                                <p className="description">{t('keys.explicacionAnalitica')}</p>
                                            </div>
                                            <label className="switch">
                                              <input onClick= {() => {this.checkNotificaciones()} } id="cookieAnalisis" type="checkbox"/>
                                              <span className="slider"></span>
                                            </label>
                                       </li>
                                   </ul>
                                </fieldset>
                                <button type="button" className="button_modal" data-dismiss="modal" onClick={() => this.configurarCookie()}>{t('keys.guardarCookie')}</button>
                                <button type="button" className="button_modal white" data-dismiss="modal" aria-label="Close">{t('keys.cerrar')}</button>
                           </div>

                       </div>
                    </div>
                 </div>

	        )
	}	
}

export default withTranslation()(CookiesComponent);
