import React,  { Component } from 'react';
import { Link } from 'react-router-dom'; 
import { withTranslation } from 'react-i18next';

import '../../css/App.css';
import IconFooter from '../../images/icons/logotipo_bizkaia_color.svg';

class Footer extends Component{

	constructor(props) {
	    super(props);
	    this.state = {
	      width: window.innerWidth,
	      version:"app_footer",
	      locale: "eu_ES"
	    };
	}

	componentWillMount() {
		window.addEventListener('resize', this.handleWindowSizeChange);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowSizeChange);
	}

	handleWindowSizeChange = () => {
		this.setState({ width: window.innerWidth });
	};

	render() { 
		const { t } = this.props;
		return(
				<footer className={this.state.version} id="footer">
	    		    <img src={IconFooter} alt={t('keys.bizkaiaForuAldundia')}/>
	    			<div className="container_fullgray">
	    			    <div className="container">
	                        <p className="powered_by"><span lang="eu">{t('keys.footerBizkaiko')}</span> - <span lang="es">{t('keys.footerDiputacion')}</span></p>
	                        <ul className="nav flex-row navbar-accesibilidad main_footer">
	                            <li className="nav-item"><Link to="/accesibilidad">{t('keys.accesibilidad')}</Link></li>
	                            <li className="nav-item"><Link to="/ayuda">{t('keys.ayudaContacto')}</Link></li>
	                            <li className="nav-item"><Link to="/aviso_legal">{t('keys.avisoLegal')}</Link></li>
	                            <li className="nav-item"><Link to="/cookies">{t('keys.cookies')}</Link></li>
	                        </ul>
	                    </div>
	    			</div>
    			</footer>
		);
	}
}

export default withTranslation()(Footer);
