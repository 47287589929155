import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import $ from 'jquery';
import liferayAPI from '../../apiLiferay';
import parse from 'html-react-parser';

import Header from '../general/Header'; 
import Footer from '../general/Footer';

import ReactGA from 'react-ga4';
import CookiesUniversal from 'universal-cookie';

import AcessoRapido from '../general/AccesoRapidoComponent';
import CookiesModal from '../general/CookiesComponent';

const cookies = new CookiesUniversal();


class CookiesComponent extends Component {
	constructor(props) {
		    super(props);

		    this.state = {
		      version: "app_cookies",
		      view: "no_list",
		      showSelections: "none",
		      contenidosLiferay:[],
		      locale: "eu_ES"
		    	  
		    };
	}

	handleLanguage = (langValue) => {
		 this.setState({locale: langValue});
	}
	
	handlerModeView = (view) => {
	    this.setState({ view: view});
	}

	handlerSelectionToogle = () => {
	    if(this.state.showSelections === "none"){
	      this.setState({ showSelections: "block" });
	    }else{
	      this.setState({ showSelections: "none" });
	    }
	}	
	
	componentDidMount(){
		const cookies = new CookiesUniversal();
 		if(cookies.get('cookies_analisis_bizkaitik_mugitu') === undefined){
			   $('#modalOpenCookies').modal('show') 
	    }
 		
		this.cookies();
		if(window.scrollY !== 0){
			 window.scrollTo( 0, 0 );
		}
	
		if(document.getElementById('planificador')!== null && document.getElementById('lineas')!==null){
			 document.getElementById('planificador').classList.remove('active');document.getElementById('lineas').classList.remove('active');  
		}
		
		liferayAPI.getArticlesContenidoLiferayByKey(this.props.i18n.language, process.env.REACT_APP_ARTICLE_ID_COOKIES).then(response => 
			this.setState({contenidosLiferay: response})
		);
		 
	 }	
	
	cookies(){
		 var {t} = this.props;
		   	
		 var dir = window.location.origin+"/"+"#";
		 const pathname = window.location.href.substring(dir.length); 
		 const cookies = new CookiesUniversal();
		 var cookieAnalitica = cookies.get('cookies_analisis_bizkaitik_mugitu');
		    
		 if(cookieAnalitica ==="true"){
//			ReactGA.pageview(pathname, ['defaultTracker'], t('keys.cookies')); 
			ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.cookies'), tracking: 'defaultTracker' });

		 }else if(cookieAnalitica ==="false") {
//			ReactGA.pageview(pathname, ['anonymousTracker'], t('keys.cookies'));
			ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.cookies'), tracking: 'anonymousTracker' });

		 }
	}
	
	mostrarCookies(){
		var cookieAnalitica = cookies.get('cookies_analisis_bizkaitik_mugitu');
		 
		$('#modalOpenCookies').modal('show');
		document.getElementById("cookieConfiguracion").style.display = "block";
		document.getElementById("cookieInicio").style.display = "none";
		
		if(cookies.get('cookies_analisis_bizkaitik_mugitu') === "true"){
			document.getElementById("cookieAnalisis").checked = true;
		}else{
			document.getElementById("cookieAnalisis").checked = false;
		} 
	}
	
	render() {
		const { t } = this.props;
		return(
			 <React.Fragment>
				<Header handleToUpdate = {this.handlerModeView} action={this.handlerSelectionToogle} title={t('keys.cookies')} cabecera="true" onSelectLanguage={this.handleLanguage}/>
			    	<div className={this.state.version+ " cabecera_common"} id="main-content">
						<h1 className="title">{t('keys.cookies')}</h1>
                    </div>

                    <div className="section_page_common">
                        <div className="container">
	                        {
								this.state.contenidosLiferay !== undefined && this.state.contenidosLiferay.length !==0 &&
								parse(this.state.contenidosLiferay.contentFields[0].contentFieldValue.data)
							}
	                      <button type="button" className="button_modal small" onClick={this.mostrarCookies}>{t('keys.configurarCookie')}</button>
                        </div>
                    </div>
                    <AcessoRapido/>
                    <Footer />
			</React.Fragment>
		);
	}
}
export default withTranslation()(CookiesComponent);
