import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import Header from '../general/Header';
import Footer from '../general/Footer';

import AcessoRapido from '../general/AccesoRapidoComponent';

import DescubreBizkaia from '../home/DescubreBizkaiaComponent';
import TarjetaBizkaia from './TarjetaBizkaiaComponent';
import LugaresInteres from './LugaresInteresComponent';
import ReactGA from 'react-ga4';
import CookiesUniversal from 'universal-cookie';
import $ from 'jquery';

class DescubreBizkaiaInfoComponent extends Component {
	constructor(props) {
		super(props);
 
        
		this.state = {
			version: "descubreBizkaia",
			view: "no_list", 
		   	showSelections: "none",  
		   	locale: "eu_ES"    	  
		};
	}

	componentDidMount(){
		const cookies = new CookiesUniversal();
 		if(cookies.get('cookies_analisis_bizkaitik_mugitu') === undefined){
			   $('#modalOpenCookies').modal('show') 
	    }
 		
		this.cookies()
		if(window.scrollY !== 0){
			window.scrollTo( 0, 0 );
		}
		 
		if(document.getElementById('planificador')!==null && document.getElementById('lineas')!==null){
			document.getElementById('planificador').classList.remove('active');
			document.getElementById('lineas').classList.remove('active');  
		}	
	}
	 
	cookies(){
		 var {t} = this.props;
		   	
		 var dir = window.location.origin+"/"+"#";
		 const pathname = window.location.href.substring(dir.length); 		 
		 const cookies = new CookiesUniversal();
		 var cookieAnalitica = cookies.get('cookies_analisis_bizkaitik_mugitu');
		    
		 if(cookieAnalitica ==="true"){
				ReactGA.set({ anonymizeIP: true }, ['defaultTracker']);
//				ReactGA.pageview(pathname, ['defaultTracker'], t('keys.menuDescubre')); 
				ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.menuDescubre'), tracking: 'defaultTracker' });

				
		 }else if(cookieAnalitica ==="false") {
				ReactGA.set({ anonymizeIP: true }, ['anonymousTracker']);
//				ReactGA.pageview(pathname, ['anonymousTracker'], t('keys.menuDescubre'));
				ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.menuDescubre'), tracking: 'anonymousTracker' });

		 }
	}
	
	handleLanguage = (langValue) => {
		this.setState({locale: langValue});
	}
	  
	handlerModeView = (view) => {
   	    this.setState({ view: view}); 
   	}

   	handlerSelectionToogle = () => {
   	    if(this.state.showSelections === "none"){
   	      this.setState({ showSelections: "block" });
   	    }else{
   	      this.setState({ showSelections: "none" });
   	    }
   	  }	
   	
	render() {
		  const { t } = this.props;
		  return(
				  <React.Fragment>
					<Header handleToUpdate = {this.handlerModeView} action={this.handlerSelectionToogle} title={t('keys.menuDescubre')} cabecera="true" onSelectLanguage={this.handleLanguage}/>
					<div className="content_body descubre_bizkaia" id="main-content">

                        <div className="cabecera_descubre">
                            <div className="background"></div>
                            <h1 className="title">{parse(t('keys.descubreCabecera'))}</h1>
                        </div>

					    <div className="section_descrubre_bizkaia">
                            <div className="container">
                                <DescubreBizkaia/>
                            </div>
                        </div>

                        <div className="banner_full">
                            <div className="container">
                                <TarjetaBizkaia/>
                            </div>
                        </div>

                        <div className="section_places">
						    <LugaresInteres/>
                        </div>

					</div>
					<AcessoRapido/>
					<Footer />
				   </React.Fragment>
		  );
	  }
}
export default withTranslation()(DescubreBizkaiaInfoComponent);
