import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import liferayAPI from '../../apiLiferay';
import ReactGA from 'react-ga4';
import CookiesUniversal from 'universal-cookie';
import $ from 'jquery';

class SeccionTarifasComponent extends Component {
	constructor(props) {
	    super(props);

	    this.state = {  
	      contenidosLiferay:[],
	      locale: "eu_ES"
	    	  
	    };
	  }
	
	componentDidMount() {
		const cookies = new CookiesUniversal();
 		if(cookies.get('cookies_analisis_bizkaitik_mugitu') === undefined){
			   $('#modalOpenCookies').modal('show') 
	    }
        liferayAPI.getArticlesContenidoLiferay(this.props.i18n.language, process.env.REACT_APP_STRUCTURE_ID_OPERADOR).then(response => 
			this.setState({contenidosLiferay: response.items})
		);	
	}
	
	cookies(){
		var {t} = this.props;
		   	
		var dir = window.location.origin+"/"+"#";
		 const pathname = window.location.href.substring(dir.length); 	
		const cookies = new CookiesUniversal();
		var cookieAnalitica = cookies.get('cookies_analisis_bizkaitik_mugitu');
	
		if(cookieAnalitica ==="true"){
 			ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.menuLineas'), tracking: 'defaultTracker' });

		}else if(cookieAnalitica ==="false") {
 			ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.menuLineas'), tracking: 'anonymousTracker' });

		}
	}
	 
	
    render() { 
    	
    	const {t} = this.props;
    	
    	if(this.props.pageView){
    		this.cookies()
    	}
        let contenidoTarifas;
        const regex = /[^\w.\s]/gi;
    	if (this.state.contenidosLiferay !== undefined && this.state.contenidosLiferay.length !==0){
    	
			contenidoTarifas = this.state.contenidosLiferay.map((contenido, index) => ( 
				contenido.contentFields[1].contentFieldValue.data.replace(regex, "") === this.props.operador.toString() && 
				contenido.contentFields[2].contentFieldValue.value === this.props.routeType &&  
				<div className="content_text" key={index}>
                    {parse(contenido.contentFields[3].contentFieldValue.data)}
                </div>
			));
    	}
    return(
    		
        <React.Fragment>
	        <div className="list_horarios">
		        <div className="linea_horario">
		            <h2 className="title">
		                {this.props.operador}
		                <span className="numero_linea">{this.props.linea}</span>
		            </h2>
		            <div className="info_linea"> 
		                {
                			this.props.routeType === "3" &&
                			<span className="material-icons" title={t('keys.bus')} aria-hidden="true">directions_bus</span>
                		}
                    	{
                			this.props.routeType === "2" &&
                			<span className="material-icons" title={t('keys.tren')} aria-hidden="true">directions_transit_filled</span>
                		}
                    	{
                			this.props.routeType === "1" &&
                			<span className="material-icons" title={t('keys.metro')} aria-hidden="true">directions_railway</span>
                		}
                    	{
                			this.props.routeType === "7" &&
                			<span className="material-icons" title={t('keys.tranvia')} aria-hidden="true">tram</span>
                		}
		                <div className="text">
		                    <h3 className="subtitle">{this.props.ruta}</h3>
		                    {  
                        		this.props.seeMap !== "" && 
			                    <button type="button" className="see_map" data-toggle="modal" data-target="#openMapaLinea">
			                    	{t('keys.mapaRecorrido')}
			                    </button>
		                    }
		                </div>
		            </div>
		        </div>
		        {contenidoTarifas}
	        </div>
        </React.Fragment>
    		
    );
  }
}
export default  withTranslation()(SeccionTarifasComponent);
