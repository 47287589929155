import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import liferayAPI from '../../apiLiferay';

import IconRifgt from '../../images/icons/icon_arrow_right_red.svg';
import IconRifgtHover from '../../images/icons/icono_arrow_red_circle_hover.svg';
import IconAlert from '../../images/icons/icon-incidencia.svg';
import IconAlertHover from '../../images/icons/icon_incidencia_hover.svg';
import IconClose from '../../images/icons/icon-close.svg';
import IconInfo from '../../images/icons/icon-informacion.svg';
import IconInfoHover from '../../images/icons/icon_informacio_hover.svg';
 
class AlertasHomeComponent extends Component {
	
	ALERT_CLASS = "container_alerta linea";
	
	constructor(props) {
	    super(props);
		
	    this.state = {  
	     	version: "app_alertas",
	    	locale: "eu_ES",
	    	alertas: [],
	    	removedAlerts: [],
	    	showNotif: true,
	    	tipoAviso: ""
	    };
	    this.closeModal = this.closeModal.bind(this)
	  }
	
	componentDidMount() {
		
		const regex = /[^\w\s]/gi;
		var lineas = JSON.parse(localStorage.getItem('lineas'));
		var showAlerts = localStorage.getItem('inputIncidencias');
		var showInfo = localStorage.getItem('inputInformacion');
		
		var startDate = new Date();
		startDate.setMonth(startDate.getMonth() - 3);
		
		if((showAlerts !== null && showAlerts !== undefined) || (showInfo !== null && showInfo !== undefined)) {
			this.setState({ showNotif: false});
		}
		
		if(showAlerts !== null && showInfo === null) {
			this.state.tipoAviso = "alerta";
		} else if (showAlerts == null && showInfo !== null) {
			this.state.tipoAviso = "info";
		} else {
			this.state.tipoAviso = "";
		}
				
 		liferayAPI.getAlertasHome(this.props.i18n.language, process.env.REACT_APP_STRUCTURE_ID_ALERTAS, this.state.tipoAviso, startDate.toISOString()).then(response => {
           
            let item = null;
            let operadorAPI = "";
            let lineasAPI = "";
             
            for (let j = 0; j < response.items.length; j++) {
                item = response.items[j];
                console.log(item.contentFields[6].contentFieldValue.data);
                operadorAPI = item.contentFields[5].contentFieldValue.data; 
                lineasAPI = item.contentFields[6].contentFieldValue.data;
                if (lineasAPI === "") {
					this.state.alertas.push(item);
	        	} else {
					if (lineas !== null && lineas !== undefined) {
						for (let i = 0; i < lineas.length; i++) {
 			                if (operadorAPI !== lineas[i].operador) {
			                	//do nothing
			                } else {
			                    if (lineasAPI !== "") {
				                    var lineasAPIArray = lineasAPI.split(',');
				                   
									for (let k = 0; k < lineasAPIArray.length; k++) {
				                    	if(lineasAPIArray[k] === lineas[i].nombreLinea) {
				                    		this.state.alertas.push(item);
				                    		break;
				                    	}	
									}
				            	}
			                }
			         	}
		        	}
	        	}
            }
            
            this.setState({
                alertas: this.state.alertas
            }, function () {
                var removedAlerts = localStorage.getItem('removedAlerts');
                if (removedAlerts !== null && removedAlerts !== undefined) {
                    this.props.fromChildToParentCallback(this.state.alertas.length-JSON.parse(removedAlerts).length);
                    localStorage.setItem('unreadedAlerts', this.state.alertas.length-JSON.parse(removedAlerts).length);
                    this.setState({
                        removedAlerts: JSON.parse(removedAlerts)
                    })
                } else {
                	this.props.fromChildToParentCallback(this.state.alertas.length);
                	localStorage.setItem('unreadedAlerts', this.state.alertas.length);
                }
            })
        });			

	}
	
	formatDate (date) {
	
		var publishedDate = new Date(date);
		var options = { hour: 'numeric', minute: 'numeric'};
		
		return publishedDate.toLocaleDateString("en-GB", options).replace(',',' -');
	}
	
	closeAlert (articleId) {
		
		setTimeout(function() {document.getElementById('alert-' + articleId).style.display='none';}, 150);
    	this.setState({
    		removedAlerts: this.state.removedAlerts.concat(articleId)
    	}, function () {
	            let removedAlerts = JSON.stringify(this.state.removedAlerts);
				localStorage.setItem('removedAlerts', removedAlerts);
				this.props.fromChildToParentCallback(this.state.alertas.length-this.state.removedAlerts.length);
				localStorage.setItem('unreadedAlerts', this.state.alertas.length-this.state.removedAlerts.length);
        	}
        );
	}
	
	setReaded (articleId) {
		
    	this.setState({
    		removedAlerts: this.state.removedAlerts.concat(articleId)
    	}, function () {
	            let removedAlerts = JSON.stringify(this.state.removedAlerts);
				localStorage.setItem('removedAlerts', removedAlerts);
				this.props.fromChildToParentCallback(this.state.alertas.length-this.state.removedAlerts.length);
				localStorage.setItem('unreadedAlerts', this.state.alertas.length-this.state.removedAlerts.length);
        	}
        );
	}
	
	closeModal() {
		localStorage.setItem('inputIncidencias', true);
		this.setState({ showNotif: false});
	}
	 
render() {
	const {t} = this.props;
	
	//html para pintar los avisos
	var alerts;
	const regex = /[^\w\s]/gi;
	
	if (this.state.alertas !== undefined && this.state.alertas !== null) {
		
		alerts = this.state.alertas.map((alert, indexAlert) => (
			console.log(alert),
			<li className={this.state.removedAlerts.includes(alert.id) ? "container_alerta linea hidden": alert.contentFields[4].contentFieldValue.value=="alerta"? "container_alerta linea": "container_alerta" } 
			id={"alert-" + alert.id} key={indexAlert}>
                <button type="button" className="alertas-btn" onClick={() => this.closeAlert(alert.id)} aria-label={t('keys.cerrar')}>
                    <span className="sr-only">{t('keys.cerrar')}</span>
                    <img src={IconClose} alt=""/>
                </button>

                <div className="content_alert">
                    <a className="link_all" href={"#/alertas_detalle/"+alert.key} onClick={() => this.setReaded(alert.id)} rel="noreferrer" >
                        <span className="sr-only">{t('keys.verDetalle')}</span>
                    </a>
                    <p className="fecha">{this.formatDate(alert.datePublished)}h</p>
                    <h2 className="title">{alert.contentFields[5].contentFieldValue.data}
                    {alert.contentFields[6].contentFieldValue.data.length!==0?" | ":""}
                    {alert.contentFields[6].contentFieldValue.data.replaceAll(",",", ").length>180?alert.contentFields[6].contentFieldValue.data.replaceAll(",",", ").substring(0,180)+"...":alert.contentFields[6].contentFieldValue.data.replaceAll(",",", ")}</h2>
                    <p className="description">{alert.title}</p>
                    <p className="link">
                    	{t('keys.verDetalle')}
                        <img className="icono_notificacion normal" src={alert.contentFields[4].contentFieldValue.value=="alerta"?IconAlert:IconInfo} alt={t('keys.alerta')}/>
                        <img className="icono_notificacion hover" src={alert.contentFields[4].contentFieldValue.value=="alerta"?IconAlertHover:IconInfoHover} alt={t('keys.alerta')}/>
                        <img className="normal" src={IconRifgt} alt=""/>
                        <img className="hover" src={IconRifgtHover} alt=""/>
                    </p>
                </div>
            </li>
		));
	}
	
    return( 
    	<React.Fragment>
			<li className={this.state.showNotif ? "container_alerta": "container_alerta hidden"}>
                <button type="button" className="alertas-btn" onClick={this.closeModal} aria-label={t('keys.cerrar')}>
                    <span className="sr-only">{t('keys.cerrar')}</span>
                    <img src={IconClose} alt=""/>
                </button>

				<div className="content_alert">
                    <a className="link_all" href="/#/configuracion" rel="noreferrer" >
                        <span className="sr-only">{t('keys.configuracionNotificaciones')}</span>
                    </a>
                    <h2 className="title">{t('keys.activaTusNotificaciones')}</h2>
                    <p className="description">{t('keys.recibeInformacion')}</p>
                    <p className="link">
                        <img className="icono_notificacion normal" src={IconInfo} alt={t('keys.alerta')}/>
                        <img className="icono_notificacion hover" src={IconInfoHover} alt={t('keys.alerta')}/>
                        {t('keys.configuracionNotificaciones')}
                        <img className="normal" src={IconRifgt} alt=""/>
                        <img className="hover" src={IconRifgtHover} alt=""/>
                    </p>
				</div>
			</li>
            {!this.state.showNotif && 
            	<React.Fragment>
            		{alerts}
            	</React.Fragment>
            }
		</React.Fragment>
    );
  }
}
export default withTranslation()(AlertasHomeComponent);
