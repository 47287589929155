import React,  { Component} from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { withTranslation } from 'react-i18next';  
import $ from 'jquery';

import IconLogo from '../../images/logotipo_B_M_color.svg';
import IconAlert from '../../images/icons/notificacion_black.svg';
import IconMenu from '../../images/logotipo_B_M_white.svg';
import IconLogoDesktop from '../../images/logo_bizkaia.png';
import IconNotificationWhite from '../../images/icons/notification_white.svg';
import IconStar from '../../images/icons/icon_star.svg';
import IconHelp from '../../images/icons/icon_help.svg';
import IconList from '../../images/icons/icon_menu_mobile.svg';

import IconHome from '../../images/icons/menu_icon_inicio.svg';
import IconPlanificador from '../../images/icons/menu_icon_planificador.svg';
import IconLineasHorarios from '../../images/icons/menu_icon_horarios.svg';
import IconAlertasTransporte from '../../images/icons/menu_icon_alertas.svg';
import IconFavoritos from '../../images/icons/menu_icon_favoritos.svg';
import IconContactoAyuda from '../../images/icons/menu_icon_ayuda.svg';

import IconClose from '../../images/icons/close.png'; 

class Header extends Component { 
	 
	  constructor(props) {
	    super(props);

	    this.state = {
	      width: window.innerWidth, 
	      viewOnPhone: "list",
	      cabecera: this.props.title,
	      locale: "eu_ES",
	      alertsLength: 0
	    }; 
	   
	  }

	  handleLangChange = (lng) => {
		  this.props.onSelectLanguage(lng);
	  }
 
	  componentWillMount() {
		 window.addEventListener('resize', this.handleWindowSizeChange);   
	  }
	  
	  componentWillUnmount() {
	    window.removeEventListener('resize', this.handleWindowSizeChange);
	  }

	  handleWindowSizeChange = () => {

	    this.setState({ width: window.innerWidth });
	  };

	  switchIcon = (view) => {
		  if(view === "no-list"){
			   this.setState({viewOnPhone: "list"});
			      document.body.classList.remove('block');
		    }else if(view === "list"){
		    	this.setState({viewOnPhone: "no-list"});
		    	document.body.classList.add('block');
		    }
	  }
	  
	  multipleEvents = () => {
	    const handleToUpdate = this.props.handleToUpdate;

	    this.switchIcon(this.state.viewOnPhone); 
	    handleToUpdate(this.state.viewOnPhone)
	  }
	  
	  changeIdioma =() =>{
		  document.getElementById('subMenuIdiomas').classList.toggle("hidden");
	  }
	  
	  getRuta(){
			 let ruta; 
			 if(window.location.href.includes("planificador_detalles")){
					 ruta="/planificador_rutas/"+this.props.or+
		             "/"+this.props.de+"/"+this.props.dt+"/"+this.props.at+"/"+this.props.travelMode+
		             "/"+this.props.preference
			 }else if(window.location.href.includes("linea_detalle")){
				 ruta = "/lineas_horarios"
			 }else if(window.location.href.includes("alertas_detalle")){
				 ruta = "/alertas"
			 }else if(window.location.href.includes("parada_detalle")){
				 ruta="/linea_detalle/"+this.props.operador+"/"+this.props.linea+"/"+this.props.ruta+"/"+this.props.idRoute+"/"+this.props.routeType+"/horarios"
			 }else if(window.location.href.includes("configuracion")){
				 ruta="/alertas"
			 }else{
				 ruta = "/"		 
			 }
			 
			return ruta;
	  }
	  
	  dataLayerLogo= () =>{ 
		  window.dataLayer.push({
		    	'event': 'logoClick',
	            'eventLabel': $("h1").text()
		    });
	  }
	  
	  dataLayerAlerta () { 
			window.dataLayer.push({
	            'event': 'alertasClick',
	            'eventLabel':$("h1").text()
	        });  
	  }

	  dataLayerAlertaMovil () { 
			window.dataLayer.push({
	            'event': 'alertasClick',
	            'eventLabel':$("#cabecera_movil").text()
	        }); 
			window.location.href="/#/alertas";
	  }
	  
	  componentDidMount(){ 
	  
	  }

	  render() { 

		var unreadedAlerts = localStorage.getItem('unreadedAlerts');
		if(unreadedAlerts !== null && unreadedAlerts !== undefined) {
			this.state.alertsLength = unreadedAlerts;
		} else {
			this.state.alertsLength = this.props.alertas;
		}
	 	
	    const { width } = this.state;
	    const desktopWidth = 992;
	    const isMobile = width < desktopWidth;
	 	
		const { t, i18n } = this.props; 
		const limit = 2;
		const changeLanguage = lng => {
		    i18n.changeLanguage(lng.substring(0, limit)); 	    
		    this.setState({
	        	locale: lng
	        },this.handleLangChange(lng));
		  
		    if(document.getElementById('subMenuIdiomas')!==null){ 
			   document.getElementById('subMenuIdiomas').classList.add("hidden")
		   }else{
			   this.setState({viewOnPhone: "list"});
			   document.body.classList.remove('block');
		   }
		   window.location.reload();
		};  
 
	    if (isMobile) {
	    	
	 
	      return(
	    		  <header className="app_header" id="header">
	    		  <Link className="jump_content" to={{pathname:window.location.hash.replace("#",""), hash:"#main-content"}} rel="noreferrer">
			      {t('keys.saltarContenido')}</Link>
	
		              <div className="header-logo"> 
		                <Link className="home" to="/" onClick={this.dataLayerLogo} style={this.props.title === "" ? {display: 'block'} : {display: 'none'}}>
		                  <h1>
		                  	<img className="logo" alt={t('keys.menuBizkaitikMugitu')} src={IconLogo} />
		                    <span className="sr-only">{t('keys.menuBizkaitikMugitu')}</span>
		                  </h1>
		                </Link> 
		                
			            <Link className="inside" to={this.getRuta()} style={this.props.title === "" ? {display: 'none'} : {display: 'flex'}}>
				  				<span className="material-icons icon_back" aria-hidden="true">chevron_left</span>
				  				<span className="sr-only">{t('keys.volver')}</span>		
				  		</Link>
				  			<h1 style={this.props.title === "" ? {display: 'none'} : {display: 'block'}} className={this.props.cabecera && "sr-only"} id="cabecera_movil">{this.props.title}</h1>
		              </div>
		              
		              <div className="actions_header">
			              	<button type="button" className="notifications" onClick={this.dataLayerAlertaMovil} data-target="alertas">
			  					<img src={IconAlert} alt=""/>
			  					<span className="sr-only">{t('keys.menuAbrir')}</span>
			  					<span className={this.props.alertas <= 0 || this.state.alertsLength <= 0 || this.state.alertsLength === undefined ? "number_notification hidden" : "number_notification"}>{this.props.alertas !== undefined ? this.props.alertas : this.state.alertsLength}</span>
			  				</button>
					  		<button type="button" className="header-menu" onClick={this.multipleEvents}>
					  			<img src={IconList} alt=""/>
					  			<span className="sr-only">{t('keys.menuAbrirPrincipal')}</span>
					  		</button>
		              </div>
		              
		              <div className="menu" id="menu" style={this.state.viewOnPhone === "no-list" ? {display: 'block'} : {display: 'none'}}>
		              	<button type="button" className="header-menu" onClick={this.multipleEvents}>
			  				<img src={IconClose} alt=""/>
			  					<span className="sr-only">{t('keys.cerrar')}</span>
			  			</button>
		              	<div className="header_menu">
		              		<p className="logo"><img src={IconMenu} alt={t('keys.menuBizkaitikMugitu')}/></p>
			            		<div className="languages">
			            			<p>{t('keys.idiomas')}</p>
			            			<ul>
			            				<li><a href={window.location.hash} className={this.props.i18n.language==='eu'?"language active":"language"} id="eu" onClick={() => {changeLanguage('eu_ES')}}>{t('keys.idiomaEU')}</a></li>
			            				<li><a href={window.location.hash} className={this.props.i18n.language==='es'?"language active":"language"} id="es" onClick={() => {changeLanguage('es_ES')}}>{t('keys.idiomaES')}</a></li>
			            			</ul>
			            			
			            		</div>
		            	</div>
		            	<div className="section_menus_mobile">
		            	<ul className="main_first">
		            		<li>
		            			<Link to="/" onClick={this.multipleEvents}>
		            				<img src={IconHome} alt="" className="icono"/>
		            				{t('keys.menuInicio')}
		            			</Link>
		            		</li>
		            		<li>
		            			<Link to="/planificador_rutas" onClick={this.multipleEvents} >
		            				<img src={IconPlanificador} alt="" className="icono"/>
		            				{t('keys.menuPlanificador')}
		            			</Link>
		            		</li>
		            		<li>
		            			<Link to="/lineas_horarios" onClick={this.multipleEvents} >
		            				<img src={IconLineasHorarios} alt="" className="icono"/>
		            				{t('keys.menuLineas')}
		            			</Link>
		            		</li>
		            		<li>
		            			<Link to="/alertas" onClick={this.multipleEvents} >
		            				<img src={IconAlertasTransporte} alt="" className="icono"/>
		            				{t('keys.menuAlertasTransporte')}
		            			</Link>
		            		</li>
		            		<li>
		            			<Link to="/mis_lineas" onClick={this.multipleEvents}>
		            				<img src={IconFavoritos} alt="" className="icono"/>
		            				{t('keys.menuMisLineas')}
		            			</Link>
		            		</li>
		            		<li>
		            			<Link to="/ayuda" onClick={this.multipleEvents} >
		            				<img src={IconContactoAyuda} alt="" className="icono"/>
		            				{t('keys.menuContacto')}
		            			</Link>
		            		</li>
		            	</ul>
	
		            	<ul className="main_second">
		            		<li>
		            			<Link to="/descubre_bizkaia" onClick={this.multipleEvents} >{t('keys.menuDescubre')}</Link>
		            		</li>
		            		<li>
		            			<Link to="/mapas" onClick={this.multipleEvents} >{t('keys.menuMapas')}</Link>
		            		</li>
		            		<li>
		            			<Link to="/titulos" onClick={this.multipleEvents} >{t('keys.menuTitulos')}</Link>
		            		</li>
		            		<li>
		            			<Link to="/desarrolladores" onClick={this.multipleEvents} >{t('keys.menuDesarrolladores')}</Link>
		            		</li>
		            	</ul>
		            	</div>
		            </div>
	              </header>
	      );
	    } else {
	         	let estilo, estiloL, estiloD, estiloB;
	         
	         	if(window.location.href.includes('planificador_rutas') || window.location.href.includes('planificador_detalles')){
	         		estilo = "active";
	         	} else if(window.location.href.includes('lineas_horarios') || window.location.href.includes('mapas') || window.location.href.includes('titulos') || 
	         			window.location.href.includes('linea_detalle')){
	         		estiloL = "active";
	         	}else if(window.location.href.includes('desarrolladores')){
	         		estiloD = "active";
	         	}else if(window.location.href.includes('descubre_bizkaia')){
	         		estiloB = "active";
	         	}
	         	
	      return(
	    		  <header className="desktop" >
	
	    		  <Link className="jump_content" to={{pathname:window.location.hash.replace("#",""), hash:"#main-content"}} rel="noreferrer">
	    		  {t('keys.saltarContenido')}</Link>
	
	                <div className="header_logo">
		                {
		    			  this.props.title==="" &&
		               
		                	<h1>
			                	<Link to="/" onClick={this.dataLayerLogo}>
			                    	<img className="logo" src={IconLogoDesktop} alt=""/>
			                    		<span className="sr-only">{t('keys.menuBizkaitikMugitu')}</span>
			                    </Link>
		                    </h1>
		                }
		                {
		      			  this.props.title!=="" &&
		      			  <p>
		  	                	<Link to="/" onClick={this.dataLayerLogo}>
		  	                    	<img className="logo" src={IconLogoDesktop} alt=""/>
		  	                    		<span className="sr-only">{t('keys.menuBizkaitikMugitu')}</span>
		  	                    </Link>
		                    </p>
		                 }   
	                  </div>
	                  <div className="pre_header">
	                        <div className="container">
	                            <ul className="list_items">
	                                <li>
	                                    <Link to="/mis_lineas">
	                                        <img className="logo" src={IconStar} alt=""/>
	                                        {t('keys.menuMisLineas')} 
	                                    </Link>
	                                </li>
	                                <li>
	                                    <Link to="/ayuda">
	                                        <img className="logo" src={IconHelp} alt=""/>
	                                        {t('keys.ayuda')} 
	                                    </Link>
	                                </li>
	                            </ul>
	
	                            <div className="section_languages">
	                                <button type="button" onClick={this.changeIdioma}>{this.props.i18n.language==='es'?'ES':'EU'}<span className="material-icons select">arrow_drop_down</span></button>
	                                <ul className="select_languages hidden" id="subMenuIdiomas">
	                                    <li>
	                                    	<a href={window.location.hash} className="language" onClick={() => changeLanguage('es_ES')}>{t('keys.idiomaES')}</a>
	                                    </li>
	                                    <li>
	                                    	<a href={window.location.hash} className="language" onClick={() => changeLanguage('eu_ES')}>{t('keys.idiomaEU')}</a>
	                                    </li>
	                                </ul>
	                            </div> 
	                        </div>
	                  </div>
	                  <div className="main_first">
	                        <div className="container">
	                       
	                            <ul className="list_main" >
	                                <li>
	                                
	                                <Link to="/planificador_rutas" className={estilo}>
	                                {t('keys.menuPlanificadorEscritorio')}
	                                    </Link>
	                                </li>
	                                <li>
	                                    <button type="button" onClick={() => document.getElementById('subMenuLineas').classList.toggle('hidden')} className={estiloL}>
	                                      {t('keys.menuLineas')}
	                                    </button>
	                                    <ul className="sub_menu hidden" id="subMenuLineas">
	                                        <li>
	                                            <Link to="/lineas_horarios">
	                                            {t('keys.menuLineas')}
	                                            </Link>
	                                        </li>
	                                        <li>
	                                            <Link to="/mapas">
	                                            {t('keys.menuMapas')}
	                                            </Link>
	                                        </li>
	                                        <li>
	                                            <Link to="/titulos">
	                                            {t('keys.menuTitulos')}
	                                            </Link>
	                                        </li>
	                                    </ul>
	                                </li>
	                                <li>
	                                    <Link to="/descubre_bizkaia" id="descubre" className={estiloB}>
	                                    {t('keys.menuDescubre')}
	                                    </Link>
	                                </li>
	                                <li>
	                                    <Link to="/desarrolladores" className={estiloD}>
	                                    {t('keys.menuDesarrolladores')}
	                                    </Link>
	                                </li>
	                                <li>
	                                    <Link to="/alertas" className="icono" id="alertas" onClick={this.dataLayerAlerta}>
	                                        <img src={IconNotificationWhite} alt="" className="icono"/>
	                                        <span className="sr-only">{t('keys.notificaciones')}</span>
	                                        <span className={this.props.alertas <= 0 || this.state.alertsLength <= 0 || this.state.alertsLength === undefined ? "number_notification hidden" : "number_notification"}>{this.props.alertas !== undefined ? this.props.alertas : this.state.alertsLength}</span>
	                                    </Link>
	                                </li>
	                            </ul>
	                        </div>
	                  </div>
	              </header>
	      );
	    }
	  }
}export default withTranslation()(Header);
