import React  from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './i18n';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { isIE } from 'react-device-detect'
import AvisoIE from './components/avisoIE/AvisoIE';  

if(isIE){ 
	ReactDOM.render(
			<AvisoIE/>,
			 document.getElementById('root')
	);
}else{

	ReactDOM.render(
	  <React.StrictMode>
	    <App />
	  </React.StrictMode>,
	  document.getElementById('root')
	);

	serviceWorkerRegistration.register();


	// If you want to start measuring performance in your app, pass a function
	// to log results (for example: reportWebVitals(console.log))
	// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
	reportWebVitals();

}
