const paradasAlfabeticasService: string | undefined = process.env.REACT_APP_LINEAS_PARADASALFABETICASSERVICE
const paradasSecuencialService: string | undefined = process.env.REACT_APP_LINEAS_PARADASSECUENCIALSERVICE
const horariosService: string | undefined = process.env.REACT_APP_LINEAS_HORARIOSSERVICE;
const lineasService: string | undefined = process.env.REACT_APP_LINEAS_LINEASSERVICE;
const lineasLSService: string | undefined = process.env.REACT_APP_LINEAS_LINEASLSSERVICE;

class apiLineas {

	getParadasAlfabeticas(operador:string, idRoute:string, direccion:number){
	   	return fetch(paradasAlfabeticasService,
		   	{
		   		method: 'POST',
		   		headers: {
			    	'Content-Type': 'application/json'
			    },
		   		body: JSON.stringify({"agencyName":operador, "idRoute":idRoute, "directionId":direccion})
		   	})
	   	.then((response) => response.json())
	}

	getParadasSecuencial(operador:string, linea:string, direccion:number, tripHeadSign: string){
	   	return fetch(paradasSecuencialService,
		   	{
		   		method: 'POST',
		   		headers: {
			    	'Content-Type': 'application/json'
			    },
		   		body: JSON.stringify({"agencyName":operador,"idRoute":linea,"directionId":direccion, "tripHeadSign":tripHeadSign})
		   	})
	   	.then((response) => response.json())
	}

	getLineas(linea : string, tipo : number, stopName : string){
	   	return fetch(lineasService,
		   	{
		   		method: 'POST',
		   		headers: {
			    	'Content-Type': 'application/json'
			    },
		   		body: JSON.stringify({"texto":linea, "tipoTransporte":tipo, "stopName":stopName})
		   	})
	   	.then((response) => response.json())
	}

	getLineasLS(operadorLinea : string){
	   	return fetch(lineasLSService,
		   	{
		   		method: 'POST',
		   		headers: {
			    	'Content-Type': 'application/json'
			    },
		   		body: JSON.stringify({"Ids":operadorLinea})
		   	})
	   	.then((response) => response.json())
    }
	
	getHorarios(agency : string, routeId : string, stopId : string, directionId : number, date : date){
	   	return fetch(horariosService,
		   	{
		   		method: 'POST',
		   		headers: {
			    	'Content-Type': 'application/json'
			    },
		   		body: JSON.stringify({"agencyName":agency, "idRoute":routeId, "directionId":directionId, "stopId":stopId, "date": date })
		   	})
	   	.then((response) => response.json())
	}
}

const lineasAPI = new apiLineas();
export default lineasAPI;
