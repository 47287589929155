import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import liferayAPI from '../../apiLiferay';

import IconCardCredit from '../../images/icons/icon_card_credit.svg';
import IconRifgt from '../../images/icons/icon_arrow_right_red.svg';
import IconRifgtHover from '../../images/icons/icono_arrow_red_circle_hover.svg';

class TarjetaBizkaiaComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			version: "descubreBizkaia",
		   	contenidosLiferay:[],
		   	locale: "eu_ES"    	  
		};
	}

	handleLanguage = (langValue) => {
		this.setState({locale: langValue});
	}
	  
   	componentDidMount() {
   		if(window.scrollY !== 0){
			 window.scrollTo( 0, 0 );
		}

   		liferayAPI.getArticlesContenidoLiferayByKey(this.props.i18n.language, process.env.REACT_APP_ARTICLE_ID_TARJETA_BIZKAIA).then(response =>  
   			this.setState({contenidosLiferay: response})
   		);
	}
 
	render() {
		  const {t} = this.props;
		  let contenidoL = [];
		  let contenidoTarjetaBizkaia;
		  
		  if (this.state.contenidosLiferay !== undefined && this.state.contenidosLiferay.length !==0){
			  contenidoL.push(this.state.contenidosLiferay)
			  contenidoTarjetaBizkaia = contenidoL.map((contenido, index) => (
					<li className="item" key={index}>
					  	<div className="item_head" id={"heading"+index}>		 
					  		<h3 className="subtitle">
					  			<button type="button" id={"button"+index} className="collapsed" data-toggle="collapse" data-target={"#bbc"+ index} aria-expanded={this.state.open} aria-controls={"bbc"+index} >
						    		<span className="text">{contenido.contentFields[0].contentFieldValue.data}</span>
						    		<span className="material-icons arrow" aria-hidden="true">expand_more</span>
							   	</button>	
						    </h3>	
						</div>
						<div id={"bbc"+index} className="content_body collapse" aria-labelledby={"heading"+index} data-parent="#accordion" >
							<div className="item_body">
								{parse(contenido.contentFields[4].contentFieldValue.data)}
								{
									contenido.contentFields[5].contentFieldValue.data !== "" &&
									 <a className="link_more" href={contenido.contentFields[5].contentFieldValue.data} rel="noreferrer">
										{t('keys.verMas')}
									    <img className="normal" src={IconRifgt} alt=""/>
							            <img className="hover" src={IconRifgtHover} alt=""/>
						            </a>
								}
							   
						    </div> 
						</div>
					</li>
			  )); 
		  }
		  return(
				<React.Fragment>
				  	<h2 className="title">{parse(t('keys.tarjetaBizkaiaCard'))}</h2>
				  	<img className="image" src={IconCardCredit} alt=""/>
				  	<ul id="accordion" className="section_collapse">
				  		{contenidoTarjetaBizkaia}
				  	</ul>
				  	
				   </React.Fragment>
		  );
  }
}
export default withTranslation()(TarjetaBizkaiaComponent);
