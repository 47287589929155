import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import liferayAPI from '../../apiLiferay';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

import IconRifgt from '../../images/icons/icon_arrow_right_red.svg';
import IconRifgtHover from '../../images/icons/icono_arrow_red_circle_hover.svg';
 
class OficinasClienteComponent extends Component {
	 constructor(props) {
		    super(props);

		    this.state = {
		      version: "app_ayuda",
		      numOficinas:0,
		      locale: "eu_ES"
		    	  
		    };
		  }

	handleLanguage = (langValue) => {
		 this.setState({locale: langValue});
	}
	
	componentDidMount() {
 
		
        liferayAPI.getArticlesContenidoLiferay(this.props.i18n.language, process.env.REACT_APP_STRUCTURE_ID_OFICINA_CLIENTE).then(response => this.setState({
        	numOficinas:response.items.length
        }));
	}
		
	render() {
		const { t } = this.props;
		console.log()
		return(
			 <React.Fragment> 
				<h2 className="title">{t('keys.oficinasAtencion')}</h2>
				 {
					 this.props.operador !== undefined &&
					 <Link className="link_more_static" to={{pathname:"/oficinas_info/"+this.props.operador}} id={this.props.operador}>
					 {t('keys.encuentraTuOficina')}
					    <img className="normal" src={IconRifgt} alt=""/>
	                    <img className="hover" src={IconRifgtHover} alt=""/>
		            </Link>
				 }
				 {
					 this.props.operador === undefined &&
					 <React.Fragment>
						 <p className="text_description">{t('keys.bizkaiaCuenta')} {this.state.numOficinas}{parse(t('keys.oficinasAtienden'))}</p>
						 <a className="link_more_static" href="#/oficinas_info">
		                    {t('keys.encuentraTuOficina')}
						    <img className="normal" src={IconRifgt} alt=""/>
		                    <img className="hover" src={IconRifgtHover} alt=""/>
		                 </a>
	                 </React.Fragment>
				 }
				    
			</React.Fragment>
		);
	}
}
export default withTranslation()(OficinasClienteComponent);
