import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { GoogleMap, DirectionsRenderer, Marker } from '@react-google-maps/api';
import { Link } from 'react-router-dom';

import Header from '../general/Header';
import Footer from '../general/Footer';

import GuardarLineas from '../lineas/GuardarLineaComponent';
import CompartirLinea from '../lineas/CompartirLineaComponent';
import pushWooshAPI from '../../apiPushWoosh';
import IconAlarmLine from '../../images/icons/icon_alarm_line.svg';
import IconFinTrayecto from '../../images/icons/icon_fin_trayecto.svg';
import IconDotStart from '../../images/icons/icon-dot-start.svg';
import IconDotEnd from '../../images/icons/icon-dot-end.svg';
import liferayAPI from '../../apiLiferay';
import IconOrigin from '../../images/icons/walk.svg';
 
import ReactGA from 'react-ga4';
import CookiesUniversal from 'universal-cookie'; 
import $ from 'jquery';

class PlanificadorDetalleComponent extends Component {
	
	constructor(props) {
	    super(props);
 
	    this.state = {
	      version: "app_planificadorDetalle",
	      view: "no_list",
	      classBlock:"",
	      showSelections: "none",
	      locale: "eu_ES"

		};

		this.state = {
			directions: null,
			transitMode: '',
			origin: this.props.match.params.or,
			destination: this.props.match.params.de,
			directionsRoute: null,
			directionIndex: 0,
			addresses:[],
			resultados: 1,
			preference: undefined,
			hasAlert: [],
			alerts: [],
			ruta:""
		}

		this.previousRoute = this.previousRoute.bind(this)
		this.nextRoute = this.nextRoute.bind(this)

	}

	handleLanguage = (langValue) => {
		this.setState({locale: langValue});
	}

	handlerModeView = (view) => {
	    this.setState({ view: view});
	    if(view==="no-list"){
			this.setState({ classBlock: "" });
		}else{
			this.setState({ classBlock: "block" });
		}
	}

	handlerModeView = (view) => {
	    this.setState({ view: view});
	}

	handlerSelectionToogle = () => {
	    if(this.state.showSelections === "none"){
	      this.setState({ showSelections: "block" });
	    }else{
	      this.setState({ showSelections: "none" });
	    }
	}

	componentDidMount() {
		const cookies = new CookiesUniversal();
 		if(cookies.get('cookies_analisis_bizkaitik_mugitu') === undefined){
			   $('#modalOpenCookies').modal('show') 
	    } 
 		
		this.cookies()
		this.searchRoute (this.props.match.params.dI, this.props.match.params.dt, this.props.match.params.at, this.props.match.params.travelMode, this.props.match.params.routePreference);
	}
	
	cookies(){
		var {t} = this.props;
	   	
		var dir = window.location.origin+"/"+"#";
		const pathname = window.location.href.substring(dir.length); 	
		const cookies = new CookiesUniversal();
		var cookieAnalitica = cookies.get('cookies_analisis_bizkaitik_mugitu');
 
		if(cookieAnalitica ==="true"){
//			ReactGA.pageview(pathname, ['defaultTracker'], t('keys.planificadorDetalle')); 
			ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.planificadorDetalle'), tracking: 'defaultTracker' });

		}else if(cookieAnalitica ==="false") {
//			ReactGA.pageview(pathname, ['anonymousTracker'], t('keys.planificadorDetalle'));
			ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.planificadorDetalle'), tracking: 'anonymousTracker' });

		}
	}	

	searchRoute (id, departureTime, arrivalTime, travelMode, preferences) {
		var preference;
		if(preferences === "undefined"){
			preference = undefined
		}else{
			preference = preferences
		}
		if(departureTime){
			departureTime= new Date(departureTime);
		}

		if(arrivalTime){
			arrivalTime = new Date(arrivalTime);
		}

		var transitModes = [];

		if(travelMode !== undefined){
			var valores = travelMode.split(",")
			valores.forEach(element => transitModes.push(element));
		}

		var transitOptions= {}
		if(travelMode==="undefined"){
			transitOptions= {
					departureTime: departureTime,
					arrivalTime: arrivalTime,
					routingPreference: preference
				}
		}else{
			 transitOptions= {
					departureTime: departureTime,
					arrivalTime: arrivalTime,
					modes: transitModes,
					routingPreference: preference
				}
		}

		if (this.state.origin !== '' && this.state.destination !== '') {

			const google = window.google;
			const directionService = new google.maps.DirectionsService()
			directionService.route({
				origin: this.state.origin,
				destination: this.state.destination,
				travelMode: google.maps.TravelMode.TRANSIT,
				transitOptions: transitOptions,
				provideRouteAlternatives: true
			}, (response, status) => {
				console.log(response);
				if (status === google.maps.DirectionsStatus.OK) {
					this.setState(
						() => ({
							directions: response,
							directionsRoute: response.routes[id],
							directionIndex: parseInt(this.props.match.params.dI),
							resultados:2
						}), function () {
							if (this.state.directionsRoute != null) { 
								this.setState({
									addresses: Array(this.state.directionsRoute.legs[0].steps.length).fill(""),
									hasAlert: Array(this.state.directionsRoute.legs[0].steps.length).fill(false),
									alerts: Array(this.state.directionsRoute.legs[0].steps.length).fill("")
								})
								for (var i = 0; i < this.state.directionsRoute.legs[0].steps.length; i++) {
									console.log(this.state.directionsRoute.legs[0].steps[i])
									this.calculateAddress(this.state.directionsRoute, i);
									var lineName = "";
									
									if (this.state.directionsRoute.legs[0].steps[i].travel_mode === 'TRANSIT' && this.state.directionsRoute.legs[0].steps[i].transit.line.vehicle.type === 'BUS') {
										lineName = this.state.directionsRoute.legs[0].steps[i].transit.line.short_name;
										this.checkAlert(lineName, i);
									} else if (this.state.directionsRoute.legs[0].steps[i].travel_mode === 'TRANSIT') {
										lineName = this.state.directionsRoute.legs[0].steps[i].transit.line.name;
										this.checkAlert(lineName, i);
									}
							   	}
							}
							console.log(this.state.directionsRoute.legs[0])
	                    });
				}else if(status === google.maps.DirectionsStatus.ZERO_RESULTS){
					this.setState(
							() => ({
								resultados:0
							})
					)
				}
			})
		}
	}

	calculateAddress = (directionsRoute, i) => {
		const google = window.google;
		var geocoder = new google.maps.Geocoder;
		console.log(directionsRoute.legs[0].steps[i]);
		geocoder.geocode({'location': directionsRoute.legs[0].steps[i].start_location}, (results, status) => {
        	if (status === 'OK') {
	        	if (results[0]) {
	            	this.state.addresses.splice(i, 1, results[0].formatted_address);
	            	this.setState({
	            		addresses: this.state.addresses
	            	});
	            } else {
	            	console.log('No results found in detail info calculate address');

	            }
          	} else {
            	console.log('Geocoder failed due to: ' + status);
          	}
   		});
	}

	previousRoute() {
	
		this.state.directionIndex =  this.state.directionIndex - 1;
		this.state.directionsRoute = this.state.directions.routes[this.state.directionIndex];
		window.location='#/planificador_detalles/'+this.state.directionIndex+'/'+this.props.match.params.or+'/'+this.props.match.params.de+'/'+this.props.match.params.dt+'/'+this.props.match.params.at+'/'+this.props.match.params.travelMode+'/'+this.props.match.params.routePreference;
		this.cookies()
	}
	
	nextRoute() {
	
		this.state.directionIndex =  this.state.directionIndex + 1;
		this.state.directionsRoute = this.state.directions.routes[this.state.directionIndex];
		window.location='#/planificador_detalles/'+this.state.directionIndex+'/'+this.props.match.params.or+'/'+this.props.match.params.de+'/'+this.props.match.params.dt+'/'+this.props.match.params.at+'/'+this.props.match.params.travelMode+'/'+this.props.match.params.routePreference;
		this.cookies()
 		 
	}

	saveLine = (directionsRoute) => {
		console.log(window.location.href)
        var lineas = localStorage.getItem('lineas');
        var setLineas = new Set();

        if(lineas !== null && lineas !== undefined) {
       		var linesObject = JSON.parse(lineas);

       		linesObject.forEach(element => setLineas.add(JSON.stringify(element)));
       		console.log(setLineas);
       		localStorage.removeItem('lineas');
        }

        var lineObject = {
            operador: "",
            nombreLinea: ""
        }

        directionsRoute.legs[0].steps.forEach(function(element) {

            if (element.travel_mode === "TRANSIT") {

                lineObject = {};

                if(element.transit.line.agencies[0].name !== null || element.transit.line.agencies[0].name !== undefined){
                    if(element.transit.line.agencies[0].name === "Renfe Cercanias" || element.transit.line.agencies[0].name === "Leintz Garraioak SL" || element.transit.line.agencies[0].name === "FEVE" || element.transit.line.agencies[0].name === "Metro Bilbao"){
                        lineObject.operador = element.transit.line.agencies[0].name;
                        lineObject.nombreLinea = element.transit.line.name;
                    }else{
                        lineObject.operador = element.transit.line.agencies[0].name;
                        lineObject.nombreLinea = element.transit.line.short_name;
                    }
                }

                if (lineObject.nombreLinea.trim() !== "")
                    setLineas.add(JSON.stringify(lineObject));
            }
        });

		var arrayLineas = [];
		setLineas.forEach(x => arrayLineas.push(x));

        localStorage.setItem('lineas', '[' + arrayLineas + ']');
        pushWooshAPI.setPushWooshTags();
	}
	
	checkAlert(line, i) {
	    liferayAPI.checkAlerta(this.props.i18n.language, process.env.REACT_APP_SCTRUCTURE_ID_ALERTAS, line).then(response => {
			if(response.items.length > 0) {
				this.state.hasAlert.splice(i, 1, true);
				this.state.alerts.splice(i, 1, response.items[0]);
            	console.log("this.state.hasAlert:  " + this.state.hasAlert);
            	this.setState({
            		hasAlert: this.state.hasAlert,
            		alerts: this.state.alerts
            	});
			}
		});
	}
	
	render() {
		
		const {t} = this.props;
		let instructions; 
    	
		const styles = [
            {
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            }
        ];

		//html para pintar las pasos
		if (this.state.directionsRoute !== undefined && this.state.directionsRoute !== null) {
			instructions = this.state.directionsRoute.legs[0].steps.map((step, index) => ( 
					
				<li className="item" key={index}>
				 
				{
					step.travel_mode === 'WALKING' && index===0 &&
					<React.Fragment>
						<div className="mark icon_walk">
							<span className="material-icons" title="Walking" aria-hidden="true">directions_walk</span>
							<div className="line"></div>
						</div>

						<div className="content">
							<h3 className="title">{t('keys.tuUbicacion')}</h3>
							<p className="description">{t('keys.camina')} {step.duration.text} ({step.distance.text})</p>
							{
								this.state.directionsRoute.legs[0].departure_time !== undefined &&

								<p className="time">{this.state.directionsRoute.legs[0].departure_time.text}</p>
							}
							{
								this.state.directionsRoute.legs[0].departure_time === undefined &&

								<p className="time">{this.state.directionsRoute.legs[0].duration.text}</p>
							}
							
						</div>
					</React.Fragment>
				}
				{
					step.travel_mode === 'WALKING' && index!==0 &&
					<React.Fragment>
						<div className="mark icon_walk">
							<span className="material-icons" title="Walking" aria-hidden="true">directions_walk</span>
							<div className="line"></div>
						</div>

						<div className="content">
							<h3 className="title">{this.state.addresses[index]}</h3>
							<p className="description">{t('keys.camina')} {step.duration.text} ({step.distance.text})</p>
							<p className="time">{this.state.directionsRoute.legs[0].steps[index-1].transit.arrival_time.text}</p>
						</div>
					</React.Fragment>
				}
				{
					step.travel_mode === 'TRANSIT' && step.transit.line.vehicle.type === 'SUBWAY' &&
					<React.Fragment>
					<div className="mark icon_metro">
						<span className="material-icons" title={t('keys.metro')} aria-hidden="true">directions_railway</span>
						<div className="line">
							<span className="circle"><img src={IconDotStart} alt=""/></span>
							<span className="circle_complet"><img src={IconDotEnd} alt=""/></span>
						</div>
					</div>
					<div className="content">
						<div className="header">
							<h3 className="title">{step.transit.line.name}</h3>
							<p className="line">
								{step.transit.headsign}
								{
									this.state.hasAlert[index] &&
									<Link to={"/alertas_detalle/"+this.state.alerts[index].key}>
										<img className={this.state.hasAlert[index] ? "" : "hidden"} src={IconAlarmLine} alt=""/>
									</Link>
								}
							</p>
						</div>
						<p className="subtitle">{step.transit.departure_stop.name}</p>
						<p className="button_select">
							{step.transit.num_stops} {t('keys.paradas')} | {step.duration.text}
						</p>
						<p className="subtitle">{step.transit.arrival_stop.name}</p>
						<p className="time active">{step.transit.departure_time.text}</p>
					</div>
					</React.Fragment>

				}
				{	step.travel_mode === 'TRANSIT' && step.transit.line.vehicle.type === 'BUS' &&
					<React.Fragment>
						<div className="mark icon_bus">
							<span className="material-icons" title={t('keys.bus')} aria-hidden="true">directions_bus</span>
							<div className="line">
								<span className="circle"><img src={IconDotStart} alt=""/></span>
								<span className="circle_complet"><img src={IconDotEnd} alt=""/></span>
							</div>
						</div>
						<div className="content">
						<div className="header">
							<h3 className="title">{step.transit.line.agencies[0].name}</h3>
							<p className="line">
								{step.transit.line.short_name}
								{
									this.state.hasAlert[index] &&
									<Link to={"/alertas_detalle/"+this.state.alerts[index].key}>
										<img className={this.state.hasAlert[index] ? "" : "hidden"} src={IconAlarmLine} alt=""/>
									</Link>
								}							
							</p>
						</div>
						<p className="subtitle">{step.transit.departure_stop.name}</p>
						<p className="button_select">
							{step.transit.num_stops} {t('keys.paradas')} | {step.duration.text}
						</p>
						<p className="subtitle">{step.transit.headsign}</p>
						<p className="time active">{step.transit.departure_time.text}</p>
					</div>
					</React.Fragment>

				}
				{
					step.travel_mode === 'TRANSIT' && step.transit.line.vehicle.type === 'TRAM' &&
					<React.Fragment>
						<div className="mark icon_tram">
							<span className="material-icons" title={t('keys.tranvia')} aria-hidden="true">tram</span>
							<div className="line">
								<span className="circle"><img src={IconDotStart} alt=""/></span>
								<span className="circle_complet"><img src={IconDotEnd} alt=""/></span>
							</div>
						</div>
						<div className="content">
							<div className="header">
								<h3 className="title">{step.transit.line.vehicle.name}</h3>
								<p className="line">
								{step.transit.line.name}
								{
									this.state.hasAlert[index] &&
									<Link to={"/alertas_detalle/"+this.state.alerts[index].key}>
										<img className={this.state.hasAlert[index] ? "" : "hidden"} src={IconAlarmLine} alt=""/>
									</Link>
								}
								</p>
							</div>
							<p className="subtitle">{step.transit.departure_stop.name}</p>
							<p className="button_select">
								{step.transit.num_stops} {t('keys.paradas')} | {step.duration.text}
							</p>
							<p className="subtitle">{step.transit.arrival_stop.name}</p>
							<p className="time active">{step.transit.departure_time.text}</p>
						</div>
					</React.Fragment>
				}
				{
					step.travel_mode === 'TRANSIT' && step.transit.line.vehicle.type === 'FUNICULAR' &&
					<React.Fragment>
					<div className="mark icon_funi">
						<span className="material-icons" title={t('keys.tranvia')} aria-hidden="true">tram</span>
						<div className="line">
							<span className="circle"><img src={IconDotStart} alt=""/></span>
							<span className="circle_complet"><img src={IconDotEnd} alt=""/></span>
						</div>
					</div>
					<div className="content">
						<div className="header">
							<h3 className="title">{step.transit.departure_stop.name}</h3>
							<p className="line">
								{step.transit.line.name}
								{
									this.state.hasAlert[index] &&
									<Link to={"/alertas_detalle/"+this.state.alerts[index].key}>
										<img className={this.state.hasAlert[index] ? "" : "hidden"} src={IconAlarmLine} alt=""/>
									</Link>
								}
							</p>
						</div>
						<p className="subtitle">{step.transit.departure_stop.name}</p>
						<p className="button_select">
							{step.transit.num_stops} {t('keys.paradas')} | {step.duration.text}
						</p>
						<p className="subtitle">{step.transit.arrival_stop.name}</p>
						<p className="time active">{step.transit.departure_time.text}</p>
					</div>
					</React.Fragment>
				}
				{
					step.travel_mode === 'TRANSIT' && step.transit.line.vehicle.type === 'HEAVY_RAIL' &&

					<React.Fragment>
						<div className="mark icon_train">
							<span className="material-icons" title={t('keys.tren')} aria-hidden="true">directions_transit_filled</span>
							<div className="line">
								<span className="circle"><img src={IconDotStart} alt=""/></span>
								<span className="circle_complet"><img src={IconDotEnd} alt=""/></span>
							</div>
						</div>
						<div className="content">
							<div className="header">
								<h3 className="title">{step.transit.line.agencies[0].name+" "+step.transit.line.short_name}</h3>
								<p className="line">
									{step.transit.line.name}
								{
									this.state.hasAlert[index] &&
									<Link to={"/alertas_detalle/"+this.state.alerts[index].key}>
										<img className={this.state.hasAlert[index] ? "" : "hidden"} src={IconAlarmLine} alt=""/>
									</Link>
								}
								</p>
							</div>
							<p className="subtitle">{step.transit.departure_stop.name}</p> 
							<p className="button_select">
								{step.transit.num_stops} {t('keys.paradas')} | {step.duration.text}
							</p>
							<p className="subtitle">{step.transit.arrival_stop.name}</p>
							<p className="time active">{step.transit.departure_time.text}</p>
						</div>
					</React.Fragment>
				}
				</li>
			));
		}
		return(
			<React.Fragment>
				<Header handleToUpdate = {this.handlerModeView} action={this.handlerSelectionToogle} title={t('keys.planificadorDetalle')}
					or={this.props.match.params.or} de={this.props.match.params.de}
					travelMode={this.props.match.params.travelMode} dt={this.props.match.params.dt} at={this.props.match.params.at} preference={this.props.match.params.routePreference}
					onSelectLanguage={this.handleLanguage}/>
				<div className="section_detail_rute" id="main-content">
				    <div className="container">
				    <div className="row reverse">
				    {
				    	this.state.directionsRoute !== undefined && this.state.directionsRoute !== null &&
				   
				    	<div className="mobile col-12">
					    	<div className="cabecera_detail_rute">
							    <Link to={"/planificador_rutas/"+this.props.match.params.or+
							             "/"+this.props.match.params.de+"/"+this.props.match.params.dt+"/"+this.props.match.params.at+"/"+this.props.match.params.travelMode+
							             "/"+this.props.match.params.routePreference}
					             className="back_planificador">
							        <span className="material-icons">arrow_back</span>
							        <span className="sr-only">{t('keys.volver')}</span>
							    </Link>
								<div className="header">
									<h2 className="title" id="origen_destino">{this.props.match.params.or.replace(", España","")} - {this.props.match.params.de.replace(", España","")}</h2>
									<p className="time">{this.state.directionsRoute.legs[0].duration.text}</p>

								</div>
								<div className="select_time">
									{
										this.state.directionIndex >= 1 &&
										<button type="button" className="button_select" onClick={this.previousRoute}>
											{/*<span className="text">{t('keys.antes')}</span>*/}
											<span className="material-icons">chevron_left</span>
										</button>
									}
									{
										this.state.directionsRoute.legs[0].departure_time !== undefined &&
										<p className="range_hours">{this.state.directionsRoute.legs[0].departure_time.text} - {this.state.directionsRoute.legs[0].arrival_time.text}</p>
									}
									{
										this.state.directionsRoute.legs[0].departure_time === undefined &&
										<p className="range_hours">{this.state.directionsRoute.legs[0].steps[0].distance.text}</p>
									}
									{
										this.state.directionIndex < this.state.directions.routes.length-1 &&
										<button type="button" className="button_select" onClick={this.nextRoute}>
											<span className="material-icons">chevron_right</span>
											{/*<span className="text">{t('keys.despues')}</span>*/}
										</button>
									}
								</div>
							</div>
				    
				    	</div>
				    }
				        <div className="mapa_rute col-12 col-lg-8">
                            <GoogleMap
                                    mapContainerStyle={{
                                        }}
                                    center={{
                                        lat: 43.2633182,
                                        lng: -2.9685838
                                        }}
                                    zoom={15}
                            		options={{
	                            	  gestureHandling:'greedy',
	                                  mapTypeControl: false,
	                                  streetViewControl: false,
	                                  clickableIcons: true,
	                                  zoomControl: false,
									  fullscreenControl:true,
									  styles: styles
                            		}}

                                    onLoad={map => {
                                    console.log('onLoad map: ', map)
                                    }}
                                    // optional
                                    onUnmount={map => {
                                    console.log('onUnmount map: ', map)
                                    }}
                                >
                                {
			                       this.state.directions !== null &&
			                       	<React.Fragment>
			                       		<Marker
								            position={this.state.directions.routes[0].legs[0].start_location}
								            icon={IconOrigin}
							          	/>
							          	<Marker
								            position={this.state.directions.routes[0].legs[0].end_location}
								            icon={IconFinTrayecto}
							          	/>
							         </React.Fragment>
							    }
                                {
                                this.state.directions !== null &&
                                (
                                    <DirectionsRenderer
                                    // required
                                    options={{
										directions: this.state.directions,
										routeIndex: this.state.directionIndex,
										suppressMarkers: true
                                    }}
                                    />
                                )
                                }
                            </GoogleMap>
				        </div>
						{
							this.state.directionsRoute !== undefined && this.state.directionsRoute !== null &&

							<div className="steps_rute col-12 col-lg-4">
								<div className="cabecera_detail_rute">
								    <Link to={"/planificador_rutas/"+this.props.match.params.or+
								             "/"+this.props.match.params.de+"/"+this.props.match.params.dt+"/"+this.props.match.params.at+"/"+this.props.match.params.travelMode+
								             "/"+this.props.match.params.routePreference}
						             className="back_planificador">
								        <span className="material-icons">arrow_back</span>
								        <span className="sr-only">{t('keys.volver')}</span>
								    </Link>
									<div className="header">
										<h2 className="title" id="origen_destino_mobile">{this.props.match.params.or.replace(", España","")} - {this.props.match.params.de.replace(", España","")}</h2>
										<p className="time">{this.state.directionsRoute.legs[0].duration.text}</p>
										{
											 
											 this.state.directionsRoute.legs[0].steps.map((step, index) =>  
											 step.travel_mode === 'TRANSIT' && index===0 &&
				                    			<p className="sr-only" key={index} aria-hidden="true" data-rutaDetalle="ruta_detalle">{step.instructions}</p>
												 
											) 
										}
										{
											 
											 this.state.directionsRoute.legs[0].steps.map((step, index) =>  
											 step.travel_mode === 'TRANSIT' && index===1 &&
				                    			<p className="sr-only" key={index} aria-hidden="true" data-rutaDetalle="ruta_detalle">{step.instructions}</p>
												 
											) 
										}

									</div>
									<div className="select_time">
										{
											this.state.directionIndex >= 1 &&
											<button type="button" className="button_select" onClick={this.previousRoute}>
												{/*<span className="text">{t('keys.antes')}</span>*/}
												<span className="material-icons">chevron_left</span>
											</button>
										}
										{
											this.state.directionsRoute.legs[0].departure_time !== undefined &&
											<p className="range_hours">{this.state.directionsRoute.legs[0].departure_time.text} - {this.state.directionsRoute.legs[0].arrival_time.text}</p>
										}
										{
											this.state.directionsRoute.legs[0].departure_time === undefined &&
											<p className="range_hours">{this.state.directionsRoute.legs[0].steps[0].distance.text}</p>
										}
										{
											this.state.directionIndex < this.state.directions.routes.length-1 &&
											<button type="button" className="button_select" onClick={this.nextRoute}>
												<span className="material-icons">chevron_right</span>
												{/*<span className="text">{t('keys.despues')}</span>*/}
											</button>
										}
									</div>
								</div>
								<div className="content_indications">
									<ul className="list">
										{instructions}
										<li className="item">

											<div className="mark icon_end">
												<img src={IconFinTrayecto} alt=""/>
											</div>

											<div className="content">
												
												<h3 className="title">{t('keys.finTrayecto')}</h3>
												{
													this.state.directionsRoute.legs[0].departure_time !== undefined &&
													<p className="time">{this.state.directionsRoute.legs[0].arrival_time.text}</p>
												}
												{
													this.state.directionsRoute.legs[0].departure_time === undefined &&
													<p className="time">{this.state.directionsRoute.legs[0].steps[0].distance.text}</p>
												} 
											</div>

										</li>
									</ul>
								</div>
								<div className="section_save_rute">
								    <button type="button" className="button_save" type="button" data-toggle="modal" data-target="#modalOpenLineaGuardada" onClick= {() => {this.saveLine(this.state.directionsRoute)} } >{t('keys.guardarRuta')}</button>
								    <button type="button" className="button_share" type="button" data-toggle="modal" data-target="#modalOpenCompartirLinea">
			                            <span className="sr-only">{t('keys.compartirLinea')}</span>
			                            <span className="material-icons" aria-hidden="true">share</span>
			                        </button>
						    	</div>
						        <CompartirLinea section="planificador" origen={this.props.match.params.or} destino={this.props.match.params.de} />
								<GuardarLineas/>
							</div>
						}
                    </div>
				    </div>
				</div>
				<Footer />
			</React.Fragment>
		);
	}
}
export default  withTranslation()(PlanificadorDetalleComponent);
