import React, { Component } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import { withTranslation } from 'react-i18next';

import IconPlaniHome from '../../images/icons/icon_arrows_plan.svg';
import IconPlaniLotation from '../../images/icons/icon_location.svg';

class PlanificadorHomeComponent extends Component {
	
	constructor(props) {
	    super(props);
	   
	    this.state = {
	        origen: "",
	        destino: "",
	        version: "app_planificadorHome",
		    locale: "eu_ES"
		}
		
		this.autocomplete = null

    	this.onLoad = this.onLoad.bind(this)
		this.onPlaceChanged = this.onPlaceChanged.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleSubmit(event) {
		event.preventDefault();
		window.location.href = "/#/planificador_rutas/"+this.state.origen+"/"+this.state.destino;
	}

	onLoad(autocomplete) {
		console.log('autocomplete: ', autocomplete)
		this.autocomplete = autocomplete
	}

	onPlaceChanged() {
		if (this.autocomplete !== null) {
			console.log(this.autocomplete.getPlace())

			this.setState({
				origen: document.getElementById("origin").value,
				destino: document.getElementById("destination").value
			});
		} else {
			console.log('Autocomplete is not loaded yet!')
		}
	}

	onOriginChange(value) {
		this.setState({
			origen: value,
		});
	}

	onDestinationChange(value) {
		this.setState({
			destino: value,
		});
	}
	
	switchInputs() {
		this.setState({
			origen: document.getElementById("destination").value,
			destino: document.getElementById("origin").value
		});
	}
	
	getCurrentPosition(){ 
		var pos;
        if (navigator.geolocation) { 
            navigator.geolocation.getCurrentPosition(position => {
                pos = {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude
                };
                const google = window.google;
                var geocoder = new google.maps.Geocoder; 
                geocoder.geocode({'location': pos}, (results:any, status:any) => {
                    if (status === 'OK') {
                        if (results[0]) {
                            this.originPlace = results[0].formatted_address.replace(', España','');
                            this.originPlaceId = results[0].place_id;
                            this.currentDestinationId = results[0].place_id;
                            this.onOriginChange(results[0].formatted_address.replace(', España',''))
                            
                        } else {
                            console.log('No results found in rutas search header on getcurrentposition');
                        }    
                    } else {
                        console.log('Geocoder failed due to in rutas home : ' + status);
                    }
                });
            });
        }  
	}

  	render() {
  		const {t} = this.props;

		const bizkaiaRegion = {
			north: 43.459039,
			south: 42.972874,
			east: -2.425362,
			west: -3.449827
		}

		const country = {
			country: "es"
		}

		const options = {
			strictBounds: true,
			language: this.props.i18n.language
		}

		return(
			<React.Fragment>
				<form className="container_planificador" onSubmit={this.handleSubmit} action="/planificador_rutas">
                    <div className="mb-3">
                        <Autocomplete onLoad={this.onLoad} onPlaceChanged={this.onPlaceChanged} bounds={bizkaiaRegion} options={options} restrictions={country} >
                            <input type="text" id="origin" placeholder={t('keys.origen')} value={this.state.origen} onChange={e => this.onOriginChange(e.target.value)} required aria-label={t('keys.seleccioneOrigen')}/>
                        </Autocomplete>
                        <button type="button" className="button_location" onClick={() => this.getCurrentPosition()}>
                            <img src={IconPlaniLotation} alt=""/>
                            <span className="sr-only">Ubicación actual</span>
                        </button>
                    </div>
                    <div className="mb-3">
                        <Autocomplete onLoad={this.onLoad} onPlaceChanged={this.onPlaceChanged} bounds={bizkaiaRegion} options={options} restrictions={country} >
                            <input type="text" id="destination" placeholder={t('keys.destino')} value={this.state.destino} onChange={e => this.onDestinationChange(e.target.value)} required aria-label={t('keys.seleccioneDestino')}/>
                        </Autocomplete>
                    </div>
                    <button type="button" className="button_invert" onClick={this.switchInputs.bind(this)}>
                        <span className="sr-only">Invertir</span>
                        <img src={IconPlaniHome} alt=""/>
                    </button>
                    <button type="submit" className="button_buscar">{t('keys.buscarRuta')}</button>
			    
			    </form>
			</React.Fragment>
		);
  }
}
export default withTranslation()(PlanificadorHomeComponent);
