import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

import apiLineas from '../../apiLineas';
import liferayAPI from '../../apiLiferay';

import AcessoRapido from '../general/AccesoRapidoComponent';
import Header from '../general/Header';
import Footer from '../general/Footer';

import IconTodos from '../../images/icons/icon_place_all.svg';
import IconTodosRed from '../../images/icons/icon_place_all_red.svg'; 
import IconRifgt from '../../images/icons/icon_arrow_right_red.svg';
import IconRifgtHover from '../../images/icons/icono_arrow_red_circle_hover.svg';
import IconHoja from '../../images/hoja.png';

import ReactGA from 'react-ga4';
import CookiesUniversal from 'universal-cookie';
import $ from 'jquery';

class LineasInfoComponent extends Component {
	constructor(props) {
	    super(props);

	    this.state = { 

				open:false,
	    		version: "lineas",
	    		view: "no_list",
	    		showSelections: "none", 
	    		contenidosLiferay:[],
	    		tranviaLiferay: new Map(),
	    		metroLiferay: new Map(),
	    		trenLiferay: new Map(),
	    		busLiferay: new Map(),
	    		otrosLiferay: new Map(),
	    		linea:'',
	    		lineas:[],
	    		locale: "eu_ES",
	    		routeType: -1, 
	    		searchText: "",
	    		lineasFav:[],
	    		resultados:0
	    };
	    
	    this.handleSubmit = this.handleSubmit.bind(this);
	    this.searchLineas = this.searchLineas.bind(this);
	    this.checkLines = this.checkLines.bind(this);
	}

	componentDidMount(){
		const cookies = new CookiesUniversal();
 		if(cookies.get('cookies_analisis_bizkaitik_mugitu') === undefined){
			   $('#modalOpenCookies').modal('show') 
	    } 
	
		this.cookies()
		
		const regex = /[^\w.\s]/gi;
		
		if(window.scrollY !== 0){
			 window.scrollTo( 0, 0 );
		}
		
		if(document.getElementById('planificador')!==null && document.getElementById('lineas')!==null){
			 document.getElementById('planificador').classList.remove('active');
			 document.getElementById('lineas').classList.add('active');
		}	
		
		this.checkLines();
		
		liferayAPI.getArticlesContenidoLiferay(this.props.i18n.language, process.env.REACT_APP_STRUCTURE_ID_OPERADOR).then(response => 
	 	this.setState({
	 		contenidosLiferay: response.items
	 	}, function (){
	 		var contentLiferay = this.state.contenidosLiferay;
	 		
	 		for (var i = 0; i< contentLiferay.length; i++) { 
	 			var operadorL = contentLiferay[i].contentFields[1].contentFieldValue.data.replace(regex, "")
	 			var tipo = parseInt(contentLiferay[i].contentFields[2].contentFieldValue.value);
	 		 	switch (tipo) {
	 		 	
	 		    	case 0:
	 		    		this.state.tranviaLiferay.set(operadorL, contentLiferay[i].contentFields)
	 		            break;
	 		    	case 1:
	 		    		this.state.metroLiferay.set(operadorL, contentLiferay[i].contentFields)
	 		            break;
	 		    	case 2:
	 		    		this.state.trenLiferay.set(operadorL, contentLiferay[i].contentFields)
	 		    		break;
	 		    	case 3:
	 		    		this.state.busLiferay.set(operadorL, contentLiferay[i].contentFields) 
	 		            break;
	 		        default:
	 		        	this.state.otrosLiferay.set(operadorL, contentLiferay[i].contentFields) 
	 		            break;
	 		       } 
	 		}
				
	 	})); 
 
 		
		if(this.props.match.params.linea === undefined){
			document.getElementById("line").value = "";
		}else{
			document.getElementById("line").value =  this.props.match.params.linea;
			this.state.searchText=this.props.match.params.linea;
		}
	
		if(this.state.searchText === undefined){
			this.searchLineas("", this.state.routeType, "");
		}else{
			this.searchLineas(this.state.searchText, this.state.routeType, "");
		} 
	}
	
	cookies(){
		var {t} = this.props;

		var dir = window.location.origin+"/"+"#";
		 const pathname = window.location.href.substring(dir.length); 	 
		const cookies = new CookiesUniversal();
		var cookieAnalitica = cookies.get('cookies_analisis_bizkaitik_mugitu');
 
		if(cookieAnalitica ==="true"){
 			ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.menuLineas'), tracking: 'defaultTracker' });

		}else if(cookieAnalitica ==="false") {
 			ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.menuLineas'), tracking: 'anonymousTracker' });

		}
	}
	
	checkLines() {
		var lineasFav = JSON.parse(localStorage.getItem('lineas')); 
		var lineasRec = JSON.parse(localStorage.getItem('lineasRec'));
 
		var lineasConcat = (...arrays) =>[].concat(...arrays.filter(Array.isArray));
		var concated = lineasConcat(lineasFav, lineasRec);
				
		if (concated !== null && concated !== undefined) {
		    var lineasSet = new Set();
			concated.forEach(element => (element != null) && lineasSet.add(JSON.stringify(element)));
		    var lineas = [];
			lineasSet.forEach(x => lineas.push(x));
		   	lineas = JSON.parse('[' + lineas + ']');
		  	
		  	var linesString = [];
		  	
		    for (let i = 0; i < lineas.length; i++) {
		    	linesString.push(lineas[i].operador + "-" + lineas[i].nombreLinea);	
		    }
		    apiLineas.getLineasLS(linesString.toString()).then(response =>  {
	   			if (!response.hasOwnProperty('status')) {
   					this.setState({lineasFav: this.state.lineasFav.concat(response.contenido)})
   				}
	   		});
		}
	}
	
	deleteLine (lineShortName, lineLongName) {
		var lineasFav = JSON.parse(localStorage.getItem('lineas'));
	    var lineasRec = JSON.parse(localStorage.getItem('lineasRec'));
		var lineasFavLength = 0;
		var lineasRecLength = 0;
		
		if (lineasFav !== null && lineasFav !== undefined) {
			lineasFav = this.deleteLineFromArray(lineShortName, lineLongName, lineasFav);
			localStorage.setItem("lineas", JSON.stringify(lineasFav));
			lineasFavLength = lineasFav.length;
		}
		
		if (lineasRec !== null && lineasRec !== undefined) {
			lineasRec = this.deleteLineFromArray(lineShortName, lineLongName, lineasRec);
		    localStorage.setItem("lineasRec", JSON.stringify(lineasRec));
		    lineasRecLength = lineasRec.length;
	    }
	    
	    this.state.lineasFav = [];
	    
	    if (lineasFavLength + lineasRecLength > 0) {
	    	this.checkLines();
	    } else {
	    	this.setState({lineasFav: this.state.lineasFav})
	    }
	}
	
	deleteLineFromArray(lineShortName, lineLongName, array) {
	    var indexToRemove = [];
	    array.forEach((element, index) => (element.nombreLinea === lineShortName ||  element.nombreLinea === lineLongName) && indexToRemove.push(index));
	    indexToRemove.forEach(element => array.splice(element, 1));
	    return array;
	}
	
	handleLanguage = (langValue) => {
		 this.setState({locale: langValue});
	}
	
	handlerModeView = (view) => {
	    this.setState({ view: view}); 
	}

	handlerSelectionToogle = () => {
	    if(this.state.showSelections === "none"){
	      this.setState({ showSelections: "block" });
	    }else{
	      this.setState({ showSelections: "none" });
	    }
	}	
 
	handleSubmit= (event) =>  {
		event.preventDefault(); 
		this.state.searchText = document.getElementById("line").value;

		 let promises = [];
	        promises.push(this.searchLineas(this.state.searchText, this.state.routeType, ""));
	        
	        Promise.all(promises).then(values => {this.forceUpdate();});
	}

	setRouteType(routeType){ 
		var routeTypeViejo;
		if (this.state.lineas.length > 0 || routeTypeViejo !==routeType) {
	
			this.setState(()=>({routeType: routeType}));
			
			this.searchLineas(this.state.searchText, routeType, ""); 
			switch (routeType) { 
							 
			            case 0:
			            	 document.getElementById('todos').classList.remove('active');
			            	 document.getElementById('bus').classList.remove('active');
			            	 document.getElementById('metro').classList.remove('active');
			            	 document.getElementById('tren').classList.remove('active');
			            	 document.getElementById('tram').classList.add('active');  		
			                break;
			            case 1:
			            	document.getElementById('todos').classList.remove('active');
			            	 document.getElementById('bus').classList.remove('active');
			            	 document.getElementById('metro').classList.add('active');
			            	 document.getElementById('tren').classList.remove('active');
			            	 document.getElementById('tram').classList.remove('active');  	  
			                break;
			            case 2:
			            	document.getElementById('todos').classList.remove('active');
			            	 document.getElementById('bus').classList.remove('active');
			            	 document.getElementById('metro').classList.remove('active');
			            	 document.getElementById('tren').classList.add('active');
			            	 document.getElementById('tram').classList.remove('active');    	 
			                break;
			            case 3:
			            	 document.getElementById('todos').classList.remove('active');
			            	 document.getElementById('bus').classList.add('active');
			            	 document.getElementById('metro').classList.remove('active');
			            	 document.getElementById('tren').classList.remove('active');
			            	 document.getElementById('tram').classList.remove('active'); 
			            	 
			                break;
			            default:
			            	document.getElementById('todos').classList.add('active');
		            	 	document.getElementById('bus').classList.remove('active');
		            	 	document.getElementById('metro').classList.remove('active');
		            	 	document.getElementById('tren').classList.remove('active');
		            	 	document.getElementById('tram').classList.remove('active');    	
			                break;
			}
		}
		routeTypeViejo = routeType;
   	}

	searchLineas(linea, routeType) {
		
		apiLineas.getLineas(linea, routeType, "").then((response) => {
			console.log(response);
			this.setState(
					() => ({
						resultados:1,
						lineas: response.contenido,
						searchText: linea
					})
			);
			var id = window.location.hash.replace("#/lineas_horarios#ancla","")
			if(window.location.hash.includes('lineas_horarios#')){
				if(document.getElementById('button'+id.replaceAll(" ",""))!==null || document.getElementById(id.replaceAll(" ",""))!==null ){
					document.getElementById('button'+id.replaceAll(" ","")).classList.remove('collapse');
					document.getElementById(id.replaceAll(" ","")).classList.add('show');
				}
				
			} 
			if(this.state.lineas.length === 0 ){
				 this.setState(
							() => ({
								resultados:2
							})
					);
			}
		});	
	}
 
	formatOperador(operador){
		var operador;
		for(var i=0; i<this.state.lineas.length; i++){
			if(this.state.lineas[i].agencyName === operador){
				operador = this.state.lineas[i].agencyName
				
			}
		}
		return operador;
	}
	
	formatOperadorL(operadorL){
		const regex = /[^\w.\s]/gi;
		
		var operador; 
		for(var i=0; i<this.state.contenidosLiferay.length; i++){
			
			if(this.state.contenidosLiferay[i].contentFields[1].contentFieldValue.data.replace(regex, "")=== operadorL){				
				operador = this.state.contenidosLiferay[i].title;
			}
		} 
		return operador;
	}
	
	render() {
		const { t } = this.props;
 
		let lineasFav;
		var filtroAgenciasBus = new Map();
		var filtroAgenciasTren = new Map();
		var filtroAgenciasMetro = new Map();
		var filtroAgenciasTranvia = new Map();
		var filtroAgenciasOtros = new Map();

		if (this.state.lineas !== "undefined" ||this.state.lineas.length > 0) {
			this.state.noResults = false;
			for (var i = 0; i< this.state.lineas.length; i++) {
		        var operador =  this.state.lineas[i].agencyName;
		        var jsonState = this.state.lineas[i];

		        switch (this.state.lineas[i].routeType) {
		            case 0:
		            case 5:
		            case 6:
		            case 7:
		                if (!filtroAgenciasTranvia.has(operador))
		                    filtroAgenciasTranvia.set(operador, []);

		                filtroAgenciasTranvia.get(operador).push(this.state.lineas[i]);	    		
		                break;
		            case 1:
		                if (!filtroAgenciasMetro.has(operador))
		                    filtroAgenciasMetro.set(operador, []);

		                filtroAgenciasMetro.get(operador).push(this.state.lineas[i])	  
		                break;
		            case 2:
		                if (!filtroAgenciasTren.has(operador))
		                    filtroAgenciasTren.set(operador, []);

		                filtroAgenciasTren.get(operador).push(this.state.lineas[i])	 
		                break;
		            case 3:
                        if(Object.keys(jsonState).includes("routeLongName") && Object.keys(jsonState).includes("agencyName") &&
                           Object.keys(jsonState).includes("routeId") && Object.keys(jsonState).includes("routeType") &&
                           Object.keys(jsonState).includes("routeShortName")){
                            if (!filtroAgenciasBus.has(operador))
                                filtroAgenciasBus.set(operador, []);

                            filtroAgenciasBus.get(operador).push(this.state.lineas[i])
                            break;
                        }
		            default:
		                if (!filtroAgenciasOtros.has(operador))
		                    filtroAgenciasOtros.set(operador, []);

		                filtroAgenciasOtros.get(operador).push(this.state.lineas[i])	
		                break;
		        }
		    }
		}
		
		const agenciasBusLiferay = [...this.state.busLiferay.keys()]
		const agenciasBusLineas = [...filtroAgenciasBus.keys()]
 
		const agenciasMetroLiferay = [...this.state.metroLiferay.keys()]
		const agenciasMetroLineas = [...filtroAgenciasMetro.keys()]
		  
		const agenciasTrenLiferay = [...this.state.trenLiferay.keys()]
		const agenciasTrenLineas = [...filtroAgenciasTren.keys()]
		  
		const agenciasTranviaLiferay = [...this.state.tranviaLiferay.keys()]
		const agenciasTranviaLineas = [...filtroAgenciasTranvia.keys()]
	 
		var existentesBus = [];
		var noExistentesBus = []

		for (var i=0; i< agenciasBusLiferay.length; i++){
			 if(agenciasBusLineas.find(element => element === agenciasBusLiferay[i]) !== undefined){
				 existentesBus.push(agenciasBusLiferay[i])
			 }else{
				 noExistentesBus.push(agenciasBusLiferay[i])
			 }
		}
	  
		var existentesMetro = [];
		var noExistentesMetro = []
		for (var i=0; i< agenciasMetroLiferay.length; i++){
			if(agenciasMetroLineas.find(element => element === agenciasMetroLiferay[i]) !== undefined){
				existentesMetro.push(agenciasMetroLiferay[i])
			}else{
				noExistentesMetro.push(agenciasMetroLiferay[i])
			}
		}
	  
		var existentesTranvia = [];
		var noExistentesTranvia = []
		for (var i=0; i< agenciasTranviaLiferay.length; i++){
			if(agenciasTranviaLineas.find(element => element === agenciasTranviaLiferay[i]) !== undefined ){
				existentesTranvia.push(agenciasTranviaLiferay[i])
			}else{
				noExistentesTranvia.push(agenciasTranviaLiferay[i])
			}
		}
	  
		var existentesTren = [];
		var noExistentesTren = [];
		
		var agencyName, routeShortName, routeLongName, routeId, routeType;
		for (var i=0; i< agenciasTrenLiferay.length; i++){
			if(agenciasTrenLineas.find(element => element === agenciasTrenLiferay[i]) !== undefined){
				existentesTren.push(agenciasTrenLiferay[i])
			}else{
				noExistentesTren.push(agenciasTrenLiferay[i])
			}
		}
	
		 var autobus, autobusNo
		 if(existentesBus.length > 0 && this.state.resultados !== 0){
			 autobus = existentesBus.map((operador, index)=>(
					<li className="item" key={index}>
					<div className="item_head" id={"heading"+operador.replaceAll(" ","")}>
					<span className="ancla_enlace" id={"ancla"+operador.replaceAll(" ","")} ></span>
						<h3 className="subtitle">
							<button type="button" id={"button"+operador.replaceAll(" ","")} className="collapsed" data-toggle="collapse" data-target={"#"+operador.replaceAll(" ","")}
							aria-expanded={this.state.open}
							aria-controls={operador.replaceAll(" ","")}>
								<span className="text">{this.formatOperador(operador)}</span>
								<span className="material-icons arrow" aria-hidden="true">expand_more</span>
							</button>
						</h3>
				</div>
				<div id={operador.replaceAll(" ","")} className="content_body collapse" aria-labelledby={"heading"+operador.replaceAll(" ","")} >
					<div className="item_body">
	                	<div className="group_lineas">
	                    	<ul className="list_lineas recientes">
	                        {
	                        	filtroAgenciasBus.get(operador).map((linea, indexLinea)=>(
	                        			
		                            <li className="items" key={indexLinea}>
		                            {
 
                                    	linea.routeShortName !== "" &&
	                                	<Link to={"/linea_detalle/"+linea.agencyName+"/"+linea.routeShortName+"/"+linea.routeLongName.replaceAll("/","-")+"/"+linea.routeId+"/"+linea.routeType+"/horarios"}>
		                                	<p className="pre_title">{linea.agencyName}</p>
		                                    <p className="title_linea">
		                                    	<span className="material-icons" aria-hidden="true">directions_bus</span>
		                                    	{linea.routeLongName}
		                                    </p>
		                                   
		                                    	<p className="linea">
		                                    	{linea.routeShortName}
		                                    </p>
		                                    
	                                    </Link> 
		                            }
		                            {
                                    	linea.routeShortName === "" &&
	                                	<Link to={"/linea_detalle/"+linea.agencyName+"/%20/"+linea.routeLongName.replaceAll("/","-")+"/"+linea.routeId+"/"+linea.routeType+"/horarios"}>
		                                	<p className="pre_title">{linea.agencyName}</p>
		                                    <p className="title_linea">
		                                    	<span className="material-icons" aria-hidden="true">directions_bus</span>
		                                    	{linea.routeLongName}
		                                    </p> 		                                    
	                                    </Link> 
		                            }
		                            </li>
	                        			 
		                                    
	         					))
	         				 }
	         				</ul>
                        </div>
                    </div>
                </div>	                </li>
		   ));
		 }
		 
		 if(noExistentesBus.length > 0 && this.state.resultados !== 0){	 
		    autobusNo = noExistentesBus.map((operador, index)=>(
				   this.state.searchText === "" &&
				   <li className="item" key={index}>
				   <div className="item_head" id={"heading"+operador.replaceAll(" ","")}>
		   			<span className="ancla_enlace" id={"ancla"+operador.replaceAll(" ","")} ></span>
		   			<h3 className="subtitle">
		   				<button type="button" id={"button"+operador.replaceAll(" ","")} className="collapsed" data-toggle="collapse" data-target={"#"+operador.replaceAll(" ","")} aria-expanded={this.state.open} aria-controls={operador.replaceAll(" ","")} >
		   					<span className="text">{this.formatOperadorL(operador)}</span>
		   					<span className="material-icons arrow" aria-hidden="true">expand_more</span>
		   				</button>
		   			</h3>
		   		</div>
		   		<div id={operador.replaceAll(" ","")} className="content_body collapse" aria-labelledby={"heading"+operador.replaceAll(" ","")}>
		   			<div className="item_body">
		   				<div className="group_lineas">
		   					<a className="link_more mensaje" href={this.state.busLiferay.get(operador)[11].contentFieldValue.data===''?"#/lineas_horarios":this.state.busLiferay.get(operador)[11].contentFieldValue.data} target="_blank" rel="noopener noreferrer">
    					   		{t('keys.mensajeLinea1')} {this.formatOperadorL(operador)} {t('keys.mensajeLinea2')} 
    					   		<img className="normal" src={IconRifgt} alt=""/>
    					   		<img className="hover" src={IconRifgtHover} alt=""/>
    					   	</a> 
		   				
                       </div>
                   </div>
               </div>				   </li>
		   ));
		 }

	   /*{METRO}*/
	   var metro, metroNo
	   if(existentesMetro.length > 0 && this.state.resultados !== 0){
		   metro = existentesMetro.map((operador, index)=>(
		   			console.log(operador)&&
				   <li className="item" key={index}>
				   		<div className="item_head" id={operador==="Metro Bilbao" && "headingMetroBilbao"}>
				   			<span className="ancla_enlace" id={operador==="Metro Bilbao" && "anclaMetroBilbao"} ></span>
				   			<h3 className="subtitle">
				   				<button type="button" id={operador==="Metro Bilbao" && "buttonMetroBilbao"} className="collapsed" data-toggle="collapse" data-target={operador==="Metro Bilbao" && "#MetroBilbao"} aria-expanded={this.state.open} aria-controls={operador==="Metro Bilbao" && "MetroBilbao"} >
				   					<span className="text">{this.formatOperador(operador)}</span>
				   					<span className="material-icons arrow" aria-hidden="true">expand_more</span>
				   				</button>
				   			</h3>
				   		</div>
				   		<div id={operador==="Metro Bilbao" && "MetroBilbao"} className="content_body collapse" aria-labelledby={operador==="Metro Bilbao" && "headingMetroBilbao"}>
				   			<div className="item_body">
				   				<div className="group_lineas">
				   					<ul className="list_lineas recientes">
		                            {
		         					   	filtroAgenciasMetro.get(operador).map((linea, indexLinea)=>(
			         						<li className="items" key={indexLinea}>
		                                    {
		                                    	(linea.agencyName.toString()!=="FEVE" && linea.agencyName.toString()!=="Metro Bilbao") &&
		                                    	<Link to={"/linea_detalle/"+linea.agencyName+"/"+linea.routeShortName+"/"+linea.routeLongName.replaceAll("/","-")+"/"+linea.routeId+"/"+linea.routeType+"/horarios"}>
		                                        	<p className="pre_title">{linea.agencyName}</p>
		    	                                    <p className="title_linea">
		    	                                    	<span className="material-icons" aria-hidden="true">directions_railway</span>
		    	                                        	{linea.routeLongName}
		    	                                    </p>
		    	                                    {
		    	                                    	linea.routeShortName.toString()!=="" && linea.routeShortName.toString()!=='""' &&
		    	                                        <p className="linea">
		    	                                        	{linea.routeShortName}
				                                        </p>
		    	                                    }
		                                        </Link> 
		                            		}
		                            		{
		                            			(linea.agencyName.toString()==="FEVE" || linea.agencyName.toString()==="Metro Bilbao") &&    		
		                                		<Link to={"/linea_detalle/"+linea.agencyName+"/%20/"+linea.routeLongName.replaceAll("/","-")+"/"+linea.routeId+"/"+linea.routeType+"/horarios"}>
		                                        	<p className="pre_title">{linea.agencyName}</p>
			                                        <p className="title_linea">
			                                            <span className="material-icons" aria-hidden="true">directions_railway</span>
			                                            {linea.routeLongName}
			                                        </p>
			                                        {
			                                        	linea.routeShortName.toString()!=="" && linea.routeShortName.toString()!=='""' &&
			                                        	<p className="linea">
			                                        		{linea.routeShortName}
			                                            </p>
		                                        	}
		                                    	</Link> 
		                            		}
		                            		</li>
	                                	))
		                            }
		         				   	</ul>
		                        </div>
	                        </div>
	                    </div>	  
				   </li>
		  ));
	   }	   
	   
	   if(noExistentesMetro.length > 0 && this.state.resultados !== 0){
		 metroNo = noExistentesMetro.map((operador, index)=>(
				this.state.searchText === "" &&
					<li className="item" key={index}>
						<div className="item_head" id={operador==="Metro Bilbao" && "headingMetroBilbao"}>
							<span className="ancla_enlace" id={operador==="Metro Bilbao" && "anclaMetroBilbao"} ></span>
							<h3 className="subtitle">
					        	<button type="button" id={operador==="Metro Bilbao" && "buttonMetroBilbao"} className="collapsed" data-toggle="collapse" data-target={operador==="Metro Bilbao" && "#MetroBilbao"} 
					        	aria-expanded={this.state.open} aria-controls={operador==="Metro Bilbao" && operador.replaceAll(" ","")} >
					            	<span className="text">{this.formatOperadorL(operador)}</span>
					            	<span className="material-icons arrow" aria-hidden="true">expand_more</span>
				            	</button>
			            	</h3>
		            	</div>
		            	<div id={operador==="Metro Bilbao" && "MetroBilbao"} className="content_body collapse" aria-labelledby={operador==="Metro Bilbao" && "headingMetroBilbao"}>
		                	<div className="item_body">
		                        <div className="group_lineas">
		                        	<a className="link_more mensaje" href={this.state.metroLiferay.get(operador)[11].contentFieldValue.data===''?"#/lineas_horarios":this.state.metroLiferay.get(operador)[11].contentFieldValue.data} rel="noopener noreferrer">
	 					   				{t('keys.mensajeLinea1')} {this.formatOperadorL(operador)} {t('keys.mensajeLinea2')} 
	 					   				<img className="normal" src={IconRifgt} alt=""/>
	 					   				<img className="hover" src={IconRifgtHover} alt=""/>
	 					   			</a> 	                             
		                        </div>
	                        </div>
	                    </div>
					</li>
		));
	   }
	/*{TREN}*/ 
	var tren, trenNo
 	if(existentesTren.length > 0 && this.state.resultados !== 0){
		tren = existentesTren.map((operador, index)=>(		   
				<li className="item" key={index}>
					<div className="item_head" id={"heading"+operador.replaceAll(" ","")}>
						<span className="ancla_enlace" id={"ancla"+operador.replaceAll(" ","")} ></span>
						<h3 className="subtitle">
					    	<button type="button" id={"button"+operador.replaceAll(" ","")} className="collapsed" data-toggle="collapse" data-target={"#"+operador.replaceAll(" ","")} aria-expanded={this.state.open} aria-controls={operador.replaceAll(" ","")} >
					        	<span className="text">{this.formatOperador(operador)}</span>
					        	<span className="material-icons arrow" aria-hidden="true">expand_more</span>
					        </button>
					    </h3>
				    </div>
				    <div id={operador.replaceAll(" ","")} className="content_body collapse" aria-labelledby={"heading"+operador.replaceAll(" ","")}>
	                	<div className="item_body">
	                        <div className="group_lineas">
	                            <ul className="list_lineas recientes">
	                            {
	                            	filtroAgenciasTren.get(operador).map((linea, indexLinea)=>(
	                        			<li className="items" key={indexLinea}>
	                        			{
	                        				(linea.agencyName.toString()!=="FEVE" && linea.agencyName.toString()!=="Metro Bilbao") &&
	                    		      		<Link to={"/linea_detalle/"+linea.agencyName+"/"+linea.routeShortName+"/"+linea.routeLongName.replaceAll("/","-")+"/"+linea.routeId+"/"+linea.routeType+"/horarios"}>
	                        					<p className="pre_title">{linea.agencyName}</p>
		                                        <p className="title_linea">
		                                            <span className="material-icons" aria-hidden="true">directions_transit_filled</span>
		                                            {linea.routeLongName}
		                                        </p>
		                                        {
		                                        	linea.routeShortName.toString()!=="" && linea.routeShortName.toString()!=='""' &&
		                                        	<p className="linea">
		                                        		{linea.routeShortName}
		                                            </p>
		                                        }	
	                                        </Link> 
	                            		}
	                            		{
	                            			(linea.agencyName.toString()==="FEVE" || linea.agencyName.toString()==="Metro Bilbao") &&
	                                		<Link to={"/linea_detalle/"+linea.agencyName+"/%20/"+linea.routeLongName.replaceAll("/","-")+"/"+linea.routeId+"/"+linea.routeType+"/horarios"}>
	                                        	<p className="pre_title">{linea.agencyName}</p>
		                                        <p className="title_linea">
		                                            <span className="material-icons" aria-hidden="true">directions_transit_filled</span>
		                                            {linea.routeLongName}
		                                        </p>
		                                        {
		                                        	linea.routeShortName.toString()!=="" && linea.routeShortName.toString()!=='""' &&
		                                        	<p className="linea">
		                                        		{linea.routeShortName}
		                                            </p>
		                                        }
	                                        	
		                                    </Link> 
	                            		}
			                             </li>
		         					  ))
		         				   }
		         				   </ul>
		                        </div>
	                        </div>
	                    </div>  
	                </li>
		   ));
		}
		if(noExistentesTren.length > 0 && this.state.resultados !== 0){
		   trenNo = noExistentesTren.map((operador, index)=>(
				   this.state.searchText === "" &&
				   <li className="item" key={index}>
				   		<div className="item_head" id={"heading"+operador.replaceAll(" ","")}>
				   			<span className="ancla_enlace" id={"ancla"+operador.replaceAll(" ","")} ></span>
				   			<h3 className="subtitle">
						        <button type="button" id={"button"+operador.replaceAll(" ","")} className="collapsed" data-toggle="collapse" data-target={"#"+operador.replaceAll(" ","")} aria-expanded={this.state.open} aria-controls={operador.replaceAll(" ","")} >
						               <span className="text">{this.formatOperadorL(operador)}</span>
						               <span className="material-icons arrow" aria-hidden="true">expand_more</span>
						        </button>
					        </h3>
					    </div>
					    <div id={operador.replaceAll(" ","")} className="content_body collapse" aria-labelledby={"heading"+operador.replaceAll(" ","")}>
		                	<div className="item_body">
		                        <div className="group_lineas">         
		                        	<a className="link_more mensaje" href={this.state.trenLiferay.get(operador)[11].contentFieldValue.data===''?"#/lineas_horarios":this.state.trenLiferay.get(operador)[11].contentFieldValue.data} rel="noopener noreferrer">
	 					   				{t('keys.mensajeLinea1')} {this.formatOperadorL(operador)} {t('keys.mensajeLinea2')} 
	 					   				<img className="normal" src={IconRifgt} alt=""/>
	 					   				<img className="hover" src={IconRifgtHover} alt=""/>
	 					   			</a>                       
		                        </div>
		                    </div>
	                    </div>	  
				   </li>
		   ));
	   }
	   /*{TRANVIA}*/
 					   				
	   var tranvia, tranviaNo; 
 	   if(existentesTranvia.length > 0 && this.state.resultados !== 0){
		   tranvia = existentesTranvia.map((operador, index)=>(
				   <li className="item" key={index}>
				   		<div className="item_head" id={"heading"+operador.replaceAll(" ","").replaceAll(".","")+"tranvia"}>
				   			<h3 className="subtitle">
					       		<span className="ancla_enlace" id={"ancla"+operador.replaceAll(" ","").replaceAll(".","")+"tranvia"} ></span>
					       		<button type="button" id={"button"+operador.replaceAll(" ","").replaceAll(".","")+"tranvia"} className="collapsed" data-toggle="collapse" data-target={"#"+operador.replaceAll(" ","").replaceAll(".","")+"tranvia"} aria-expanded={this.state.open} aria-controls={operador.replaceAll(" ","").replaceAll(".","")+"tranvia"} >
					               <span className="text">{this.formatOperador(operador)}</span>
					               <span className="material-icons arrow" aria-hidden="true">expand_more</span>
					            </button>
					        </h3>
					    </div>
					    <div id={operador.replaceAll(" ","").replaceAll(".","")+"tranvia"} className="content_body collapse" aria-labelledby={"heading"+operador.replaceAll(" ","").replaceAll(".","")+"tranvia"}>
		                	<div className="item_body">
		                        <div className="group_lineas">
	                            	<ul className="list_lineas recientes">
		                            	{  
		         					   		filtroAgenciasTranvia.get(operador).map((linea, indexLinea)=>(
		         					   			
				                            	<li className="items"  key={indexLinea}>
				                            	{
		         					   				linea.routeShortName !== "" &&
		         					   			
			                               			<Link to={"/linea_detalle/"+linea.agencyName+"/"+linea.routeShortName+"/"+linea.routeLongName.replaceAll("/","-")+"/"+linea.routeId+"/"+linea.routeType+"/horarios"}>
				                                		<p className="pre_title">{linea.agencyName}</p>
				                                        <p className="title_linea">
				                                            <span className="material-icons" aria-hidden="true">tram</span>
				                                            {linea.routeLongName}
				                                        </p>
				                                        <p className="linea">
				                                            	{linea.routeShortName}
			                                        	</p>                                   	
			                                    	</Link> 
				                            	}
				                            	{
		         					   				linea.routeShortName === "" &&
		         					   			
			                               			<Link to={"/linea_detalle/"+linea.agencyName+"/%20/"+linea.routeLongName.replaceAll("/","-")+"/"+linea.routeId+"/"+linea.routeType+"/horarios"}>
				                                		<p className="pre_title">{linea.agencyName}</p>
				                                        <p className="title_linea">
				                                            <span className="material-icons" aria-hidden="true">tram</span>
				                                            {linea.routeLongName}
				                                        </p>                                	
			                                    	</Link> 
				                            	}
			                                	</li>
	                               			))
		                            	} 
	                            	</ul>
	                        	</div>
	                    	</div>
	                   </div>
					</li>
		   ));
	   }
	   
	   if(noExistentesTranvia.length > 0 && this.state.resultados !== 0){
		   tranviaNo = noExistentesTranvia.map((operador, index)=>(
			   this.state.searchText === "" &&
			   <li className="item" key={index}>
			   		<div className="item_head" id={"heading"+operador.replaceAll(" ","").replaceAll(".","")+"tranvia"}>
			   			<h3 className="subtitle">
				       		<span className="ancla_enlace" id={"ancla"+operador.replaceAll(" ","").replaceAll(".","")} ></span>
				       		<button type="button" id={"button"+operador.replaceAll(" ","").replaceAll(".","")+"tranvia"} className="collapsed" data-toggle="collapse" data-target={"#"+operador.replaceAll(" ","").replaceAll(".","")+"tranvia"} aria-expanded={this.state.open} aria-controls={operador.replaceAll(" ","")+"tranvia"} >
				               <span className="text">{this.formatOperadorL(operador)}</span>
				               <span className="material-icons arrow" aria-hidden="true">expand_more</span>
				            </button>
				        </h3>
			        </div>
			        <div id={operador.replaceAll(" ","").replaceAll(".","")+"tranvia"} className="content_body collapse" aria-labelledby={"heading"+operador.replaceAll(" ","").replaceAll(".","")+"tranvia"}>
	                	<div className="item_body">
	                    	<div className="group_lineas">
	                        	<a className="link_more mensaje" href={this.state.tranviaLiferay.get(operador)[11].contentFieldValue.data===''?"#/lineas_horarios":this.state.tranviaLiferay.get(operador)[11].contentFieldValue.data} rel="noopener noreferrer">
 					   				{t('keys.mensajeLinea1')} {this.formatOperadorL(operador)} {t('keys.mensajeLinea2')} 
 					   				<img className="normal" src={IconRifgt} alt=""/>
 					   				<img className="hover" src={IconRifgtHover} alt=""/>
 					   			</a> 
	                        </div>
                        </div>
                    </div>
				</li>
		   ));
	  }
 	
	   if(this.state.lineasFav.length > 0){
 			lineasFav = this.state.lineasFav.map((line, indexLine) =>(
 					<li className="item" key={indexLine}>
				        <div>
				            <h3 className="subtitle">{line.agencyName} | {line.routeShortName}</h3>
				            <p className="description">
				            {
	                			line.routeType === 3 &&
	                			<span className="material-icons" title={t('keys.bus')} aria-hidden="true">directions_bus</span>
	                		}
	                    	{
	                			line.routeType === 2 &&
	                			<span className="material-icons" title={t('keys.tren')} aria-hidden="true">directions_transit_filled</span>
	                		}
	                    	{
	                			line.routeType === 1 &&
	                			<span className="material-icons" title={t('keys.metro')} aria-hidden="true">directions_railway</span>
	                		}
	                    	{
	                			line.routeType === 7 || line.routeType === 0 || line.routeType === 5 && 
	                			<span className="material-icons" title={t('keys.tranvia')} aria-hidden="true">tram</span>
	                		}
				               	{line.routeLongName}
				            </p>
				        </div>
					    <Link className="link_all" rel="noreferrer" to={"/linea_detalle/"+line.agencyName+"/"+(line.routeShortName === ""?"%20":line.routeShortName)+"/"+line.routeLongName.replaceAll("/","-")+"/"+line.routeId+"/"+line.routeType+"/horarios"}>
				            <span className="sr-only">{t('keys.verInfo')}</span>
				        </Link>
				        <button className="link" type="button" id="dropdownMenuLineas" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				            <span className="material-icons" aria-hidden="true">more_vert</span>
				            <span className="sr-only">{t('keys.verOpciones')}</span>
				        </button>
				        <div className="dropdown_bm dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLineas">
				            <button type="button" className="dropdown-item" onClick={() => {this.deleteLine(line.routeShortName, line.routeLongName)}}>
				                <span className="material-icons">delete</span>
				                {t('keys.borrar')}
					        </button>
				            <a className="dropdown-item" href="#/configuracion">
				                <span className="material-icons">settings</span>
				                {t('keys.configurar')}
				            </a>
				        </div>
				    </li>
 			));
   		}

		return(
   				<React.Fragment>
   					<Header handleToUpdate = {this.handlerModeView} action={this.handlerSelectionToogle} title={t('keys.menuLineas')} onSelectLanguage={this.handleLanguage}/>
		    		<div className={this.state.version+ " cabecera_lineas"} id="main-content" >
		    		    <h1 className="sr-only no_mobile">{t('keys.menuLineas')}</h1>
                        <div className="container">
                            <form className="select_linea" onSubmit={this.handleSubmit} action="/lineas_horarios">
                            	<input type="text" id="line" placeholder={t('keys.introducirLinea')} className="pac-target-input" aria-label={t('keys.seleccioneLinea')}/>
                                <button className="button_buscar" id="line_buscar">{t('keys.buscarLinea')}</button>
                            </form>

                            <p className={this.state.searchText !== "" ? "text_results" : "text_results hidden"} id="resultados">{t('keys.resultados')}<strong>{this.state.searchText}</strong></p>

                             <div className="lineas_horarios">
                                <div className="container">
                                    <ul className="list_items">
                                        <li>
                                            <Link className="link active" id="todos" to="/lineas_horarios"  onClick={(text) => this.setRouteType(-1)}>
                                                <span className="image">
                                                    <img className="normal" src={IconTodos} alt=""/>
                                                    <img className="hover" src={IconTodosRed} alt=""/>
                                                </span>
                                                <span className="text">{t('keys.todos')}</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="link" id="bus" to="/lineas_horarios"  onClick={(text) => this.setRouteType(3)}>
                                                <span className="image" aria-hidden="true">
                                                    <span className="material-icons icon">directions_bus</span>
                                                </span>
                                                <span className="text">{t('keys.bus')}</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="link" id="metro" to="/lineas_horarios"  onClick={(text) => this.setRouteType(1)} >
                                                <span className="image" aria-hidden="true">
                                                    <span className="material-icons icon">directions_railway</span>
                                                </span>
                                                <span className="text">{t('keys.metro')}</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="link" id="tren" to="/lineas_horarios"  onClick={(text) => this.setRouteType(2)}>
                                                <span className="image" aria-hidden="true">
                                                    <span className="material-icons icon">directions_transit_filled</span>
                                                </span>
                                                <span className="text">{t('keys.tren')}</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link className="link" id="tram" to="/lineas_horarios"  onClick={(text) => this.setRouteType(0)}>
                                                <span className="image" aria-hidden="true">
                                                    <span className="material-icons icon">tram</span>
                                                </span>
                                                <span className="text">{t('keys.tranvia')}</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
			    		</div>
                    </div>

                    <div className="section_content_lineas container">
						<div className={this.state.lineasFav.length > 0 ? "group_lineas" : "group_lineas hidden"}>
                            <div className="section_lineas_favoritas">
                                <h2 className="title">{parse(t('keys.recientesLineas'))}</h2>
                                <ul className="lineas_list">
									{lineasFav}              
                                </ul>

                                <a className="link_more" href="/#/mis_lineas"  rel="noreferrer">
                                {t('keys.todasmisLineasGuardadas')}
                                    <img className="normal" src={IconRifgt} alt=""/>
                                    <img className="hover" src={IconRifgtHover} alt=""/>
                                </a>
                            </div>
                        </div>
 
                        
                     <div className="group_lineas" id="listadoDinamico"> 
                     {
                    	 this.state.resultados === 1 &&  autobus !== undefined  &&
                    	 	<React.Fragment>
	                     	<h2 className="title" id="tipobus">{t('keys.bus')}</h2>
		                    <ul id="listatipoAutobus" className="section_collapse">
		                    	{autobus}
		                    	{autobusNo}
		                    </ul>
		                    </React.Fragment>
                     }
	                    {
	                    	this.state.resultados === 1 && metro !== undefined  &&
	                    	<React.Fragment>
		                    	<h2 className="title" id="tipometro">{t('keys.metro')}</h2>
		                        <ul id="listatipoMetro" className="section_collapse">
		                    		{metro}
		                    		{metroNo}
		                    	</ul>
	                    	</React.Fragment>
	                     
	                    }
                    	{
	                    	this.state.resultados === 1 && tren !== undefined  &&
	                    	<React.Fragment>
		                    	<h2 className="title" id="tipotren">{t('keys.tren')}</h2>                 	
		                    	<ul id="listatipoTren" className="section_collapse">
		                    		{tren}
		                    		{trenNo}
		                    	</ul>
	                    	</React.Fragment>
                    	}
                    	{
	                    	this.state.resultados === 1 && tranvia !== undefined  &&
	                    	<React.Fragment>
		                    	<h2 className="title" id="tipotran">{t('keys.tranvia')}</h2>
		                    	<ul id="listatipoTranvia" className="section_collapse">
			                    	{tranvia}
			                		{tranviaNo}
		                    	</ul>
	                    	</React.Fragment>
                    	 }
                    </div>
                     {
                    	
                    	 this.state.resultados === 2 &&
                    	 <p className="text_description">{t('keys.noHayBusquedas')}</p>
                     } 
                     {
                    	 
                    	 autobus === undefined  && metro === undefined  && tren === undefined  && tranvia === undefined  && this.state.resultados !== 2 &&
                    	 <div className="button_status" id="capaBloqueante">
	                 		<div className="background">
	                 			<img alt="" className="image" src={IconHoja} />
	                 			<p className="text">{t('keys.cargando')}</p>
	                 		</div>
	                 	</div> 
                     }
                    	   
                    </div>
                    <AcessoRapido/>
                    <Footer />
		    	</React.Fragment>
	  );
  }
}
export default  withTranslation()(LineasInfoComponent);
