import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

import apiLineas from '../../apiLineas';

import Header from '../general/Header';
import Footer from '../general/Footer';

import AcessoRapido from '../general/AccesoRapidoComponent';

import IconTodos from '../../images/icons/icon_place_all.svg';
import IconTodosRed from '../../images/icons/icon_place_all_red.svg';
 
import ReactGA from 'react-ga4';
import CookiesUniversal from 'universal-cookie';
import $ from 'jquery';

class FavoritosHomeComponent extends Component {
	constructor(props) {
		super(props);
        
		this.state = {
				version: "app_favoritos",
			     view: "no_list",
			      showSelections: "none",
			      lineasFav:[],
			      lineas:[],
			      routeType: -1,
			      resultado:'',
			      searchText: "",
			      locale: "eu_ES",
                  noResults: false
			    };
			
			this.handleSubmit = this.handleSubmit.bind(this);
			this.checkLines = this.checkLines.bind(this);
			this.searchFav = this.searchFav.bind(this);
		}
	
	handleLanguage = (langValue) => {
		 this.setState({locale: langValue});
	}

	handlerModeView = (view) => {
		this.setState({ view: view});
	}

	handlerSelectionToogle = () => {
	    if(this.state.showSelections === "none"){
	      this.setState({ showSelections: "block" });
	    } else {
	      this.setState({ showSelections: "none" });
	    }
	}	
	
	componentDidMount(){
		const cookies = new CookiesUniversal();
 		if(cookies.get('cookies_analisis_bizkaitik_mugitu') === undefined){
			   $('#modalOpenCookies').modal('show') 
	    }
 		
		this.cookies()
        if(window.scrollY !== 0){
            window.scrollTo( 0, 0 );
        }

        if(document.getElementById('planificador')!== null && document.getElementById('lineas') !== null){
            document.getElementById('planificador').classList.remove('active');
            document.getElementById('lineas').classList.remove('active');  
        }
        
        this.checkLines().then(values => {this.forceUpdate()});
	}
	
	cookies(){
		 var {t} = this.props;
		   	
		 var dir = window.location.origin+"/"+"#";
		 const pathname = window.location.href.substring(dir.length); 
		 const cookies = new CookiesUniversal();
		 var cookieAnalitica = cookies.get('cookies_analisis_bizkaitik_mugitu');
		  
		 if(cookieAnalitica ==="true"){
				ReactGA.set({ anonymizeIP: true }, ['defaultTracker']);
//				ReactGA.pageview(pathname, ['defaultTracker'], t('keys.menuMisLineas')); 
				ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.menuMisLineas'), tracking: 'defaultTracker' });

		 }else if(cookieAnalitica ==="false") {
				ReactGA.set({ anonymizeIP: true }, ['anonymousTracker']);
//				ReactGA.pageview(pathname, ['anonymousTracker'], t('keys.menuMisLineas'));
				ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.menuMisLineas'), tracking: 'anonymousTracker' });

		 }
	}
	 
    handleSubmit = (event) =>  {
        event.preventDefault(); 
        this.state.resultado = document.getElementById("favorito").value;
        
        let promises = [];
        promises.push(this.searchFav(this.state.resultado, this.state.routeType, ""));
        promises.push(this.checkLines());
        
        Promise.all(promises).then(values => {this.forceUpdate()});
    }
	 
    setRouteType(routeType) {
		this.setState(()=>({routeType: routeType}));        
        let promises = [];
        promises.push(this.searchFav(this.state.resultado, routeType, ""));
        promises.push(this.checkLines());
        
        Promise.all(promises).then(values => {this.forceUpdate()});
        switch (routeType) { 
		 
        case 0:
        	 document.getElementById('todos').classList.remove('active');
        	 document.getElementById('bus').classList.remove('active');
        	 document.getElementById('metro').classList.remove('active');
        	 document.getElementById('tren').classList.remove('active');
        	 document.getElementById('tram').classList.add('active');  		
            break;
        case 1:
        	document.getElementById('todos').classList.remove('active');
        	 document.getElementById('bus').classList.remove('active');
        	 document.getElementById('metro').classList.add('active');
        	 document.getElementById('tren').classList.remove('active');
        	 document.getElementById('tram').classList.remove('active');   	  
            break;
        case 2:
        	document.getElementById('todos').classList.remove('active');
        	 document.getElementById('bus').classList.remove('active');
        	 document.getElementById('metro').classList.remove('active');
        	 document.getElementById('tren').classList.add('active');
        	 document.getElementById('tram').classList.remove('active');    	 
            break;
        case 3:
        	 document.getElementById('todos').classList.remove('active');
        	 document.getElementById('bus').classList.add('active');
        	 document.getElementById('metro').classList.remove('active');
        	 document.getElementById('tren').classList.remove('active');
        	 document.getElementById('tram').classList.remove('active'); 
            break;
        default:
        	document.getElementById('todos').classList.add('active');
        	document.getElementById('bus').classList.remove('active');
	    	 document.getElementById('metro').classList.remove('active');
	    	 document.getElementById('tren').classList.remove('active');
	    	 document.getElementById('tram').classList.remove('active'); 	
            break;
		}
    }
	 
    searchFav(linea, routeType){
        return new Promise ((resolve, reject) => {
            this.state.lineas = [];
            apiLineas.getLineas(linea, routeType, "").then((response) => {
            	
            		this.state.lineas = response.contenido;
                    resolve();
                
            });
        });
	 }
	 
    checkLines() {
        return new Promise ((resolve, reject) => {
			var lineasFav = JSON.parse(localStorage.getItem('lineas'));
										
			if (lineasFav !== null && lineasFav !== undefined && lineasFav.length > 0) {
                this.state.lineasFav = [];
               
                var linesString = [];
			    for (let i = 0; i < lineasFav.length; i++) {
			    	linesString.push(lineasFav[i].operador + "-" + lineasFav[i].nombreLinea);	
			    }
			    
                apiLineas.getLineasLS(linesString.toString()).then(response =>  {
                    this.state.lineasFav = this.state.lineasFav.concat(response.contenido);
                    resolve();
                });
			}
        });
    }
		
    deleteLine (lineShortName, lineLongName) {
        var lineasFav = JSON.parse(localStorage.getItem('lineas'));
        var lineasFavLength = 0;
        
        if (lineasFav !== null && lineasFav !== undefined) {
        	lineasFav = this.deleteLineFromArray(lineShortName, lineLongName, lineasFav);
        	localStorage.setItem("lineas", JSON.stringify(lineasFav));
        	lineasFavLength = lineasFav.length;
        }
        
        this.state.lineasFav = [];
        if (lineasFavLength > 0) {
            this.checkLines().then(values => {this.forceUpdate()});
        } else {
            this.setState({lineasFav: this.state.lineasFav})
        }
    }
    
    deleteLineFromArray(lineShortName, lineLongName, array) {
        var indexToRemove = [];
        array.forEach((element, index) => (element.nombreLinea === lineShortName ||  element.nombreLinea === lineLongName) && indexToRemove.push(index));
        indexToRemove.forEach(element => array.splice(element, 1));
        return array;
    }
		
	render() {
		const {t} = this.props; 
		
		let lineasFav = "";
        var lineasFavJson = JSON.parse(localStorage.getItem('lineas'));
        
		if (this.state.lineasFav.length > 0 && this.state.resultado === "" && this.state.routeType === -1) {
            this.state.noResults = false;
		  	lineasFav = this.state.lineasFav.map((line, indexLine) => (
			  	<li className="item" key={indexLine}>
			        <div>
			            <h3 className="subtitle">{line.agencyName} | {line.routeShortName}</h3>
			            <p className="description">
			            {
                			line.routeType === 3 &&
                			<span className="material-icons" title={t('keys.bus')} aria-hidden="true">directions_bus</span>
                		}
                    	{
                			line.routeType === 2 &&
                			<span className="material-icons" title={t('keys.tren')} aria-hidden="true">directions_transit_filled</span>
                		}
                    	{
                			line.routeType === 1 &&
                			<span className="material-icons" title={t('keys.metro')} aria-hidden="true">directions_railway</span>
                		}
                    	{
                			line.routeType === 7 || line.routeType === 0 &&
                			<span className="material-icons" title={t('keys.tranvia')} aria-hidden="true">tram</span>
                		}
			               	{line.routeLongName}
			            </p>
			        </div>
				    <Link className="link_all" rel="noreferrer" to={"/linea_detalle/"+line.agencyName+"/"+(line.routeShortName === ""?"%20":line.routeShortName)+"/"+line.routeLongName.replaceAll("/","-")+"/"+line.routeId+"/"+line.routeType+"/horarios"}>
			            <span className="sr-only">{t('keys.verInfo')}</span>
			        </Link>
			        <button className="link" type="button" id="dropdownMenuLineas" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
			            <span className="material-icons" aria-hidden="true">more_vert</span>
			            <span className="sr-only">{t('keys.verOpciones')}</span>
			        </button>
			        <div className="dropdown_bm dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLineas">
			            <button type="button" className="dropdown-item" onClick={() => {this.deleteLine(line.routeShortName, line.routeLongName)}}>
			                <span className="material-icons">delete</span>
			                {t('keys.borrar')}
				        </button>
			            <a className="dropdown-item" href="#/configuracion">
			                <span className="material-icons">settings</span>
			                {t('keys.configurar')}
			            </a>
			        </div>
			    </li>
		  	));
		} else if(this.state.lineasFav.length > 0) {
            var favoritos = [];
            var array = JSON.stringify(this.state.lineasFav);
            for(var i = 0; i < this.state.lineas.length; i++) {
                if (array.includes(JSON.stringify(this.state.lineas[i]))){
                    favoritos.push(this.state.lineas[i])
                }
            }
            
            if (favoritos.length === 0)
                this.state.noResults = true;
            else 
                this.state.noResults = false;
            
			lineasFav = favoritos.map((line, indexLine) => (
				  	<li className="item" key={indexLine}>
				        <div>
				            <h3 className="subtitle">{line.agencyName} | {line.routeShortName}</h3>
				            <p className="description">
				            {
	                			line.routeType === 3 &&
	                			<span className="material-icons" title={t('keys.bus')} aria-hidden="true">directions_bus</span>
	                		}
	                    	{
	                			line.routeType === 2 &&
	                			<span className="material-icons" title={t('keys.tren')} aria-hidden="true">directions_train</span>
	                		}
	                    	{
	                			line.routeType === 1 &&
	                			<span className="material-icons" title={t('keys.metro')} aria-hidden="true">directions_transit_filled</span>
	                		}
	                    	{
	                			line.routeType === 7 &&
	                			<span className="material-icons" title={t('keys.tranvia')} aria-hidden="true">tram</span>
	                		}
				               	{line.routeLongName}
				            </p>
				        </div>
					    <Link className="link_all" rel="noreferrer" to={"/linea_detalle/"+line.agencyName+"/"+(line.routeShortName === ""?"%20":line.routeShortName)+"/"+line.routeLongName.replaceAll("/","-")+"/"+line.routeId+"/horarios"}>
				            <span className="sr-only">{t('keys.verInfo')}</span>
				        </Link>
				        <button className="link" type="button" id="dropdownMenuLineas" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				            <span className="material-icons" aria-hidden="true">more_vert</span>
				            <span className="sr-only">Ver opciones</span>
				        </button>
				        <div className="dropdown_bm dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLineas">
				            <button type="button" className="dropdown-item" onClick={() => {this.deleteLine(line.routeShortName, line.routeLongName)}}>
				                <span className="material-icons">delete</span>
				                {t('keys.borrar')}
					        </button>
				            <a className="dropdown-item" href="#/configuracion">
				                <span className="material-icons">settings</span>
				                {t('keys.configurar')}
				            </a>
				        </div>
				    </li>
			  	));
		} else {
            this.state.lineasFav = [];
        }
		
		return(
            <React.Fragment>
				<Header handleToUpdate = {this.handlerModeView} action={this.handlerSelectionToogle} title={t('keys.menuMisLineas')} onSelectLanguage={this.handleLanguage}/>

				    <div className={this.state.version+ " cabecera_lineas_save"} id="main-content"> 
						<h1 className="title no_mobile">{t('keys.menuMisLineas')}</h1> 
                        
                        <div className="container" >
                        
                        <form className="select_linea" onSubmit={this.handleSubmit} action="/mis_lineas">
                            <input type="text" id="favorito" placeholder={t('keys.introducirLinea')} className="pac-target-input" aria-label={t('keys.seleccioneLinea')}/>
                            <button type="submit" className="button_buscar">{t('keys.buscarLinea')}</button>
                        </form>

                        <p className={this.state.searchText !== "" ? "text_results" : "text_results hidden"} id="resultados">Resultados para <strong>{this.state.searchText}</strong></p>

                         <div className="lineas_horarios">
                            <div className="container">
                                <ul className="list_items">
                                    <li>
                                        <Link className="link active" id="todos" to="/mis_lineas"  onClick={(text) => this.setRouteType(-1)}>
                                            <span className="image">
                                                <img className="normal" src={IconTodos} alt=""/>
                                                <img className="hover" src={IconTodosRed} alt=""/>
                                            </span>
                                            <span className="text">{t('keys.todos')}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="link" id="bus"  to="/mis_lineas"  onClick={(text) => this.setRouteType(3)}>
                                            <span className="image" aria-hidden="true">
                                                <span className="material-icons icon">directions_bus</span>
                                            </span>
                                            <span className="text">{t('keys.bus')}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="link" id="metro" to="/mis_lineas"  onClick={(text) => this.setRouteType(1)} >
                                            <span className="image" aria-hidden="true">
                                                <span className="material-icons icon">directions_railway</span>
                                            </span>
                                            <span className="text">{t('keys.metro')}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="link" id="tren" to="/mis_lineas"  onClick={(text) => this.setRouteType(2)}>
                                            <span className="image" aria-hidden="true">
                                                <span className="material-icons icon">directions_transit_filled</span>
                                            </span>
                                            <span className="text">{t('keys.tren')}</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link className="link" id="tram" to="/mis_lineas"  onClick={(text) => this.setRouteType(0)}>
                                            <span className="image" aria-hidden="true">
                                                <span className="material-icons icon">tram</span>
                                            </span>
                                            <span className="text">{t('keys.tranvia')}</span>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
		    		</div>
           
                    </div>
                    

                    <div className="section_content_lineas section_favoritos container">

                        <div className="group_lineas">

                            <div className="section_lineas_favoritas">
                                <div className="group_lineas">
                                    <div className="section_lineas_favoritas">
                                        <h2 className="title">{parse(t('keys.ultimasLineas'))}</h2>
                                        {((lineasFavJson === null || lineasFavJson === undefined) || lineasFavJson.length === 0) && <p className="text_description">{t('keys.noHayLineasGuardadas')}</p>}
                                        {this.state.noResults && <p className="text_description">{t('keys.noHayBusquedas')}</p>}
                                        <ul className="lineas_list">
                                            {lineasFav}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                <AcessoRapido/>
                <Footer />
            </React.Fragment>
		);
	}
}
export default  withTranslation() (FavoritosHomeComponent);