import { Component } from 'react';
import $ from 'jquery';
import { withTranslation } from 'react-i18next'; 
import pushWooshAPI from './apiPushWoosh';
import ReactGA from 'react-ga4';

class Gtm extends Component {

	componentDidMount(){ 
 
		//Footer
		$(document).on('click', '.main_footer li', function() {
			window.dataLayer.push({
		            'event': 'footerClick',
		            'eventLabel': $(this).text()
		        });
	    });
		
		//Abrir menu movil
		$(document).on('click', '.app_header .actions_header .header-menu', function() { 
			window.dataLayer.push({
		            'event': 'menuClick'
		        });
		    });
 
		//Idioma
		$(document).on('click', '.languages .language', function() {
			window.dataLayer.push({
	            'event': 'idiomaClick',
	            'eventLabel':$(this).text()
	        });
		});
		
		$(document).on('click', '.section_languages .select_languages .language', function() { 
			window.dataLayer.push({
	            'event': 'idiomaClick',
	            'eventLabel':$(this).text()
	        });
		});
		
		//Home Lineas guardadas
		$(document).on('click', "#lineas_guardadas", function(){
			var padre = $(this).parents('div');
			window.dataLayer.push({
                'event': 'contenidoHome',
                'eventLabel':$('#lineasGuardadas', padre).text() +" | "+ $(this).text()
            });
		});
		
		$(document).on('click', ".section_lineas_favoritas .link_all", function(){
			var padre = $(this).parents('div');
			var padreLinea = $(this).parents('.item');
			window.dataLayer.push({
                'event': 'contenidoHome',
                'eventLabel': $('#lineasGuardadas', padre).text() +" | "+ $('.subtitle', padreLinea).text() 
            });
		});
		
		//Home Top recorridos
		$(document).on('click', ".app_bizkaitikMugitu.content_body .section_descrubre_bizkaia .list_card .list-item .content_text .link", function(){
			window.dataLayer.push({
                'event': 'contenidoHome',
                'eventLabel': $('#descubre_bizkaia').text() +" | "+ $('.sr-only',this).text()	
            });
		});
		
		//Home Top recorridos Ver mas
		$(document).on('click', ".section_descrubre_bizkaia .link_more", function(){
			window.dataLayer.push({
                'event': 'contenidoHome',
                'eventLabel':$('#descubre_bizkaia').text() +" | "+ $(this).text()
            });
		});
		
		//Home Todo el transporte
		$(document).on('click', ".section_transporte_publico .list_transporte .link", function(){
			var padre = $(this).parents('div');
			window.dataLayer.push({
                'event': 'contenidoHome',
                'eventLabel':$('#todo_transporte', padre).text() +" | "+ $(this).text()
            });
		});
		
		//Home Titulos transporte
		$(document).on('click', ".section_titulos_trasnportes .section_collapse .item_head button", function(){
			if($(this).attr("aria-expanded") === "true"){ 
				window.dataLayer.push({
	                'event': 'contenidoHome',
	                'eventLabel':$('#titulos_transporte').text() +" | "+ $('.text',this).text()
	            });
			}
			
		});

		//Home Desarrolladores
		$(document).on('click', ".section_desarrolladores .link", function(){
			var padre = $(this).parents('div'); 
			window.dataLayer.push({
                'event': 'contenidoHome',
                'eventLabel':$('#desarrolladores', padre).text() +" | "+ $(this).text()
            });
		});
		 
		$(document).on('click', ".section_bizkaibus_app .link", function(){
			window.dataLayer.push({
                'event': 'contenidoHome',
                'eventLabel':$('#bizkaibusApp').text() +" | "+ $(this).text()
            });
		});
		
		//Contenido menu
		$(document).on('click', '.section_menus_mobile li', function() {
			window.dataLayer.push({
                'event': 'contenidoMenu',
                'eventLabel':$(this).text()
            });
		});
		
		$(document).on('click', 'header.desktop .main_first .list_main li a', function() { 
			if($(this).attr("id") === "alertas"){		
				window.dataLayer.push({
	                'event': 'contenidoMenu',
	                'eventLabel':$(".sr-only", this).text()
	            }); 
			}else{
				window.dataLayer.push({
	                'event': 'contenidoMenu',
	                'eventLabel':$(this).text()
	            }); 
			}
			
		});
		
		
		//Mis lineas guardadas y Ayuda/contacto
		$(document).on('click', 'header.desktop .pre_header .list_items a ', function() { 
			window.dataLayer.push({
                'event': 'contenidoMenu',
                'eventLabel':$(this).text()
            }); 
		});
		
		//Sticky
		$(document).on('click', '.app_accesoRapido a', function() {
			window.dataLayer.push({
                'event': 'stickyClick',
                'eventLabel':$(this).text()
            });
		});
 
		//Contenido descubre bizkaia
		$(document).on('click', '.descubre_bizkaia .section_descrubre_bizkaia .list_card .list-item .content_text .link', function() {
			
			window.dataLayer.push({
                'event': 'contenidoBizkaia',
                'eventLabel':$(".sr-only", this).text()
            });
		});
 
		$(document).on('click', '.banner_full .section_collapse .item_head button', function() {
			if($(this).attr("aria-expanded") === "true"){
				 
				window.dataLayer.push({
	                'event': 'contenidoBizkaia',
	                'eventLabel':$('.item_head .text').text()
	            });
			}
		});

		$(document).on('click', '.places_interest .list_items .link', function() {
			
			window.dataLayer.push({
                'event': 'lugaresMenu',
                'eventLabel':$(this).text()
            });
		});
         
		$(document).on('click', '.places_interest_content .list_content .item .link_more', function() {
			
			window.dataLayer.push({
                'event': 'lugaresClick',
                'eventLabel':$(".title", this).text()
            });
		});
		
		//Titulos y tarifas
		$(document).on('click', '.section_titulos_tarifas .section_collapse .item_head button', function() {
			if($(this).attr("aria-expanded") === "true"){
				console.log(window.dataLayer)
				window.dataLayer.push({
	                'event': 'titulosClick',
	                'eventLabel':$(".text", this).text()
	            });
			}
		});

		$(document).on('click', '.section_titulos_tarifas .section_collapse .content_body .link_more', function() {
			var padre = $(this).parents('li');  
	            window.dataLayer.push({
	                'event': 'titulosLink',
	                'eventLabel':$(".text", padre).text()
	            }); 
		});
		
		//Mapas
		$(document).on('click', '.content_body_mapas .list_maps .item .link', function() {
			var padre = $(this).parents('li');
			 
			var eventLabel = "";
			if($(".title", padre).length >0){
				eventLabel = $(".title", padre).text();

			}else{
				eventLabel = $(".sr-only",this).text();

			}
			
			window.dataLayer.push({
                'event': 'mapasClick',
                'eventLabel':eventLabel
            });
			
		});
		
	 
		//Planificador Origen y destino
		$(document).on('click', '#origin, #destination', function() {
			
			var eventLabel="";
			
			if($("#cabecera_movil").text() !== ""){
				eventLabel = $("#cabecera_movil").text();
			}else if($(".cabecera_planificador .title_h1_desktop").text() !== ""){
				eventLabel = $(".title_h1_desktop").text();
			}else{
				eventLabel='Home';
			}
			
			
			window.dataLayer.push({
                'event': 'planificadorClick',
                'eventLabel':eventLabel
            });
		});

		//Planificador buscador
		$(document).on('click', '.section_planificador_ruta .container_planificador .button_buscar', function() {
			
			window.dataLayer.push({
                'event': 'planificadorOK',
                'eventLabel':$('#origin').val()+" | "+$('#destination').val()
            });
		});
		
		$(document).on('click', '#planificador_buscar', function() {
			
			window.dataLayer.push({
                'event': 'planificadorOK',
                'eventLabel':$('#origin').val()+" | "+$('#destination').val()
            });
		});
 
		//Planificador filtros
		$(document).on('click', '.button_planificador', function() { 
 
			var eventLabel;
			if($(this).clone().children().remove().end().text() === "Tipo transporte" || $(this).clone().children().remove().end().text() === "Garrario mota"){
				eventLabel = $(this).clone().children().remove().end().text();				
			}else{
				eventLabel = $(this).attr("data-titlemodalhora");
			} 
			window.dataLayer.push({
	            'event': 'filtroClick1',
	            'eventLabel': eventLabel
	        });
	    });

		$(document).on('click', '.button_rutas', function() { 
			window.dataLayer.push({
	            'event': 'filtroClick1',
	            'eventLabel': $(this).attr("data-titleRuta")
	        });
	    });
		 
		$(document).on('click', '.planificador_sections.container .select_box a', function() { 
			window.dataLayer.push({
	            'event': 'filtroClick2',
	            'eventLabel': $(this).text()
	        });
	    });
		
		$(document).on('click', '#tipoTransporte', function() {
			var value = "";
		    if($('#bus').is(':checked') === true){
		        value += $('label[for=bus]').clone().children().remove().end().text();
		    }
		    
		    if($('#train').is(':checked') === true){
		        if(value === ""){
		            value += $('label[for=train]').clone().children().remove().end().text();
		        }else{
		            value += " | " + $('label[for=train]').clone().children().remove().end().text();
		        }
		    }  
		    
		    if($('#tram').is(':checked') === true){
		        if(value === ""){
		            value += $('label[for=tram]').clone().children().remove().end().text();
		        }else{
		            value += " | " + $('label[for=tram]').clone().children().remove().end().text();
		        }
		    } 

		    if($('#subway').is(':checked') === true){
		        if(value === ""){
		            value += $('label[for=subway]').clone().children().remove().end().text();
		        }else{
		            value += " | " + $('label[for=subway]').clone().children().remove().end().text();
		        }
		    } 

		    window.dataLayer.push({
		        'event': 'filtroClick2',
		        'eventLabel': value
		    }); 
		});
		
		$(document).on('click', '.button_invert', function() {
			var padreOD = $(this).parents(".planificador_sections.container");
			window.dataLayer.push({
		        'event': 'ordenClick',
		        'eventLabel': $("#origin", padreOD).val()+" | " + $("#destination", padreOD).val(),
		    }); 
		});
				
		//Planificador rutas
		$(document).on('click', '.content_results .direction-options .list_rutas .direction-info .link_ruta', function() {
			var padreRuta = $(this).parents('.right');
			var padreOD = $(this).parents(".planificador_sections.container")
			console.log(window.dataLayer)
			window.dataLayer.push({
			        'event': 'resultadosClick',
			        'eventLabel': $(this).closest("li").index(),
			        'origen|destino':$("#origin", padreOD).val()+" | " + $("#destination", padreOD).val(),
			        'ruta':$(".more_info", padreRuta).text()       
			});
		});
	
		$(document).on('click', '.section_detail_rute .cabecera_detail_rute .select_time .button_select ', function() {
			var originDestino, eventLabel = "";
			if(document.getElementById("origen_destino_mobile") === null){
				originDestino = $('#origen_destino').text().replace("-","|")
			}else{
				originDestino = $('#origen_destino_mobile').text().replace("-","|")
			}

			if($(this).text() === "chevron_right"){
				eventLabel = "Siguiente";	
		    }else{
		    	eventLabel = "Anterior";
		    }

			window.dataLayer.push({
		        'event': 'elementosDetalle',
		        'eventLabel': eventLabel,
		        'origen|destino': originDestino,
		        'ruta': $(".header .sr-only").text()    
			}); 
		});
	
		//Lineas
		$(document).on('click', '#line_buscar', function() {
			window.dataLayer.push({
                'event': 'buscadorClick',
                'eventLabel':$('#line').val()
            });
		});
		
		$(document).on('click', '.lineas_horarios .list_items .link', function() {
			
			window.dataLayer.push({
                'event': 'menuLineas',
                'eventAction':$(".text", this).text()
            });
		});
 
		$(document).on('click', '.list_lineas .items a', function() {
			var padre = $(this).parents('.section_collapse'); 
				window.dataLayer.push({
	                'event': 'seleccionLinea',
	                'eventAction': $(padre).attr("id").replace("listatipo",""),
	                'eventLabel':$(".pre_title", this).text() + " | " + $(".linea", this).text(),
	                'ruta':$(".title_linea", this).clone().children().remove().end().text()
	            }); 
		});
		
		//Lineas detalle
		$(document).on('click', '.cabecera_detail_linea .list_anclas .link', function() {
			
			window.dataLayer.push({
                'event': 'menuDetalle', 
                'eventLabel':$(".text", this).text(),
                'ruta':$("#rutaHorario").text()
            });
		});
		
		//Lineas detalle. Cambio de direccion
		$(document).on('click', '.section_linea_horarios .list_horarios .linea_horario .button_change_direccion', function() {
			
			window.dataLayer.push({
	            'event': 'detalleHorarios', 
	            'eventLabel':$(".sr-only", this).text(),
	            'ruta':$("#rutaHorario").text()
	        });
		});
		
		//Lineas detalle. Parada
		$(document).on('click', '.list_paradas .item.active .content .button_parada ', function() {
			
			window.dataLayer.push({
	            'event': 'detalleHorarios', 
	            'eventLabel': "parada " + $(this).text(),
	            'ruta':$("#rutaHorario").text()
	        });
		});
		
		$(document).on('click', 'div[role=button]', function() {
			
			window.dataLayer.push({
	            'event': 'detalleHorarios', 
	            'eventLabel': "Parada en mapa "+ $('#paradasNombre').text(),
	            'ruta':$("#rutaHorario").text()
	        });
		});
		
		//Linea detalle ir al horario parada 
		$(document).on('click', '.section_linea_horarios .list_horarios .paradas_linea .list_paradas .item.active .content .link', function(){
			var padre = $(this).parents('.list_horarios');
			
			window.dataLayer.push({
	            'event': 'detalleHorarios', 
	            'eventLabel': $(this).text(),
	            'ruta': $(".subtitle", padre).text()
	        });
		}); 
		
		//Ver en el mapa
		$(document).on('click', '.content_detail_parada .cabecera_detail_parada .link_more_static', function(){
			console.log(window.dataLayer)
			window.dataLayer.push({
	            'event': 'detalleHorarios', 
	            'eventLabel': $(this).text(),
	            'parada':$(".content_detail_parada .cabecera_detail_parada .title .text").clone().children().remove().end().text()
	        });
		}); 
		 
		//Ver mapa de lineas
		$(document).on('click', '.section_linea_horarios .list_horarios .linea_horario .info_linea .text .see_map', function(){
			
			window.dataLayer.push({
	            'event': 'detalleHorarios', 
	            'eventLabel': $(this).text(),
	            'ruta':$("#rutaHorario").text()
	        });
		}); 
		
		//Descargar mapa de lineas
		$(document).on('click', '.section_linea_horarios .section_dowdload_linea .link', function(){
			
			window.dataLayer.push({
	            'event': 'detalleHorarios', 
	            'eventLabel': $(this).text(),
	            'ruta':$("#rutaHorario").text()
	        });
		});
		
		$(document).on('click', 'map area', function() {
		    window.dataLayer.push({
	            'event': 'detalleHorarios', 
	            'eventLabel': $('#paradasNombre').text(),
	            'ruta':$("#rutaHorario").text()
	        });
		})
		
		//Contacto Preguntas
		$(document).on('click', '.section_preguntas_frecuentes .section_collapse .item_head button', function() {
			if($(this).attr("aria-expanded") === "true"){ 
				window.dataLayer.push({
	                'event': 'preguntasClick',
	                'eventLabel':$(".text", this).text()
	            });
			}
		});
		
		$(document).on('click', '.section_preguntas_frecuentes .section_collapse .content_body .link_more', function() {
			console.log(window.dataLayer)
			var padre = $(this).parents('li');  
				window.dataLayer.push({
	                'event': 'preguntasClick',
	                'eventLabel':$(this).text()+" - "+ $(".text", padre).clone().children().remove().end().text()
	            }); 
		});
		
		//Contacto Telefono / Correo
		$(document).on('click', '.section_contacta .list_contacta .item p', function() {
			var padre="";
			var eventLabel="";
			if($(".row.reverse").length >0){
				padre = $(this).parents('.row.reverse');
				eventLabel= $("#tarifas .title", padre).clone().children().remove().end().text();
			}else{
				eventLabel="CTB";
			} 
			
			window.dataLayer.push({
                'event': 'contactoClick',
                'eventAction': $(this).attr("id"),
                'eventLabel': eventLabel
            });
		});
		
		//Contacto RRSS
		$(document).on('click', '.section_contacta .list_contacta .rrss .link', function() {
			var padre = "";
			var eventLabel;
			if($(".row.reverse").length >0){
				padre = $(this).parents('.row.reverse');
				eventLabel=$("#tarifas .title", padre).clone().children().remove().end().text()
			}else{
				eventLabel="CTB"
			}

			
			window.dataLayer.push({
                'event': 'contactoClick',
                'eventaction': $(".sr-only", this).text(),
                'eventLabel': eventLabel
            });
		});
		 
		//Enlace oficinas desde operador
		$(document).on('click', '.section_contacto .link_more_static', function() {	   
			var eventLabel;
			if($(this).attr("id") !== undefined){
				eventLabel=$(this).attr("id")
			}else{
				eventLabel="CTB"
			}
			
			window.dataLayer.push({
                'event': 'oficinaLink',
                'eventLabel':eventLabel
            });
		});
		
 
		//Despegables oficina
		$(document).on('click', '.section_list_offices .section_collapse .item_head button', function() {
			console.log(window.dataLayer)
			if($(this).attr("aria-expanded") === "true"){
				window.dataLayer.push({
	                'event': 'oficinaClick',
	                'eventLabel':$(".text", this).children(0).text()+" - "+ $(".text", this).clone().children().remove().end().text()
	            });
			}
		});
		
		//Oficina. Como llegar
		$(document).on('click', '.section_list_offices .section_collapse .content_body .link_more', function() {
			console.log(window.dataLayer)
			var padre = $(this).parents('li');  
				window.dataLayer.push({
	                'event': 'infoOficina',
	                'eventLabel':$(".text", padre).children(0).text()+" - "+ $(".text", padre).clone().children().remove().end().text()
	            }); 
		});
		
		//Conversion. Guardar linea favorito
		$(document).on('click', '.section_detail_line .section_save_rute .button_save', function() {
			var padre = $(this).parents('.content_tabs_line'); 

			window.dataLayer.push({
                'event': 'lineaFav',
                'eventLabel': $("#horarios .title", padre).clone().children().remove().end().text() +" | "+$("#horarios .title .line", padre).text(),
                'ruta': $("#rutaHorario", padre).text()
                
            });
		});
		
		//Eliminar línea de ‘Favoritos’
		$(document).on('click', '.dropdown-menu.dropdown_bm .dropdown-item', function() {
			var padre = $(this).parents('.item.show'); 
			window.dataLayer.push({
                'event': 'lineaUnfav',
                'eventLabel': $(".subtitle", padre).text(),
                'ruta': $(".description", padre).clone().children().remove().end().text()
            });
		});
		
		//Compartir linea 1click	 
		$(document).on('click', '.section_detail_rute .steps_rute .section_save_rute .button_share', function() {
			var padre = $(this).parents('.steps_rute');  

			window.dataLayer.push({
                'event': 'lineaCompartida1',
                'eventLabel': $("#origen_destino", padre).text()
            });
		});
		
		$(document).on('click', '.section_detail_line .section_save_rute .button_share', function() {
			var padre = $(this).parents("#content_tabs_line");

			window.dataLayer.push({
                'event': 'lineaCompartida1',
                'eventLabel':  $("#horarios .title", padre).clone().children().remove().end().text() +" | "+$("#horarios .title .line", padre).text(),
                'ruta': $("#rutaHorario", padre).text()
            });
		});
		
		$(document).on('click', '.modal_share .modal-content .header_modal .list_rrss .item_rrss', function() {
			var padre = "";
			var linea = "";
			var ruta = "";
			if(window.location.href.indexOf("planificador") > -1){
				padre = $(this).parents('.row.reverse');
				linea = $("#origen_destino", padre).text();
				ruta = "";
			}else{
				padre = $(this).parents('.content_tabs_line');
				linea = $("#horarios .title", padre).clone().children().remove().end().text() +" | "+$("#horarios .title .line", padre).text();
				ruta = $("#rutaHorario", padre).text()
			} 
			window.dataLayer.push({
                'event': 'lineaCompartida2',
                'eventLabel':$(this).text(),
                'ruta': linea,
                'origen|destino': ruta
            });
		});
		
		$(document).on('click', '#inputIncidencias', function(){
			var padre = $(this).parents('.item');			
			var eventLabel = "";
			pushWooshAPI.pushWooshSuscribe({ userVisibleOnly : true})
			.then(function(subscrition){
				
				eventLabel = $(".subtitle",padre).text();
				
				if($('#inputIncidencias').is(':checked') === true){
				   window.dataLayer.push({
					   'event': 'crearAlerta',
					   'eventLabel':eventLabel 
				   });         
				}else{
					window.dataLayer.push({
		                'event': 'borrarAlerta',
		                'eventLabel':eventLabel
		            });	
				}
				
			}).catch(function(err){ 
				window.dataLayer.push({
	                'event': 'borrarAlerta',
	                'eventLabel':eventLabel
	            });
			});		 
		});
 		
		$(document).on('click', '#inputInformacion', function(){
			var padre = $(this).parents('.item');			
			var eventLabel = "";
			pushWooshAPI.pushWooshSuscribe({ userVisibleOnly : true})
			.then(function(pushSubscription){
				eventLabel = $(".subtitle",padre).text();
			    
				if($('#inputInformacion').is(':checked') === true){
						window.dataLayer.push({
			                'event': 'crearAlerta',
			                'eventLabel':eventLabel 
			            });
				}else{ 
					window.dataLayer.push({
		                'event': 'borrarAlerta',
		                'eventLabel':eventLabel
		            });
				} 
				
			}).catch(function(err){
				
				window.dataLayer.push({
	                'event': 'borrarAlerta',
	                'eventLabel':eventLabel
	            });
			});	
			
		});
		
		
		
		
		window.addEventListener('appinstalled', function(event){
			
			window.dataLayer.push({
                'event': 'gestionApp',
                'eventAction': 'instalada'
            });
		});
		
 	}

	render() {

		return null
				
	}
	
} export default withTranslation()(Gtm);