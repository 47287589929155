import React, { Component } from 'react';

import { withTranslation } from 'react-i18next'; 

import AcessoRapido from '../general/AccesoRapidoComponent';

import Header from '../general/Header';
import Footer from '../general/Footer';

import TitulosTransportes from './TitulosTransportesComponent';
import PlanificadorRuta from './PlanificadorHomeComponent';
import AlertasHome from './AlertasHomeComponent';
import DescubreBizkaia from './DescubreBizkaiaComponent';
import Desarrolladores from './MugituDesarrolladoresComponent';
import BizkaibusApp from './BizkaibusAppComponent';
import LineasFavoritas from './LineasFavoritasComponent';
import TransportePublico from './TransportePublicoComponent';
import ReactGA from 'react-ga4';
import CookiesUniversal from 'universal-cookie';
import $ from 'jquery';

class HomeComponent extends Component  {

	constructor(props) {
	    super(props);

	    this.state = {  
	      version: "app_bizkaitikMugitu",
	      view: "no_list",
	      showSelections: "none",
	      locale: "eu_ES",
	      data: ""
	    };
	}

	handlerModeView = (view) => {
	    this.setState({ view: view});
	}
	
	handleLanguage = (langValue) => {
		 this.setState({locale: langValue});
	}

	handlerSelectionToogle = () => {

	    if(this.state.showSelections === "none"){ 
	    }else{
	      this.setState({ showSelections: "none" });
	    }
	}
	
	componentDidMount(){
		const cookies = new CookiesUniversal();
		if(cookies.get('cookies_analisis_bizkaitik_mugitu') === undefined){
			   $('#modalOpenCookies').modal('show') 
	    	} 
		this.cookies()
		if(window.scrollY !== 0){
			window.scrollTo( 0, 0 );
		}	 
	 
		if(document.getElementById('planificador')!==null && document.getElementById('lineas')!==null){
			document.getElementById('planificador').classList.remove('active');
			document.getElementById('lineas').classList.remove('active');  
		}
	}
	
	cookies(){
		
		var {t} = this.props;
				
		var dir = window.location.origin+"/"+"#";
		const pathname = window.location.href.substring(dir.length); 	 
		const cookies = new CookiesUniversal();
		var cookieAnalitica = cookies.get('cookies_analisis_bizkaitik_mugitu');
	    
		if(cookieAnalitica ==="true"){	
//			ReactGA.pageview(pathname, ['defaultTracker'], t('keys.menuInicio'));
			ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.menuInicio'), tracking: 'defaultTracker' });

		 }else if(cookieAnalitica ==="false") { 
//			ReactGA.pageview(pathname, ['anonymousTracker'], t('keys.menuInicio')); 
			ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.menuInicio'), tracker: 'anonymousTracker' });

		 }
	}
	
	receiveChildValue = (childData) => {
		console.log("Received data from close function:  " +childData);
		this.setState({data: childData})
	};
	
	render() {	
		
		const {data} = this.state;
		const {t} = this.props;
		
		return (
				<React.Fragment>
					<Header handleToUpdate={this.handlerModeView} action={this.handlerSelectionToogle} title="" onSelectLanguage={this.handleLanguage} alertas={this.state.data}/>

						<div className={this.state.version + " content_body"} id="main-content">			
				            <div className="section_planificador_ruta">
				                <div className="background"></div>
				                <div className="container">
	                                <h2 className="title">{t('keys.muevete')}</h2>
	                                <PlanificadorRuta/>
	                            </div>
				            </div>
				
				            <ul className="section_alertas container">
				                <AlertasHome fromChildToParentCallback={this.receiveChildValue} />
				            </ul>
				
	                        <LineasFavoritas/>
				
				            <div className="section_transporte_publico">
				                <div className="container">
				                    <TransportePublico/>
	                            </div>
				            </div>
				
				            <div className="section_descrubre_bizkaia">
				                <div className="container">
				                    <DescubreBizkaia/>
				                </div>
				            </div>
	
				            <div className="section_titulos_trasnportes">
				                <div className="container">
	                                <TitulosTransportes/>
	                            </div>
	                        </div>
				
				            <div className="section_banners">
	                            <div className="container">
	                                <div className="row">
	
	                                    <div className="col-12 col-md-6">
	                                        <div className="section_desarrolladores">
	                                            <Desarrolladores/>
	                                        </div>
	                                    </div>
	
	                                    <div className="col-12 col-md-6">
	                                        <div className="section_bizkaibus_app">
	                                            <BizkaibusApp/>
	                                        </div>
	                                    </div>
	
	                                </div>
	                            </div>
	                        </div>
				
				        </div>
			        <AcessoRapido/>
			        <Footer />
		        </React.Fragment>
			

		);
	}
}
export default withTranslation()(HomeComponent);