import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { GoogleMap, Autocomplete, DirectionsRenderer, Marker } from '@react-google-maps/api';

import "react-datepicker/dist/react-datepicker.css";
import parse from 'html-react-parser';

import Header from '../general/Header';
import Footer from '../general/Footer';

import AcessoRapido from '../general/AccesoRapidoComponent';

import IconPlaniHome from '../../images/icons/icon_arrows_plan.svg';
import IconPlaniHome2 from '../../images/icons/icon-dot-dot-buscador.svg';
import IconDurationRutes from '../../images/icons/icon_duration_rutas.svg';
import IconClose from '../../images/icons/close.png';
import IconPlaniLotation from '../../images/icons/icon_location.svg';
import IconAlarmLine from '../../images/icons/icon_alarm_line.svg';
import liferayAPI from '../../apiLiferay';
import IconOrigin from '../../images/icons/walk.svg';
import IconDestination from '../../images/icons/icon_fin_trayecto.svg';
 
import ReactGA from 'react-ga4';
import CookiesUniversal from 'universal-cookie';
import $ from 'jquery';

class PlanificadorComponent extends Component {	
	
	constructor(props) {
		super(props);
 
		this.state = {  
			version: "app_planificadorRutas",
			view: "no_list",
			showSelections: "none",
			startDate: new Date(),		
			locale: "eu_ES"
		};
 
		this.state = {
			directions: null,
			transitMode: '',
			transit:[],
			origin: this.props.match.params.origen,
			destination: this.props.match.params.destino,
			arrivalTime:'',
			departureTime:'',
			fechaLlegada:'',
			fechaSalida:'',
			horaLlegada:'',
			horaSalida:'',
			resultados: 1,
			preference: undefined,
			routePreference:'BEST_ROUTE',
			hasAlert: [[],[],[],[]]
		}
		
		this.searchRoute = this.searchRoute.bind(this)
		this.autocomplete = null

    	this.onLoad = this.onLoad.bind(this)
		this.onPlaceChanged = this.onPlaceChanged.bind(this) 
	}
	

	handleLanguage = (langValue) => {
		 this.setState({locale: langValue});
	}
	
	handlerModeView = (view) => {
	    this.setState({ view: view});

	}

	handlerSelectionToogle = () => {
	    if(this.state.showSelections === "none"){
	      this.setState({ showSelections: "block" });
	    }else{
	      this.setState({ showSelections: "none" });
	    }
	}	
	
	onLoad (autocomplete) {
		console.log('autocomplete: ', autocomplete)
		this.autocomplete = autocomplete
	}

	onPlaceChanged() {
		if (this.autocomplete !== null) {
			this.setState({
				origin: document.getElementById("origin").value,
				destination: document.getElementById("destination").value
			});
		} else {
			console.log('Autocomplete is not loaded yet!')
		}
	}
	
	onOriginChange(value) {
		this.setState({
			origin: value,
		});
	}

	onDestinationChange(value) {
		this.setState({
			destination: value,
		});
	}
	
	selectTransport(value) {
		
		  let transitModes =  this.state.transit
		  
		  if(transitModes.includes(value)){
			  let result = transitModes.indexOf(value);
			  transitModes.splice(result, 1); 
		  }else{
			  transitModes.push(value)
		  } 
		  this.state.transit = transitModes;
	}

	checkFecha(value, text){
		if(text==="llegada"){
			this.state.fechaLlegada = value

		} else if(text==="salida"){
			this.state.fechaSalida = value
		}
	}
	
	checkHora(value, text){
		if(text==="llegada"){
			this.state.horaLlegada = value 
		}else if(text==="salida"){
			this.state.horaSalida = value
		} 
	} 
	
	getArrivalTime(){ 
		var fullArrivalDate = "";
        var yearD =  "";
        var monthD =  "";
        var dayD = "";
        var hourD =  "";
        var minuteD = "";
        var arrivalTime="";
		if(this.state.fechaLlegada!=="" && this.state.horaLlegada!==""){

    		fullArrivalDate = this.state.fechaLlegada.concat(this.state.horaLlegada);
            yearD = parseInt(fullArrivalDate.substring(0, 4));
            monthD = parseInt(fullArrivalDate.substring(5, 7));
            dayD = parseInt(fullArrivalDate.substring(8, 10));
            hourD = parseInt(fullArrivalDate.substring(10, 12));
            minuteD = parseInt(fullArrivalDate.substring(13));
            arrivalTime = new Date(yearD, monthD - 1, dayD, hourD, minuteD);

            return arrivalTime;
        }else if(this.state.fechaLlegada==="" && this.state.horaLlegada!==""){
        	this.state.fechaLlegada = document.querySelector('input[name="fechaLlegada"]').value;
        	fullArrivalDate = this.state.fechaLlegada.concat(this.state.horaLlegada);
            yearD = parseInt(fullArrivalDate.substring(0, 4));
            monthD = parseInt(fullArrivalDate.substring(5, 7));
            dayD = parseInt(fullArrivalDate.substring(8, 10));
            hourD = parseInt(fullArrivalDate.substring(10, 12));
            minuteD = parseInt(fullArrivalDate.substring(13));
            arrivalTime = new Date(yearD, monthD - 1, dayD, hourD, minuteD);

            return arrivalTime;
        }
	}
	
	getDepartureTime(){
		var fullDepartureDate = "";
        var yearD =  "";
        var monthD =  "";
        var dayD = "";
        var hourD =  "";
        var minuteD = "";
        var departureTime="";
 
		if(this.state.fechaSalida!=="" && this.state.horaSalida!==""){
			fullDepartureDate = this.state.fechaSalida.concat(this.state.horaSalida);
			yearD = parseInt(fullDepartureDate.substring(0, 4));
			monthD = parseInt(fullDepartureDate.substring(5, 7));
			dayD = parseInt(fullDepartureDate.substring(8, 10));
			hourD = parseInt(fullDepartureDate.substring(10, 12));
			minuteD = parseInt(fullDepartureDate.substring(13));
			departureTime = new Date(yearD, monthD - 1, dayD, hourD, minuteD);	     
			return departureTime;   
        }else if(this.state.fechaSalida==="" && this.state.horaSalida!==""){
        	this.state.fechaSalida = document.querySelector('input[name="fechaSalida"]').value;
        	fullDepartureDate = this.state.fechaSalida.concat(this.state.horaSalida);
            yearD = parseInt(fullDepartureDate.substring(0, 4));
            monthD = parseInt(fullDepartureDate.substring(5, 7));
            dayD = parseInt(fullDepartureDate.substring(8, 10));
            hourD = parseInt(fullDepartureDate.substring(10, 12));
            minuteD = parseInt(fullDepartureDate.substring(13));
            departureTime = new Date(yearD, monthD - 1, dayD, hourD, minuteD);

            return departureTime;
        }
	}
	
	componentDidMount() {
		const cookies = new CookiesUniversal();
 		if(cookies.get('cookies_analisis_bizkaitik_mugitu') === undefined){
			   $('#modalOpenCookies').modal('show') 
	    } 
	
		this.cookies()
 
		if(window.scrollY !== 0){
			 window.scrollTo( 0, 0 );
		}
		 
		if(document.getElementById('planificador') !== null && document.getElementById('lineas') !== null){
			 document.getElementById('planificador').classList.add('active');
			 document.getElementById('lineas').classList.remove('active');  
		}	

		this.setState({ version: "app_planificadorRutas"}); 
		this.setState({ checked: false });

		if(this.state.origin === "undefined"){
			this.state.origin = "";
		}else if(this.state.origin!=="" && this.state.origin !== undefined && this.state.destination !== "" && this.state.destination !== undefined && typeof undefined){
			this.searchRoute(); 
		} 

		if(this.props.match.params.travelMode !== "undefined" && this.props.match.params.travelMode !== undefined){
			var valores = this.props.match.params.travelMode.split(",")
   			
			valores.forEach((element) =>{
   				this.selectTransport(element);
   				document.getElementById(element.toLowerCase()).checked = true;
   			});
   			
			this.searchRoute();
		}
		
		var now = new Date();
	   	var month ="", day="", hour="";
	   	
	   	if((now.getMonth() + 1)<10){
	   		month ="0"+(now.getMonth() + 1);
	   	}else{
	   		month =now.getMonth() + 1;
	   	}
	   	
	   	if(now.getDate()<10){
	   		day ="0"+now.getDate();
	   	}else{
	   		day = now.getDate();
	   	}
	   	
	   	if(now.getHours()<10){
	   		hour = "0"+now.getHours();
	   	}else{
	   		hour = now.getHours();
	   	}
	   	
	   	var departureTime = new Date(this.props.match.params.dt); 
	   	var arrivalTime = new Date(this.props.match.params.at); 
	   	
	   	if(this.props.match.params.dt!=="undefined" && typeof "string" && !typeof undefined){
	   		document.getElementById("fechaSalida").defaultValue = departureTime.getFullYear()+"-"+(departureTime.getMonth() + 1)+"-"+departureTime.getDate();
		   	document.getElementById("horaSalida").defaultValue = departureTime.getHours()+":"+departureTime.getMinutes();
		   	this.searchRoute()
	   	}else if(this.props.match.params.at!=="undefined" && typeof "string" && !typeof undefined){
		   	document.getElementById("fechaLlegada").defaultValue = arrivalTime.getFullYear()+"-"+(arrivalTime.getMonth() + 1)+"-"+arrivalTime.getDate();
		   	document.getElementById("horaLlegada").defaultValue = arrivalTime.getHours()+":"+arrivalTime.getMinutes();
		   	this.searchRoute()
	   	}
	   	
	   	if(this.props.match.params.at === undefined && this.props.match.params.dt === undefined){
		   	document.getElementById("fechaSalida").defaultValue = now.getFullYear()+"-"+month+"-"+day;
		   	document.getElementById("fechaLlegada").defaultValue = now.getFullYear()+"-"+month+"-"+day;
		   	document.getElementById("horaLlegada").defaultValue = hour+":"+now.getMinutes();
		   	document.getElementById("horaSalida").defaultValue = hour+":"+now.getMinutes();
	   	}

	   	var {t} = this.props;
	   	
	   	if(this.props.match.params.routePreference !=="undefined" && typeof "string" && !typeof undefined ){
	   		if(this.props.match.params.routePreference.includes("FEWER_TRANSFER")){
		   		this.filterRuta(t('keys.rutaTransbordos'), this.props.match.params.routePreference)
		   	}else if(this.props.match.params.routePreference.includes("LESS_WALKING")){
		   		this.filterRuta(t('keys.rutaAndando'), this.props.match.params.routePreference)
		   	}else{
		   		this.filterRuta(t('keys.rutaRapida'), undefined)
		   	}
	   	}	   	
	}
	
	cookies(){
			var {t} = this.props;
		   	
			var dir = window.location.origin+"/"+"#";
			const pathname = window.location.href.substring(dir.length); 
			const cookies = new CookiesUniversal();
			var cookieAnalitica = cookies.get('cookies_analisis_bizkaitik_mugitu');
 
			if(cookieAnalitica ==="true"){
				ReactGA.set({ anonymizeIP: true }, ['defaultTracker']);
//				ReactGA.pageview(pathname, ['defaultTracker'], t('keys.menuPlanificador')); 
				ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.menuPlanificador'), tracking: 'defaultTracker' });

			}else if(cookieAnalitica ==="false") {
				ReactGA.set({ anonymizeIP: true }, ['anonymousTracker']);
//				ReactGA.pageview(pathname, ['anonymousTracker'], t('keys.menuPlanificador'));
				ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.menuPlanificador'), tracking: 'anonymousTracker' });

			}
	}
	
	searchRoute () {
		this.cookies()
		var arrivalTime = new Date();
		var departureTime = new Date();
		var preference = this.state.preference;
		
		
		if(this.props.match.params.dt===undefined){
			departureTime = this.getDepartureTime();
		}else if(this.props.match.params.dt==="undefined" && typeof "string"){
			departureTime = undefined
		}else{
			departureTime = new Date(this.props.match.params.dt)
		}
		
		if(this.props.match.params.at === undefined){
			arrivalTime = this.getArrivalTime();
		}else if(this.props.match.params.at==="undefined" && typeof "string"){
			arrivalTime = undefined
		}else{
			arrivalTime = new Date(this.props.match.params.at);
		}

		var transitModes = this.state.transit; 
		var transitOptions = {}
		if(transitModes==="undefined"){
			transitOptions = {
					departureTime: departureTime,
					arrivalTime: arrivalTime,
					routingPreference: preference
				}	
		}else{
			 transitOptions = {
					departureTime: departureTime,
					arrivalTime: arrivalTime,
					modes: transitModes,
					routingPreference: preference
				}	
		}
		
		window.location.href = this.direction(this.state.origin, this.state.destination, departureTime, arrivalTime, this.state.transit, preference);
		
		if (this.state.origin !== '' && this.state.destination !== '') {			
			const google = window.google;
			const directionService = new google.maps.DirectionsService()
			directionService.route({
				origin: this.state.origin,
				destination: this.state.destination,
				travelMode: google.maps.TravelMode.TRANSIT,
				transitOptions: transitOptions,
				provideRouteAlternatives: true
			}, (response, status) => {
				if (status === google.maps.DirectionsStatus.OK) {
					this.setState(
						() => ({
							directions: response,
							resultados:2
						}), function () {
							this.displayAlerts().then(value => 
								this.setState({hasAlert: this.state.hasAlert})
							);
						}
					)		
				} else if(status === google.maps.DirectionsStatus.ZERO_RESULTS){
					this.setState(
							() => ({
							resultados:0
							
							})
					)
				}else if(status === google.maps.DirectionsStatus.NOT_FOUND){

					if(this.state.origin === " "){
						this.setState(
								() => ({
									resultados:3
								}))
					}else if(this.state.destination === " " ){
						this.setState(
								() => ({
									resultados:4
								})
							)	
					}
						
				}
			})
		}else if(this.state.origin === undefined || this.state.origin === ""){
			this.setState(
					() => ({
						resultados:3
					})
				)	
		}else if(this.state.destination === undefined || this.state.destination === "" ){
			this.setState(
					() => ({
						resultados:4
					})
				)	
		}else{
			this.setState(
					() => ({
						resultados:1
					})
				)	
		}
	} 
	
	direction(origin, destination, departureTime, arrivalTime, transitModes, routePreference){
		
		var direction="/#/planificador_rutas/";
		console.log(origin, destination)
		console.log("des " + typeof destination)
		if(origin !== ""){
			direction = direction + origin
		}else{
			direction = direction + "undefined"
		}
		
		if(destination !== ""){
			direction = direction +"/"+ destination
		}else{ 
			direction = direction + "/undefined"
		}

    	if(this.state.transit.length !==0){
    		direction = direction+"/"+departureTime+"/"+arrivalTime
                    +"/"+this.state.transit+"/"+this.state.preference;
    	}else{
    		direction = direction+"/"+departureTime+"/"+arrivalTime
                    +"/undefined/"+this.state.preference;
    	}
 
		return direction;
	}
		
	filterHorario(literal){
		document.getElementById('salida').classList.add('hidden');
		document.getElementById('buttonSalida').innerHTML=literal;
		document.getElementById('buttonSalida').insertAdjacentHTML('beforeend', '<span class="material-icons">expand_more</span>');
		if(literal === "Seleccionar hora de salida" || literal==="Hautatu irteera ordua"){
			document.getElementById('llegadaM').style.display = "none";
			document.getElementById('salidaM').style.display = "block";
		} else if(literal === "Seleccionar hora de llegada" || literal === "Hautatu iritsiera ordua"){
			document.getElementById('llegadaM').style.display = "block";
			document.getElementById('salidaM').style.display = "none";
		}else{
			document.getElementById('llegadaM').style.display = "none";
			document.getElementById('salidaM').style.display = "none";
		}
  
	}
	
	filterRuta(literal, value){
	    this.setState({ preference: value }, this.searchRoute);

		document.getElementById('ruta_modo').classList.add('hidden');
		document.getElementById('buttonRuta').innerHTML=literal;
		document.getElementById('buttonRuta').insertAdjacentHTML('beforeend', '<span class="material-icons">expand_more</span>');

	}
	
	getCurrentPosition(){ 
		var pos;
        if (navigator.geolocation) { 
            navigator.geolocation.getCurrentPosition(position => {
                pos = {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude
                };
                const google = window.google;
                var geocoder = new google.maps.Geocoder; 
                geocoder.geocode({'location': pos}, (results:any, status:any) => {
                    if (status === 'OK') {
                        if (results[0]) {
                            this.originPlace = results[0].formatted_address.replace(', España','');
                            this.originPlaceId = results[0].place_id;
                            this.currentDestinationId = results[0].place_id;
                            this.setState({origin : results[0].formatted_address.replace(', España','')})
                        } else {
                            console.log('No results found in rutas search header on getcurrentposition');
                        }    
                    } else {
                        console.log('Geocoder failed due to in rutas home : ' + status);
                    }
                });
            });
        }  
	}

	switchInputs(){
		this.state.origin = document.getElementById("destination").value;
		this.state.destination = document.getElementById("origin").value;
		
		document.getElementById("destination").value = this.state.destination;
		document.getElementById("origin").value = this.state.origin; 
	}
	
	checkAlert(line, i, j) {
	    return new Promise((resolve, reject) => {
		    liferayAPI.checkAlerta(this.props.i18n.language, process.env.REACT_APP_SCTRUCTURE_ID_ALERTAS , line).then(response => {
				if(response.items.length > 0) {
					this.state.hasAlert[i].splice(j, 1, true);
				}
				resolve();
			});
		});
	}
	
	displayAlerts() {
		return new Promise(resolve => {
			if (this.state.directions != null) {
				var promises = [];
				this.state.hasAlert = Array(this.state.directions.routes.length);
				for (var i = 0; i < this.state.directions.routes.length; i++) {
					this.state.hasAlert[i] = Array(this.state.directions.routes[i].legs[0].steps.length).fill(false);
					for (var j = 0; j < this.state.directions.routes[i].legs[0].steps.length; j++) {
						
						var lineName = "";
						
						if (this.state.directions.routes[i].legs[0].steps[j].travel_mode === 'TRANSIT' && this.state.directions.routes[i].legs[0].steps[j].transit.line.vehicle.type === 'BUS') {
							lineName = this.state.directions.routes[i].legs[0].steps[j].transit.line.short_name;
							promises.push(this.checkAlert(lineName, i, j));
						} else if (this.state.directions.routes[i].legs[0].steps[j].travel_mode === 'TRANSIT') {
							lineName = this.state.directions.routes[i].legs[0].steps[j].transit.line.name;
							promises.push(this.checkAlert(lineName, i, j));
						}
					}
				}
				Promise.all(promises).then(values => {
					resolve()
				});
			}
		});
	}
	
	render() {
		
		const {t} = this.props;
		let routes;
		const bizkaiaRegion = {
			north: 43.459039,
			south: 42.972874,
			east: -2.425362,
			west: -3.449827
		}

		const country = {
		 country: "es"
		}

		const options = {
			strictBounds: true,
			language: this.props.i18n.language
		}
				
		//html para pintar las rutas
		if ((this.state.directions !== undefined && this.state.directions !== null) && this.state.directions.routes.length === this.state.hasAlert.length){
			routes = this.state.directions.routes.map((route, indexRoute) => (
				<li className="direction-info" key={indexRoute}>
				    <div className="left">
				        <p className="total_time_spent">{route.legs[0].duration.text}</p>
				        {
                            route.legs[0].departure_time !== undefined &&
                            <div className="duration">
                                <img src={IconDurationRutes} alt=""/>
                                <p className="start_end_time"><span>{route.legs[0].departure_time.text}</span> <span>{route.legs[0].arrival_time.text}</span></p>
                            </div>
                        }
                        {
                            route.legs[0].departure_time === undefined &&
                            <div className="duration">
                                <img src={IconDurationRutes} alt=""/>
                                <p className="start_end_time"><span>{route.legs[0].steps[0].distance.text}</span></p>
                            </div>
                        }
				    </div>

				    <div className="right">
                        <ul className="direction-steps">
                        {
                        route.legs[0].steps.map((step, index) => (
                            <li className="item" key={index}>
                            {
                                step.travel_mode === 'WALKING' &&
                                <div className="step_walk"> 
                                    <span className="material-icons" title="Walking" aria-hidden="true">directions_walk</span>
                                    <p>{step.duration.text}</p>
                                </div>
                            }
                            {	step.travel_mode === 'TRANSIT' && step.transit.line.vehicle.type === 'SUBWAY' &&
                                <div className="step_subway">
                            		<img className={this.state.hasAlert[indexRoute][index] ? "alert" : "alert hidden"} src={IconAlarmLine} alt=""/>
                                    <span className="material-icons" title="Subway" aria-hidden="true">directions_railway_filled</span>
                                    <p className="travel_vehicle_info bus">
                                        {step.transit.line.name}
                                    </p>
                                </div>
                            }
                            {	step.travel_mode === 'TRANSIT' && step.transit.line.vehicle.type === 'BUS' &&
                                <div className="step_bus">
                            		<img className={this.state.hasAlert[indexRoute][index] ? "alert" : "alert hidden"} src={IconAlarmLine} alt=""/>
                                    <span className="material-icons" title="Bus" aria-hidden="true">directions_bus</span>
                                    <p>{step.transit.line.agencies[0].name}</p>
                                    <p className="travel_vehicle_info bus">
                                        {step.transit.line.short_name}
                                    </p>
                                </div>
                            }
                            {
                                step.travel_mode === 'TRANSIT' && step.transit.line.vehicle.type === 'TRAM' &&
                                <div className="step_tram">
                                	<img className={this.state.hasAlert[indexRoute][index] ? "alert" : "alert hidden"} src={IconAlarmLine} alt=""/>
                                    <span className="material-icons" title="Tram" aria-hidden="true">tram</span>
                                    <p>{step.transit.line.name}</p>
                                    <p className="travel_vehicle_info bus">
                                        {step.transit.line.short_name}
                                    </p>
                                </div>
                            }
                            {
                                step.travel_mode === 'TRANSIT' && step.transit.line.vehicle.type === 'FUNICULAR' &&
                                <div className="step_funi">
                                	<img className={this.state.hasAlert[indexRoute][index] ? "alert" : "alert hidden"} src={IconAlarmLine} alt=""/>
                                    <span className="material-icons" title="Funicular" aria-hidden="true">tram</span>
                                    <p>{step.transit.line.name}</p>
                                    <p className="travel_vehicle_info bus">
                                        {step.transit.line.short_name}
                                    </p>
                                </div> 
                            }
                            {
                                step.travel_mode === 'TRANSIT' && step.transit.line.vehicle.type === 'HEAVY_RAIL' &&
                                <div className="step_train">
                                	<img className={this.state.hasAlert[indexRoute][index] ? "alert" : "alert hidden"} src={IconAlarmLine} alt=""/>
                                    <span className="material-icons" title="Train" aria-hidden="true">train</span>
                                    <p>{step.transit.line.agencies[0].name}</p>
                                    <p className="travel_vehicle_info bus">
                                        {step.transit.line.short_name}
                                    </p>
                                </div>
                            }
                            {
                            	index !==(route.legs[0].steps.length)-1 &&
                            	<span className="material-icons separator" aria-hidden="true">east</span>
                            }
                            
                            </li>
                        ))

                    }
                        { 
                        	this.state.transit.length !==0 &&
                            <Link className="link_ruta"
                                to={"/planificador_detalles/"+indexRoute+"/"+this.state.origin+
                                      "/"+this.state.destination+"/"+this.state.directions.request.transitOptions.departureTime+"/"+this.state.directions.request.transitOptions.arrivalTime
                                      +"/"+this.state.transit+"/"+this.state.preference}
                              id={"routeButton"+indexRoute}
                              ><span className="sr-only">{t('keys.masDetalles')}</span></Link>
                       
                        }
                       
                        </ul>
                        { 
                        	this.state.transit.length ===0 &&
                        
                            <Link className="link_ruta"
                                to={"/planificador_detalles/"+indexRoute+"/"+this.state.origin+
                                      "/"+this.state.destination+"/"+this.state.directions.request.transitOptions.departureTime+"/"+this.state.directions.request.transitOptions.arrivalTime
                                      +"/undefined/"+this.state.preference}
                              id={"routeButton"+indexRoute}
                              ><span className="sr-only">{t('keys.masDetalles')}</span></Link>
                       
                        }
                        {
                        	 
                        	route.legs[0].steps.map((step, index) =>(
                       			step.travel_mode === 'TRANSIT' && index===0 &&
                    			<p className="more_info" key={index}>{step.instructions}</p>
                    			
                        	))
                        } 
                        {
                       	 
                        	route.legs[0].steps.map((step, index) =>(
                       			step.travel_mode === 'TRANSIT' && index===1 &&
                    			<p className="more_info" key={index}>{step.instructions}</p>
                    			
                        	))
                        }
				    </div>

				</li> 
			));
		}

	return(
		<React.Fragment>
		<Header handleToUpdate = {this.handlerModeView} action={this.handlerSelectionToogle} title={t('keys.menuPlanificador')} onSelectLanguage={this.handleLanguage}/>
		    <div className="planificador_sections container" id="main-content">
		    <div className="row">
                    <div className="col-12 col-lg-4 content_left">
                        <div className={this.state.version + " cabecera_planificador"}>
                            <div className="container" onMouseLeave={() => document.getElementById('salida').classList.add('hidden')}>
                                <h1 className="title_h1_desktop">{t('keys.planificadorruta')}</h1>
                                <div className="searh_rute">
                                    <div className="mb-3">
                                        <Autocomplete onLoad={this.onLoad} onPlaceChanged={this.onPlaceChanged} bounds={bizkaiaRegion} options={options} restrictions={country}>
                                            <input type="text" id="origin" placeholder={t('keys.origen')} value={this.state.origin} onChange={e => this.onOriginChange(e.target.value)} aria-label={t('keys.seleccioneOrigen')}/>
                                        </Autocomplete>
                                       <button type="button" className="button_location" onClick={() => this.getCurrentPosition()}>
                                           <img src={IconPlaniLotation} alt=""/>
                                           <span className="sr-only">{t('keys.tuUbicacion')}</span>
                                       </button>
                                    </div>
                                    <div className="mb-3">
                                        <Autocomplete onLoad={this.onLoad} onPlaceChanged={this.onPlaceChanged} bounds={bizkaiaRegion} options={options} restrictions={country}>
                                            <input type="text" id="destination" placeholder={t('keys.destino')} value={this.state.destination} onChange={e => this.onDestinationChange(e.target.value)} aria-label={t('keys.seleccioneDestino')}/>
                                        </Autocomplete>
                                    </div>
                                    <button type="button" className="button_invert" onClick={() => this.switchInputs()}>
                                        <span className="sr-only">{t('keys.invertir')}</span>
                                        <img src={IconPlaniHome} alt=""/>
                                    </button>
                                    <div className="button_invert change">
                                        <span className="sr-only">{t('keys.invertirUbicacion')}</span>
                                        <img src={IconPlaniHome2} alt=""/>
                                    </div>
                                    <button type="button" className="button_buscar" onClick={this.searchRoute} id="planificador_buscar">{t('keys.buscarRuta')}</button>
                                </div>

                                <div className="actions_rutes">
                                    <button type="button" className="button_planificador" onClick={() => document.getElementById('salida').classList.remove('hidden')} id="buttonSalida" data-titlemodalhora={t('keys.salirAhora')}>
                                    {t('keys.ahora')}
                                    <span className="material-icons">expand_more</span>
                                    </button>
                                    <ul className="select_box hidden" id="salida">
                                        <li>
                                            <Link to="/planificador_rutas" className="item" onClick={(text) => this.filterHorario(t('keys.ahora'), "ahora")} >{t('keys.ahora')}</Link>
                                        </li>
                                        <li>
                                            <Link to="/planificador_rutas" className="item" onClick={(text) => this.filterHorario(t('keys.horaSalida'), "horaSalida")} data-toggle="modal" data-target="#modalOpenHora">{t('keys.horaSalida')}</Link>

                                        </li>
                                        <li>
                                            <Link to="/planificador_rutas" className="item" onClick={(text) => this.filterHorario(t('keys.horaLlegada'), "horaLlegada")} data-toggle="modal" data-target="#modalOpenHora">{t('keys.horaLlegada')}</Link>
                                        </li>
                                    </ul>
                                    <button type="button" className="button_planificador" data-toggle="modal" data-target="#modalOpenTipoTransporte">
                                    {t('keys.tipoTransporte')}
                                    </button>
                                </div>
                            </div>

                        </div>

                        {/* Modal open hora */}
                        <div className="modal_bm modal fade" id="modalOpenHora" tabIndex="-1" role="dialog" aria-labelledby="modalOpenHora" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                              <div className="modal-content">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <img src={IconClose} alt=""/>
                                </button>
                              <form className="form_bm" action="/planificador_rutas">

                                  <fieldset id="llegadaM">
                                      <legend className="title" id="modalOpenHora">{t('keys.horaLlegada')}</legend>
                                      <div className="item ">
                                          <input id="fechaLlegada" type="date" name="fechaLlegada" className="form-control" onChange={event=> this.checkFecha(event.target.value, "llegada")}/>
                                      </div>

                                      <div className="item ">
                                         <input id="horaLlegada" type="time" name="horaLlegada" className="form-control" onChange={event=> this.checkHora(event.target.value, "llegada")}/>
                                      </div>
                                  </fieldset>

                                  <fieldset id="salidaM">
                                      <legend className="title" id="modalOpenHora">{t('keys.horaSalida')}</legend>
                                      <div className="item ">
                                          <input id="fechaSalida" type="date" name="fechaSalida" className="form-control" onChange={event=> this.checkFecha(event.target.value, "salida")}/>
                                      </div>

                                      <div className="item ">
                                         <input id="horaSalida" type="time" name="horaSalida" className="form-control" onChange={event=> this.checkHora(event.target.value, "salida")}/>
                                      </div>
                                  </fieldset>

                                  <button type="button" className="button_modal" data-toggle="modal" data-target="#modalOpenHora" onClick={this.searchRoute}>
                                  {t('keys.aplicarFiltros')}
                                  </button>
                                  </form>
                               </div>
                            </div>
                        </div>

                        {/* Modal open transporte */}
                        <div className="modal_bm modal fade" id="modalOpenTipoTransporte" tabIndex="-1" role="dialog" aria-labelledby="modalTransporte" aria-hidden="true">
                          <div className="modal-dialog modal-dialog-centered" role="document">
                            <div className="modal-content">
                            <form id="modal" action="/planificador_rutas">
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                  <img src={IconClose} alt=""/>
                                </button>

                                <fieldset>
                                    <legend className="title" id="modalTransporte"> {t('keys.filtrarTransporte')}</legend>

                                    <div className="item_check">
                                        <input id="bus" type="checkbox" name="bus" onChange={() => this.selectTransport("BUS")} />
                                        <label htmlFor="bus">
                                        {t('keys.bus')}
                                            <span className="material-icons" aria-hidden="true">directions_bus_filled</span>
                                        </label>
                                    </div>

                                    <div className="item_check">
                                        <input id="train" type="checkbox" name="train" onChange={() => this.selectTransport("TRAIN") }/>
                                        <label htmlFor="train">
                                        {t('keys.tren')}
                                            <span className="material-icons blue" aria-hidden="true">train</span>
                                        </label>
                                    </div>

                                    <div className="item_check">
                                        <input id="tram" type="checkbox" name="tram" onChange={() => this.selectTransport("TRAM") }/>
                                        <label htmlFor="tram">
                                        {t('keys.tranvia')}
                                            <span className="material-icons green" aria-hidden="true">tram</span>
                                        </label>
                                    </div>

                                    <div className="item_check">
                                        <input id="subway" type="checkbox" name="subway" onChange={() => this.selectTransport("SUBWAY") }/>
                                        <label htmlFor="subway">
                                        {t('keys.metro')}
                                            <span className="material-icons orange" aria-hidden="true">directions_railway_filled</span>
                                        </label>
                                    </div>

                                </fieldset>

                                <button type="button" className="button_modal" data-toggle="modal" data-target="#modalOpenTipoTransporte" onClick={this.searchRoute} id="tipoTransporte">
                                {t('keys.aplicarFiltros')}
                                </button>
                                </form>
                             </div>
                          </div>
                        </div>

                        <div className="content_results">
                            <div className="container">
                                <div className="content_select">
                                    <button type="button" className="button_rutas" onClick={() => document.getElementById('ruta_modo').classList.toggle("hidden")} id="buttonRuta" data-titleruta={t('keys.rutasMenos')}>
                                    	{parse(t('keys.rutaRapida'))}
                                        <span className="material-icons">expand_more</span>
                                    </button>

                                    <ul className="select_box hidden" id="ruta_modo">
                                        <li>
                                            <Link to="/planificador_rutas" className="item" onClick={(text) => this.filterRuta(t('keys.rutaRapida'), undefined)}>
                                                {parse(t('keys.rutaRapida'))}
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/planificador_rutas" className="item" onClick={(text) => this.filterRuta(t('keys.rutaTransbordos'), 'FEWER_TRANSFERS')}>{parse(t('keys.rutaTransbordos'))}</Link>
                                        </li>
                                        <li>
                                            <Link to="/planificador_rutas" className="item" onClick={(text) => this.filterRuta(t('keys.rutaAndando'), 'LESS_WALKING')}>{parse(t('keys.rutaAndando'))}</Link>
                                        </li>
                                    </ul>
                                </div>
                                <div className="direction-options">
                               {
                                    this.state.resultados===2 &&
                                    
                                    <ul className="list_rutas">
                                        {routes}
                                    </ul>
                                }
                                {
                                    this.state.resultados===0 &&
                                    <p className="text_description">{t('keys.sinResultados')}</p>
                                }
                                {
                                    this.state.resultados===1 &&
                                    <p className="text_description">{t('keys.sinBusqueda')}</p>
                                }
                                {
                                    this.state.resultados===3 &&
                                    <p className="text_description">{t('keys.introduzcaOrigen')}</p>
                                }
                                {
                                    this.state.resultados===4 &&
                                    <p className="text_description">{t('keys.introduzcaDestino')}</p>
                                }
                                </div>

                            </div>
                        </div>
                    </div>

                    {/* Mapa */}
                    <div className="mapa_linea col-12 col-lg-8">

                        <GoogleMap
                            mapContainerStyle={{
                                }}
                            center={{
                                lat: 43.2633182,
                                lng: -2.9685838
                                }}
                            zoom={15}
                            options={{
                              gestureHandling:'greedy',
                              mapTypeControl: false,
                              streetViewControl: false,
                              clickableIcons: true,
                              zoomControl: false,
                              fullscreenControl:true
                            }}

                            onLoad={map => {
                            console.log('onLoad map: ', map)
                            }}
                            // optional
                            onUnmount={map => {
                            console.log('onUnmount map: ', map)
                            }}
                        >
                       {
                       this.state.directions !== null &&
                       	<React.Fragment>
                       		<Marker
					            position={this.state.directions.routes[0].legs[0].start_location}
					            icon={IconOrigin}
				          	/>
				          	<Marker
					            position={this.state.directions.routes[0].legs[0].end_location}
					            icon={IconDestination}
				          	/>
				         </React.Fragment>
				        }
                        {
                        this.state.directions !== null &&
                        (
                            <DirectionsRenderer
                            // required
                            options={{
                                directions: this.state.directions,
                                routeIndex: this.state.directionIndex,
                                suppressMarkers: true
                            }}
                            // optional
                            onLoad={directionsRenderer => {
                                console.log('DirectionsRenderer onLoad directionsRenderer: ', directionsRenderer)
                            }}
                            // optional
                            onUnmount={directionsRenderer => {
                                console.log('DirectionsRenderer onUnmount directionsRenderer: ', directionsRenderer)
                            }}
                            />
                        )
                        }
                        </GoogleMap>

                    </div>
                </div>
            </div>
            <AcessoRapido/>
            <Footer />
			</React.Fragment>
		);
	}
}
export default withTranslation()(PlanificadorComponent);
