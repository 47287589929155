import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import liferayAPI from '../../apiLiferay';
import parse from 'html-react-parser';

import Header from '../general/Header';
import Footer from '../general/Footer';
import AcessoRapido from '../general/AccesoRapidoComponent';

import IconRifgt from '../../images/icons/icon_arrow_right_red.svg';
import IconRifgtHover from '../../images/icons/icono_arrow_red_circle_hover.svg';
import ReactGA from 'react-ga4';
import CookiesUniversal from 'universal-cookie';
import $ from 'jquery';

class AlertasDetalleComponent extends Component {

	 constructor(props) {
        super(props);

         this.state = {
             version: "alertas",
             view: "no_list",
             showSelections: "none",
             contenidosGeneralesLiferay:[],
             contenidosLiferay: [],
             locale: "eu_ES"
         };
     }
	 
	 componentDidMount(){
		const cookies = new CookiesUniversal();
	 	if(cookies.get('cookies_analisis_bizkaitik_mugitu') === undefined){
			   $('#modalOpenCookies').modal('show') 
		}
	 
		this.cookies()
		if(window.scrollY !== 0){
			window.scrollTo( 0, 0 );
		}
		 	 
		liferayAPI.getArticlesContenidoAlertasDetallesLiferay(this.props.i18n.language, this.props.match.params.articleId).then(response => 
             this.setState({contenidosGeneralesLiferay: response})
        );
		 
		liferayAPI.getArticlesContenidoLiferay(this.props.i18n.language, process.env.REACT_APP_STRUCTURE_ID_OPERADOR).then(response => 
		 	this.setState({contenidosLiferay: response.items})
		);

        if(document.getElementById('planificador')!==null && document.getElementById('lineas')!==null){
             document.getElementById('planificador').classList.remove('active');document.getElementById('lineas').classList.remove('active');
        }
	 }
	 
	cookies(){
		var {t} = this.props;
		   	
		var dir = window.location.origin+"/"+"#";
		const pathname = window.location.href.substring(dir.length); 
		const cookies = new CookiesUniversal();
		var cookieAnalitica = cookies.get('cookies_analisis_bizkaitik_mugitu');
		 
		if(cookieAnalitica ==="true"){
				ReactGA.set({ anonymizeIP: true }, ['defaultTracker']);
//				ReactGA.pageview(pathname, ['defaultTracker'], t('keys.menuAlertasDetalle')); 
				ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.menuAlertasDetalle'), tracking: 'defaultTracker' });

		}else if(cookieAnalitica ==="false") {
				ReactGA.set({ anonymizeIP: true }, ['anonymousTracker']);
//				ReactGA.pageview(pathname, ['anonymousTracker'], t('keys.menuAlertasDetalle')); 
				ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.menuAlertasDetalle'), tracking: 'anonymousTracker' });

		}
	}
	 
	handleLanguage = (langValue) => {
		 this.setState({locale: langValue});
	}
	
	handlerModeView = (view) => {
	    this.setState({ view: view});
	}

	handlerSelectionToogle = () => {
	    if(this.state.showSelections === "none"){
	    	this.setState({ showSelections: "block" });
	    }else{
	    	this.setState({ showSelections: "none" });
	    }
	}
 
	getFecha(contenido){
		 var fullDate = contenido.datePublished;
		 var dt = new Date(fullDate);
		 
		 var datestring; 
			
		 if(this.props.i18n.language ==='eu'){
			 datestring = dt.getFullYear()  + "/" + (dt.getMonth()+1) + "/" + dt.getDate() + " - " +
				 dt.getHours() + ":" + (dt.getMinutes()<10?'0'+dt.getMinutes():dt.getMinutes())+"h";
		 }else{
			 datestring = dt.getDate()  + "/" + (dt.getMonth()+1) + "/" + dt.getFullYear() + " - " +
				 dt.getHours() + ":" +(dt.getMinutes()<10?'0'+dt.getMinutes():dt.getMinutes())+"h";
		 }
		 
		return datestring;
	}
	
	render() {
	  const { t } = this.props;
	  const regex = /[^\w\s]/gi;
	  let contenidoL = [];
	  let general;
	  
	  if (this.state.contenidosGeneralesLiferay !== undefined && this.state.contenidosGeneralesLiferay.length !==0){
		  contenidoL.push(this.state.contenidosGeneralesLiferay);
		  general = contenidoL.map((contenido, index) => (
				<React.Fragment key={index}>  
			  		{
						contenido.contentFields[0].contentFieldValue.data === "" &&
						<h1 className="title_h1">{contenido.title}</h1>
					}
					{
						contenido.contentFields[0].contentFieldValue.data !== "" && 
						<h1 className="title_h1">{contenido.contentFields[0].contentFieldValue.data}</h1>
					}	
			  		<p className="time">{this.getFecha(contenido)}</p>
			  		<div className="lineas_afectadas">
				  		<h2 className="title">{t('keys.lineasAfectadas')}</h2>
				  		<ul className="list_lineas">
	                        <li className="items" key={index}>
                                <p className="title_linea">
                                    <span className="material-icons" aria-hidden="true">directions_bus_filled</span>
                                    {contenido.contentFields[5].contentFieldValue.data.replace(regex, "").charAt(0).toUpperCase() + contenido.contentFields[5].contentFieldValue.data.replace(regex, "").slice(1)}
                                </p>
                                {
                		  			contenido.contentFields[6].contentFieldValue.data !== "" &&
                		  		
                		  			<p className="linea">{contenido.contentFields[6].contentFieldValue.data.replaceAll(",",", ")}</p>
                                }	
	                        </li>
	                    </ul>
			  		</div>
		  		
			  		<div className="content_info">
			  		    {parse(contenido.contentFields[1].contentFieldValue.data)}
			  		</div>
			  		{
			  			contenido.contentFields[2].contentFieldValue.data !== "" &&
			  			<a className="link_more_static left" href={contenido.contentFields[2].contentFieldValue.data} target="_blank" rel="noopener noreferrer"> 
				  			{t('keys.verMas')}
				  			<img className="normal" src={IconRifgt} alt=""/>
				        	<img className="hover" src={IconRifgtHover} alt=""/>
	                    </a>
			  		}
			  		
		  		
		  		</React.Fragment>
		  		 
		  ));
	    }

	  return(
			<React.Fragment>
				<Header handleToUpdate = {this.handlerModeView} action={this.handlerSelectionToogle} title={t('keys.menuAlertasDetalle')}  onSelectLanguage={this.handleLanguage} /> 
		    		<div className={this.state.version+ " section_content_alerts detail_info_alertas"} id="main-content">
		    		    <div className="container">
                           {general}
                        </div> 
		    		</div>
		    		 <AcessoRapido/>
		    		 <Footer />
		    </React.Fragment>
    );
  }
}
export default withTranslation()(AlertasDetalleComponent);
