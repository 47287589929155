import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import IconAppStore from '../../images/icons/tag_app_store.svg';
import IconGooglePlay from '../../images/icons/tag_google_play.svg';
import IconBikaibusApp from '../../images/ilustracion_bizkaibus_app.png';

class BizkaibusAppComponent extends Component {
	constructor(props) {
	    super(props);

	    this.state = {  
	      version: "app_bizkaibus",
	      locale: "eu_ES"
	    	  
	    };
	  }
	
	handlerModeView = (view) => {
		this.setState({ view: view});
	}
	
	handlerMapSelectionToogle = () => {
		if(this.state.showMapSelections === "none"){
	      this.setState({ showMapSelections: "block" });
	    }else{
	      this.setState({ showMapSelections: "none" });
	    }
	}

  render() {
	  const {t} = this.props;
	  
    return(
    		
    		<React.Fragment>
    			<h2 className="title" id="bizkaibusApp">{t('keys.bizkaibusApp')}</h2>
	    		<p className="description">{t('keys.disponibleGoogle')}</p>
	    		<div className="links_apps">
	    			<a className="link" href="https://apps.apple.com/es/app/bizkaibus/id871799243" target="_blank" rel="noopener noreferrer" title={t('keys.enlaceOtraPagina')}>
                        <img className="img" alt="" src={IconAppStore}/>
                        <span className="sr-only">{t('keys.appStore')}</span>
                    </a>
                    <a className="link" href="https://play.google.com/store/apps/details?id=com.lantikdesagmail.com.bizkaibus&showAllReviews=true" target="_blank" rel="noopener noreferrer" title={t('keys.enlaceOtraPagina')}>
                        <img className="img" alt="" src={IconGooglePlay}/>
                        <span className="sr-only">{t('keys.googlePlay')}</span>
                    </a>
                </div>
                <img className="imagen_banner" alt="" src={IconBikaibusApp}/>
            </React.Fragment>
    		
    );
  }
}
export default withTranslation() (BizkaibusAppComponent);
