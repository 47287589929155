import {Pushwoosh} from 'web-push-notifications';
import IconLogo from './images/logo-bm-pushwoosh.png';


const applicationCode: string | undefined = process.env.REACT_APP_APLICATION_CODE_PUSHWOOSH | "0CA38-0E626";
const pwInstance = new Pushwoosh();

class apiPushWoosh {

    initPushWoosh(){

        console.log("applicationCode en pushWoosh API :: "+applicationCode)

        pwInstance.push(['init', {
            logLevel: 'info', // possible values: error, info, debug
            applicationCode: process.env.REACT_APP_APLICATION_CODE_PUSHWOOSH, // you application code from Pushwoosh Control Panel
            defaultNotificationTitle: 'Bizkaitik Mugitu', // sets a default title for push notifications
            defaultNotificationImage: IconLogo, // URL to custom custom notification image
            autoSubscribe: false, // or true. If true, prompts a user to subscribe for pushes upon SDK initialization
            serviceWorkerUrl: '/service-worker.js', // <- your service worker url
            subscribeWidget: {
                enable: false,
                position: 'bottomLeft', //possible values: ‘bottomLeft’, ‘bottomRight’, ‘topLeft’, ‘topRight’
                bgColor: '#12AE7E',
                bellColor: 'white',
                bellStrokeColor: '#08754f',
                bellButtonBorder: '1px solid #379676',
                shadow: '0px 0px 6px rgba(0, 0, 0, 0.75)',
                size: '48px',
                indent: '20px',
                zIndex: '999999',
                tooltipText: {
                  successSubscribe: 'You are successfully subscribed!',
                  needSubscribe: 'Get notifications about important news!',
                  blockSubscribe: 'Click to see how to get notifications',
                  alreadySubscribed: 'You are already subscribed'
                }
            }
        }]);
    }

    async checkIsSuscribed(){
        let suscribed = await pwInstance.isSubscribed();
        return suscribed;
    }

    getPushwooshParams(){

        pwInstance.getParams().then(function(params) {
            params = params || {};
            var hwid = params.hwid;
            var pushToken = params.pushToken;
            var userId = params.userId;

            console.log("HWID - "+hwid+" PUSH TOKEN - "+pushToken+" USER ID - "+userId)
        });
    }

    async pushWooshSuscribe(){

        pwInstance.subscribe();

        await pwInstance.push(['onSubscribe', function(api) {
            console.log('User successfully subscribed');
        }]);
    }

    async pushWooshDesuscribe(){

        pwInstance.unsubscribe();

        await pwInstance.push(['onUnsubscribe', function(api) {
            console.log('User successfully unsubscribed');
        }]);
    }

    async setPushWooshTags(){

        var valuesLocalStorageLineas = this.getValuesLocalStorageLineas();
        var valuesLocalStorageConfiguracion = this.getValuesLocalStorageConfiguracion();

        await pwInstance.push(function(api) {
            api.setTags({
                'bm-lineas': valuesLocalStorageLineas,
                'bm-aviso-tipo': valuesLocalStorageConfiguracion

            });
        });
    }

    getValuesLocalStorageLineas() {

        var lineas = localStorage.getItem('lineas');
        var arrayLineas = [];
        var arrayLineasNames = [];

        if(lineas !== null && lineas !== undefined) {
            arrayLineas = JSON.parse(lineas);
        }

        arrayLineas.forEach(function (linea) {
            arrayLineasNames.push(linea.operador+"#"+linea.nombreLinea);
        });
        return arrayLineasNames;
    }

    getValuesLocalStorageConfiguracion() {

        var inputIncidencias = localStorage.getItem('inputIncidencias');
        var inputInformacion = localStorage.getItem('inputInformacion');
        var arrayConfiguracion = [];

        if(inputIncidencias != null){
            arrayConfiguracion.push("alerta")
        }

        if(inputInformacion != null){
            arrayConfiguracion.push("info")
        }
        return arrayConfiguracion;
    }

}

const pushWooshAPI = new apiPushWoosh();
export default pushWooshAPI;
