import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
 
import IconClose from '../../images/icons/close.png';
import IconShareWhatsapp from '../../images/icons/share_whatsapp.svg';
import IconShareTelegram from '../../images/icons/share_telegram.svg';
import IconShareEmail from '../../images/icons/share_email.svg';

class CompartirLineaComponent extends Component {
	
	constructor(props) {
	    super(props);

	    this.state = {  
	      contenidosLiferay:[],
	      locale: "eu_ES",
	      section: this.props.section
	    };
	  }
	
	componentDidMount() {
		if(this.state.section === "planificador") {
			document.getElementById('iframe-url').value = '<iframe src="' + window.location.protocol + '//' + window.location.hostname + '/#/planificador_mini/?de=' + encodeURIComponent(this.props.destino) + '" name="planificador_mini" scrolling="no" frameborder="1" marginheight="0px" marginwidth="0px" height="315px" width="560px"></iframe>';	
		}
	}
	
	shareMobile(operador, line) {
		
		var navigator;
        navigator = window.navigator;
        var title = "Bizkaitik Mugitu";
        var url = window.location.href;
        var lineText = operador + " | " + line;
        
        if (navigator.share) {
            navigator.share({
                title: title,
                text: lineText,
                url: url
            })
            .then(() => console.log('Share complete'))
            .error((error) => console.error('Could not share at this time', error))
        }
	}
	
	copyToClipboard() {
		const {t} = this.props;
		var copyText = document.getElementById("iframe-url");

		console.log(copyText);
		copyText.select();
  		copyText.setSelectionRange(0, 99999); /* For mobile devices */
		navigator.clipboard.writeText(copyText.value);
		
		document.getElementById("clipboard-button").textContent = t('keys.copied');		
	}
	
    render() {
    	const {t} = this.props;	 
    	
    return(
    		
        <React.Fragment>
	        <div className="modal_bm modal_share modal fade" id="modalOpenCompartirLinea" tabIndex="-1" role="dialog" aria-labelledby="modalOpenCompartirLinea" aria-hidden="true">
		        <div className="modal-dialog modal-dialog-centered" role="document">
		          <div className="modal-content">
		
		              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
		                <img src={IconClose} alt=""/>
		              </button>
		
		              <div className="header_modal">
		                  <h2 className="title">{t('keys.compartirLinea')}</h2>
		                  { this.state.section !== "planificador" &&
		                  	<h3 className="subtitle">{this.props.operador} | {this.props.line}</h3>
		                  }
		                  { this.state.section === "planificador" &&
		                  	<h3 className="subtitle">{this.props.destino}</h3>
		                  }
		                  <ul className="list_rrss">
		                      <li>
		                      		<a href={"whatsapp://send?text=" + encodeURIComponent(window.location.href)} className="item_rrss" title="Compartir en WhatsApp" target="_blank" rel="noopener noreferrer">
		                              <img src={IconShareWhatsapp} alt=""/>
		                              <span>{t('keys.whatsapp')}</span>
		                      		</a>
		                      </li>
		                      { this.state.section === "planificador" &&
		                      <li>
		                         	<a href={"https://t.me/share/url?url=" + encodeURIComponent(window.location.href) + "&text=" + this.props.origen+ " --- " + this.props.destino} className="item_rrss" title="Compartir en Telegram" target="_blank" rel="noopener noreferrer">
		                              <img src={IconShareTelegram} alt=""/>
		                              <span>{t('keys.telegram')}</span>
		                          </a>
		                      </li>
		                      }
		                      { this.state.section === "lineas" &&
		                      <li>
		                         	<a href={"https://t.me/share/url?url=" + encodeURIComponent(window.location.href) + "&text=" + this.props.operador+ " | " + this.props.line} className="item_rrss" title="Compartir en Telegram" target="_blank" rel="noopener noreferrer">
		                              <img src={IconShareTelegram} alt=""/>
		                              <span>{t('keys.telegram')}</span>
		                          </a>
		                      </li>
		                      }
		                      { this.state.section === "planificador" &&
		                      <li>
		                          <a href={"mailto:?&subject=" + "Bizkaitik Mugitu - " + t('keys.menuPlanificador') + "&body=" + this.props.origen + " --- " + this.props.destino + "%0A" + encodeURIComponent(window.location.href)} className="item_rrss" title="Compartir por email" target="_blank" rel="noopener noreferrer">
		                              <img src={IconShareEmail} alt=""/>
		                              <span>{t('keys.email')}</span>
		                          </a>
		                      </li>
		                      }
		                      { this.state.section === "lineas" &&
		                      <li>
		                          <a href={"mailto:?&subject=" +  this.props.operador + " | " + this.props.line + "&body=" + this.props.operador+ " | " + this.props.line + "%0A" + encodeURIComponent(window.location.href)} className="item_rrss" title="Compartir por email" target="_blank" rel="noopener noreferrer">
		                              <img src={IconShareEmail} alt=""/>
		                              <span>{t('keys.email')}</span>
		                          </a>
		                      </li>
		                      }
		                  </ul>
		              </div>
						
						{ this.state.section === "planificador" &&
			              <div className="body_modal">
			                  <h3 className="subtitle">{t('keys.insertarCodigo')}</h3>
			                  <div className="share_linea">
			                      <input className="text_code" type="text" id="iframe-url"></input>
			                      <button type="button" id="clipboard-button" className="button_copy" onClick={() => this.copyToClipboard()}>{t('keys.copiar')}</button>
			                  </div>
			              </div>
		              }
		
		           </div>
		        </div>
		    </div>
        </React.Fragment>	
    );
  }
}
export default  withTranslation()(CompartirLineaComponent);
