import React, { Component } from 'react';
import liferayAPI from '../../apiLiferay';
import { withTranslation } from 'react-i18next';
import parse from 'html-react-parser';

import { Link } from 'react-router-dom';

import IconRifgt from '../../images/icons/icon_arrow_right_red.svg';
import IconRifgtHover from '../../images/icons/icono_arrow_red_circle_hover.svg';

class DescubreBizkaiaComponent extends Component {

	constructor(props) {
	    super(props);

	    this.state = {
    		contenidosDescubre:[], 
    		locale: "eu_ES",
	    	version: "descubreBizkaia"
	    };
	  }
	
	componentDidMount() {
		liferayAPI.getArticlesContenidoLiferayByKey(this.props.i18n.language, process.env.REACT_APP_ARTICLE_ID_DESCUBRE_BIZKAIA ).then(response => 
			this.setState({contenidosDescubre: response})
		);  
	}

  render() { 

	  let contenidoDescubreLiferay;
	  let contenidoL = [];
	  const {t} = this.props;

	if (this.state.contenidosDescubre !== undefined && this.state.contenidosDescubre.length !==0){
		contenidoL.push(this.state.contenidosDescubre)
		contenidoDescubreLiferay = contenidoL.map((contenido, index) => (
				contenido.contentFields.map((contentFields, index) =>
				
					contentFields.name==="fichasFieldSet" && 
			      	<li className="list-item" key={index}> 
			      	    <img className="background_image" alt="" src={process.env.REACT_APP_URL+contentFields.nestedContentFields[1].nestedContentFields[2].contentFieldValue.image.contentUrl} />
		            	<div className="content_text">
		                	<h3 className="subtitle">{contentFields.nestedContentFields[1].nestedContentFields[0].contentFieldValue.data}</h3>
		                	<p className="description">{contentFields.nestedContentFields[1].nestedContentFields[1].contentFieldValue.data}</p>
		                	<a href={contentFields.nestedContentFields[1].nestedContentFields[5].nestedContentFields[0].contentFieldValue.data} target={contentFields.nestedContentFields[1].nestedContentFields[5].nestedContentFields[1].nestedContentFields[0].contentFieldValue.data==="true"?"_blank":""} rel="noopener noreferrer" className="link">
		                		<span className="sr-only">{contentFields.nestedContentFields[1].nestedContentFields[0].contentFieldValue.data}</span>
		                		<span className="material-icons" aria-hidden="true">arrow_forward</span>
		                	</a>
		                </div>
			    	</li>
			    )
	      ));
	    }
	  
    return(
    	<React.Fragment>
	    	<h2 className="title" id="descubre_bizkaia">{parse(t('keys.topRecorridos'))}</h2>
		    <ul className="list_card">
		    	{contenidoDescubreLiferay}
		    </ul>
		    <Link className="link_more" to="/descubre_bizkaia">
		    	{t('keys.verMas')}
                <img className="normal" src={IconRifgt} alt=""/>
                <img className="hover" src={IconRifgtHover} alt=""/>
            </Link>
	    </React.Fragment>
    		
    );
  }
}
export default withTranslation()(DescubreBizkaiaComponent);
