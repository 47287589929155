import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import Header from '../general/Header'; 
import Footer from '../general/Footer';

import AcessoRapido from '../general/AccesoRapidoComponent';
 
import ReactGA from 'react-ga4';
import CookiesUniversal from 'universal-cookie';
import $ from 'jquery';

class AyudaContactoComponent extends Component {
	 constructor(props) {
	    super(props);
 
	    this.state = {
    		version: "app_ayudacontacto",
    		view: "no_list",
    		showSelections: "none",
    		locale: "eu_ES" 	  
	    };
	  }

	handleLanguage = (langValue) => {
		 this.setState({locale: langValue});
	}
	
	handlerModeView = (view) => {
	    this.setState({ view: view});
	}

	handlerSelectionToogle = () => {
	    if(this.state.showSelections === "none"){
	      this.setState({ showSelections: "block" });
	    }else{
	      this.setState({ showSelections: "none" });
	    }
	  }	
	
	componentDidMount(){
		const cookies = new CookiesUniversal();
 		if(cookies.get('cookies_analisis_bizkaitik_mugitu') === undefined){
			   $('#modalOpenCookies').modal('show') 
	    } 
 		
        this.cookies();
		if(window.scrollY !== 0){
			 window.scrollTo( 0, 0 );
		 }
		 if(document.getElementById('planificador')!==null && document.getElementById('lineas')!==null){
			 document.getElementById('planificador').classList.remove('active');document.getElementById('lineas').classList.remove('active');  
		 }	
		 
	 }
	
	cookies(){
		 var {t} = this.props;
		   	
		 var dir = window.location.origin+"/"+"#";
		 const pathname = window.location.href; 		 
		 const cookies = new CookiesUniversal();
		 var cookieAnalitica = cookies.get('cookies_analisis_bizkaitik_mugitu');
 
		 if(cookieAnalitica ==="true"){
//			ReactGA.pageview(pathname, ['defaultTracker'], t('keys.ayudaContacto'));
				ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.ayudaContacto'), tracking: 'defaultTracker' });

		 }else if(cookieAnalitica ==="false") {
//			ReactGA.pageview(pathname, ['anonymousTracker'], t('keys.ayudaContacto'));
			ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.ayudaContacto'), tracking: 'anonymousTracker' });

		 }
	}
	 
	render() {
		const { t } = this.props;
		return(
			 <React.Fragment>
				<Header handleToUpdate = {this.handlerModeView} action={this.handlerSelectionToogle} title={t('keys.ayudaContacto')} cabecera="true" onSelectLanguage={this.handleLanguage}/>
			    	<div className={this.state.version} id="main-content">
						<h2 className="title">{t('keys.ayudaContacto')}</h2>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce aliquet lectus tincidunt, pellentesque dui nec, vulputate mi. Sed luctus volutpat nibh id placerat. Quisque interdum lacus eu quam ullamcorper, ut eleifend augue blandit. Pellentesque ultrices sem urna, et luctus metus maximus sed. Integer condimentum id sem at congue. Nunc pretium purus turpis, et pellentesque felis aliquet a. Duis pellentesque fringilla arcu non gravida. Morbi eu rhoncus eros. Etiam laoreet auctor velit. Phasellus lacinia dui quis magna pellentesque, eu pharetra dolor fringilla. Suspendisse felis sem, sodales at rutrum ac, ultrices sed mauris. Pellentesque quis erat maximus, maximus neque sed, sodales turpis. Nullam venenatis eget leo id ultricies. Integer lacinia ex ac nunc feugiat ornare. Vestibulum eget finibus tortor, non lobortis risus. Aliquam rutrum eleifend magna nec dapibus.</p>
                    <AcessoRapido/>
                    <Footer />
			</React.Fragment>
		);
	}
}
export default withTranslation()(AyudaContactoComponent);
