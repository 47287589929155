import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { isIE } from 'react-device-detect'

import '../../css/AvisoIE.css';
import noSoportado from '../../images/avisoIE/icon_IE_no-soportado.svg';
import newTab from '../../images/avisoIE/new-tab.svg';   

class AvisoIE extends Component {


    render() {
         const { t, i18n } = this.props;
         const limit = 2;
         const changeLanguage = lng => {
             i18n.changeLanguage(lng.substring(0, limit));
         }

	    if(isIE){
		     return(
		         <React.Fragment>
		             <div class='avisoModalIE fade show' id='modalIE' tabindex='0' aria-labelledby='modalIELabel' aria-modal='true' role='dialog'>
		                 <div class='modal-dialog-avisoModalIE'>
		                     <div class='modal-content'>
		                         <div class='modal-cabecera'>
		                             <div class='titulo'>
		                                 <div class='idiomas'>
		                                     <ul>
		                                         <li><a data-senna-off='true' href={window.location.hash} lang='EU' onClick={() => changeLanguage('eu_ES')}>Euskara</a></li>
		                                         <li><a data-senna-off='true' href={window.location.hash} lang='ES' onClick={() => changeLanguage('es_ES')}>Español</a></li>
		                                     </ul>
		                                 </div>
		                                 <h2 class='tituloAviso'> {t('keys.tituloRecomendacion')}
		                                    <span class="d-block">{t('keys.tituloNoCompatible')} </span> </h2>
		                             </div>
		                             <div class='icono'>
		                                 <img src={noSoportado} alt='' />
		                             </div>
		                         </div>
		                         <div class='modal-body'>
		                             <div class='texto'>
		                                 <p>{t('keys.principalNoCompatible')}</p>
		                                 <p>{t('keys.secundarioNoCompatible')}</p>
		                             </div>
		                             <div class='enlaces'>
		                                 <ul>
		                                     <li><a href='https://www.google.com/intl/es/chrome' target='_blank' alt={t('keys.literalNavegador')} title={t('keys.literalNavegador')} rel="noreferrer">Google Chrome<img src={newTab} alt=''/></a></li>
		                                     <li><a href='https://www.mozilla.org/es-ES/firefox/new' target='_blank' alt={t('keys.literalNavegador')} title={t('keys.literalNavegador')} rel="noreferrer">Mozilla Firefox<img src={newTab} alt=''/></a></li>
		                                     <li><a href='https://www.microsoft.com/es-es/edge' target='_blank' alt={t('keys.literalNavegador')} title={t('keys.literalNavegador')} rel="noreferrer">Microsoft Edge<img src={newTab} alt=''/></a></li>
		                                 </ul>
		                             </div>
		                         </div>
		                     </div>
		                 </div>
		             </div>
		         </React.Fragment>
		     )
	     } else {
	         return (<React.Fragment></React.Fragment>);
	     }
    }
}export default withTranslation()(AvisoIE);
