import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import liferayAPI from '../../apiLiferay';
import parse from 'html-react-parser';

import IconRifgt from '../../images/icons/icon_arrow_right_red.svg';
import IconRifgtHover from '../../images/icons/icono_arrow_red_circle_hover.svg';

class PreguntasFrecuentesComponent extends Component {
	 constructor(props) {
		    super(props);

		    this.state = {
		      version: "app_ayuda", 
		      contenidosLiferay:[],
		      locale: "eu_ES"
		    	  
		    };
		  }

	handleLanguage = (langValue) => {
		 this.setState({locale: langValue});
	}
	
	componentDidMount() {

		liferayAPI.getArticlesContenidoLiferay(this.props.i18n.language, process.env.REACT_APP_STRUCTURE_ID_MENU_DESPLEGABLE).then(response =>  
			this.setState({
				contenidosLiferay: response.items
		}));
		
	}

   	getFormattedListContenido(contenido){ 
   		let content;
 		const nestedContentFields = contenido.contentFields[0].nestedContentFields[1].nestedContentFields;
		
		for(let j=0; j <nestedContentFields.length; j++){
			if(nestedContentFields[j].name === 'contenido'){
				content = nestedContentFields[j].contentFieldValue;
			}
		}

		return content.data;
	}

	render() {
		const { t } = this.props; 
		let contenidoPreguntas;
		let operador;
		let stringOperador;
		if (this.state.contenidosLiferay !== undefined){
			  contenidoPreguntas = this.state.contenidosLiferay.map((contenido, indexC) => (
					  contenido.contentFields.map((contentFields, index) =>(
							 operador = contentFields.nestedContentFields[1].nestedContentFields[2].contentFieldValue.value,
							 stringOperador = operador.replaceAll("[\"", "").replaceAll("\"]", "").replaceAll("\"",""),
							 stringOperador.includes(this.props.operador) &&
								<li className="item" key={indexC}>
									<div className="item_head" id={"heading"+indexC}>		 
						        	<h3 className="subtitle">
							        	<button type="button" id={"button"+indexC} className="collapsed" data-toggle="collapse" data-target={"#preguntas"+ indexC} aria-expanded={this.state.open} aria-controls={"preguntas"+indexC} >
							        		<span className="text">{contentFields.nestedContentFields[0].contentFieldValue.data}</span>
							        		<span className="material-icons arrow" aria-hidden="true">expand_more</span>
									   	</button>	
								    </h3>	
									</div>
									<div id={"preguntas"+indexC} className="content_body collapse" aria-labelledby={"heading"+indexC} data-parent="#accordion" >
								      <div className="item_body">
									      {parse(contentFields.nestedContentFields[1].nestedContentFields[0].contentFieldValue.data)}
									      { 
 									    	  contentFields.nestedContentFields[1].nestedContentFields[1].nestedContentFields[0].contentFieldValue.data !== "" &&
										      <a className="link_more" href={contentFields.nestedContentFields[1].nestedContentFields[1].nestedContentFields[0].contentFieldValue.data} rel="noopener noreferrer" target={contentFields.nestedContentFields[1].nestedContentFields[1].nestedContentFields[1].nestedContentFields[0].contentFieldValue.data === "true"?"_blank":""}>
											        {t('keys.verMas')}
											        <img className="normal" src={IconRifgt} alt=""/>
							                        <img className="hover" src={IconRifgtHover} alt=""/>
						                      </a>
									      }
					                  </div> 
					                 </div>
				                 </li>
							))	
		      	));
		    }
		return(
			 <React.Fragment> 
					<h2 className="title">{t('keys.preguntasFrecuentes')}</h2>
					<ul id="accordion" className="section_collapse">
					    {contenidoPreguntas}
                    </ul>
                    {/*
                    <a className="link_more_static" href={this.props.i18n.language ==='eu'?"https://web.bizkaia.eus/eu/hasiera":"https://web.bizkaia.eus/es/inicio"} target="_blank"  rel="noreferrer">
                        {t('keys.masInfo')}
                        <img className="normal" src={IconRifgt} alt=""/>
                        <img className="hover" src={IconRifgtHover} alt=""/>
                    </a>*/}
                    
			</React.Fragment>
		);
					
	}
}
export default withTranslation()(PreguntasFrecuentesComponent);
