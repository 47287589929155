import React, { Component } from 'react';
import { Autocomplete } from '@react-google-maps/api';
import { withTranslation } from 'react-i18next';
 
import IconPlaniLotation from '../../images/icons/icon_location.svg'; 


class PlanificadorMiniComponent extends Component {
	
	constructor(props) {
	    super(props);
 
	    this.state = {
	        origen: "",
	        destino: "",
	        version: "app_planificadorMini",
		    locale: "eu_ES"
		}
		
		this.autocomplete = null

    	this.onLoad = this.onLoad.bind(this)
		this.onPlaceChanged = this.onPlaceChanged.bind(this)
		this.handleSubmit = this.handleSubmit.bind(this);
	}
	
	componentDidMount(){

		if(window.scrollY !== 0){
			 window.scrollTo( 0, 0 );
		}
		 
		let url = window.location.href;
		this.state.destino = decodeURIComponent(url.split('de=')[1]);

		if(document.getElementById('footer')!==null){
			document.getElementById('footer').classList.add('hidden'); 
		}
	}

	handleSubmit(event) {
	    console.log("entra en handle submit")
		event.preventDefault();
		window.open("/#/planificador_rutas/"+this.state.origen+"/"+this.state.destino, '_blank');
		//window.location.href = "/#/planificador_rutas/"+this.state.origen+"/"+this.state.destino;
	}

	onLoad(autocomplete) {
		console.log('autocomplete: ', autocomplete)
		this.autocomplete = autocomplete
	}

	onPlaceChanged() {
		if (this.autocomplete !== null) {
			console.log(this.autocomplete.getPlace())

			this.setState({
				origen: document.getElementById("origin").value
			});
		} else {
			console.log('Autocomplete is not loaded yet!')
		}
	}

	onOriginChange(value) {
		this.setState({
			origen: value,
		});
	}
	
	getCurrentPosition(){

	    console.log("entra aqui en getCurrentPosition")

		var pos;
        if (navigator.geolocation) { 
            navigator.geolocation.getCurrentPosition(position => {
                pos = {
                  lat: position.coords.latitude,
                  lng: position.coords.longitude
                };
                const google = window.google;
                var geocoder = new google.maps.Geocoder; 
                geocoder.geocode({'location': pos}, (results:any, status:any) => {
                    if (status === 'OK') {
                        if (results[0]) {
                            this.originPlace = results[0].formatted_address.replace(', España','');
                            this.originPlaceId = results[0].place_id;
                            this.currentDestinationId = results[0].place_id;
                            this.onOriginChange(results[0].formatted_address.replace(', España',''))
                            
                        } else {
                            console.log('No results found in rutas search header on getcurrentposition');
                        }    
                    } else {
                        console.log('Geocoder failed due to in rutas home : ' + status);
                    }
                });
            });
        }  
	}

  	render() {
  		const {t} = this.props;

		const bizkaiaRegion = {
			north: 43.459039,
			south: 42.972874,
			east: -2.425362,
			west: -3.449827
		}

		const country = {
			country: "es"
		}

		const options = {
			strictBounds: true
		}

		return(
			<React.Fragment>
                <div className="section_planificador_ruta share">
                    <div className="container">
                        <form className="container_planificador" onSubmit={this.handleSubmit}>
                            <div className="mb-3">
                                <Autocomplete onLoad={this.onLoad} onPlaceChanged={this.onPlaceChanged} bounds={bizkaiaRegion} options={options} restrictions={country} >
                                    <input type="text" id="origin" placeholder={t('keys.origen')} value={this.state.origen} onChange={e => this.onOriginChange(e.target.value)} required aria-label={t('keys.seleccioneOrigen')}/>
                                </Autocomplete>
                                <button type="button" className="button_location" onClick={() => this.getCurrentPosition()}>
                                    <img src={IconPlaniLotation} alt=""/>
                                    <span className="sr-only">Ubicación actual</span>
                                </button>
                            </div>
                            <input type="submit" className="button_buscar" value="Buscar ruta"/>
                        </form>
                    </div>
                </div>
			</React.Fragment>
		);
  }
}
export default withTranslation()(PlanificadorMiniComponent);
