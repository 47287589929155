import React, { Component } from 'react';
import { withTranslation } from 'react-i18next'; 

import IconClose from '../../images/icons/close.png';


class GuardarLineaComponent extends Component {	
	constructor(props) {
	    super(props);

	    this.state = {  
	      version: "app_guardarLinea",
	      view: "no_list",
	      classBlock:"",
	      showSelections: "none", 
	      locale: "eu_ES"
	    	  
		};

	}

	handleLanguage = (langValue) => {
		 this.setState({locale: langValue});
	}
	
	goConfiguration(){
		var dir = window.location.origin+"/"+"#";
		const pathname = window.location.href.substring(dir.length);
		window.dataLayer.push({
            'event': 'configurarAlerta',
            'eventLabel':pathname
        }); 
		console.log(window.dataLayer)
		window.location="#/configuracion"
		
	}
	
	render() {
		const {t} = this.props; 
		return(
			<React.Fragment>
			    {/* Modal linea guardada */}
                <div className="modal_bm modal fade" id="modalOpenLineaGuardada" tabIndex="-1" role="dialog" aria-labelledby="modalOpenLineaGuardada" aria-hidden="true">
                  <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">

                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                          <img src={IconClose} alt=""/>
                        </button>

                        <div className="body_modal">
                            <h2 className="title">{t('keys.lineasGuardadas')}</h2>
                            <p className="description">{t('keys.explicacionNotificacion')}</p>
                            <button type="button" className="button_modal" data-dismiss="modal" onClick={this.goConfiguration}>
                            	{t('keys.configuracionNotificaciones') }
                            </button>
                        </div>

                     </div>
                  </div>
                </div>	
			</React.Fragment>	
		);
	}
}export default  withTranslation()(GuardarLineaComponent);
