import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import liferayAPI from '../../apiLiferay';
import IconFav from '../../images/icons/favorit.png';
import IconTwitter from '../../images/icons/icon_twitter.svg';
import IconFacebook from '../../images/icons/icon_facebook.svg'

import IconContactoTime from '../../images/icons/icon_contacto_time.svg'
import IconContactoTel from '../../images/icons/icon_contacto_tel.svg'
import IconContactoMail from '../../images/icons/icon_contacto_mail.svg'

class ContactoGeneralComponent extends Component {
	 constructor(props) {
		    super(props);

		    this.state = {
		      version: "app_ayuda",
		      contenidosLiferay:[],
		      horario: IconFav,
		      telefono:IconFav,
	    	  correo:IconFav,
    		  facebook:IconFacebook,
			  twitter:IconTwitter,  
		      locale: "eu_ES"
		    	  
		    };
		  }

	handleLanguage = (langValue) => {
		 this.setState({locale: langValue});
	}
	
	componentDidMount() {

		liferayAPI.getArticlesContenidoLiferayByKey(this.props.i18n.language, process.env.REACT_APP_ARTICLE_ID_OPERADOR_CTB).then(response => 
			this.setState({contenidosLiferay: response})
		);
		 
	}
 
	render() {
		let contenidoContacto;
		const { t } = this.props;
		let contenidoL = [];
 		if (this.state.contenidosLiferay !== undefined && this.state.contenidosLiferay.length !==0){
			contenidoL.push(this.state.contenidosLiferay)
			contenidoContacto = contenidoL.map((contenido, index) => (
				<React.Fragment key={index}>
					<li className="item text" >
						<div className="icon">
			        		<img src={IconContactoTime} alt=""/>
				       	</div>	
 			        	{parse(contenido.contentFields[4].nestedContentFields[0].contentFieldValue.data)}
			        </li>
			        <li className="item">
			        	<div className="icon">
							<img src={IconContactoTel} alt=""/>
						</div>
						<p className="tel" id="Telefono"><a href={"tel:"+contenido.contentFields[4].nestedContentFields[1].nestedContentFields[0].contentFieldValue.data}>{contenido.contentFields[4].nestedContentFields[1].nestedContentFields[0].contentFieldValue.data}</a></p>
					</li>
					{
				    	contenido.contentFields[4].nestedContentFields[1].contentFieldValue.data !=="" &&
				    <li className="item">
						<div className="icon">
			            	<img src={IconContactoMail} alt=""/>
				        </div>
				    
	                    <p id="Email"><a href={"mailto:"+contenido.contentFields[4].nestedContentFields[1].nestedContentFields[1].contentFieldValue.data}>{contenido.contentFields[4].nestedContentFields[1].nestedContentFields[1].contentFieldValue.data}</a></p>
	                </li>
	                }
				    <li className="rrss">
				        {
				        	contenido.contentFields[4].nestedContentFields[1].nestedContentFields.map((rrss, indexR)=>(
				        		rrss.name === "rrssFieldSet" &&
				        		<a className="link" href={rrss.nestedContentFields[0].contentFieldValue.data} target="_blank" rel="noopener noreferrer" title={t('keys.enlaceOtraPagina')} key={indexR}>
		                           	<img src={rrss.nestedContentFields[0].contentFieldValue.data.includes("facebook")?this.state.facebook:this.state.twitter} alt=""/>
		                           	<span className="sr-only">{rrss.nestedContentFields[0].contentFieldValue.data.includes("facebook")?"Facebook":"Twitter"}</span>
		                        </a>
				        	))
				        }	                        
	                </li>
                </React.Fragment>
					
			));
		}
		
		return(
			 <React.Fragment>
                    <div className="container">
                        <h2 className="title">{t('keys.contacto')}</h2>
                        <ul className="list_contacta">
                           {contenidoContacto}
                        </ul>
                    </div>
			</React.Fragment>
		);
	}
}
export default withTranslation()(ContactoGeneralComponent);
