import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import liferayAPI from '../../apiLiferay';
import { Link } from 'react-router-dom';  

import parse from 'html-react-parser';

import Header from '../general/Header';
import Footer from '../general/Footer';
import AcessoRapido from '../general/AccesoRapidoComponent';

import IconRifgt from '../../images/icons/icon_arrow_right_red.svg';
import IconRifgtHover from '../../images/icons/icono_arrow_red_circle_hover.svg';
import ReactGA from 'react-ga4';
import CookiesUniversal from 'universal-cookie';
import $ from 'jquery';

class OficinasClienteInfoComponent extends Component {
	 constructor(props) {
		    super(props);

		    this.state = { 
		      contenidosLiferay:[],
		      numOficinas:0,
		      view: "no_list",
		      showSelections: "none",
		      locale: "eu_ES"
		    	  
		    };
		  }

	handleLanguage = (langValue) => {
		 this.setState({locale: langValue});
	}
	
	handlerModeView = (view) => {
	    this.setState({ view: view});
	}

	handlerSelectionToogle = () => {
	    if(this.state.showSelections === "none"){
	      this.setState({ showSelections: "block" });
	    }else{
	      this.setState({ showSelections: "none" });
	    }
	  }	
	
	componentDidMount() {
		const cookies = new CookiesUniversal();
 		if(cookies.get('cookies_analisis_bizkaitik_mugitu') === undefined){
			   $('#modalOpenCookies').modal('show') 
	    }
 		
 		this.cookies()
 		liferayAPI.getArticlesContenidoLiferayOrder(this.props.i18n.language, process.env.REACT_APP_STRUCTURE_ID_OFICINA_CLIENTE).then(response => this.setState({
       		contenidosLiferay: response.items,
       		numOficinas:response.items.length
 		}));  
	}
	
	cookies(){
		var {t} = this.props;
		   	
		var dir = window.location.origin+"/"+"#";
		const pathname = window.location.href.substring(dir.length); 	
		const cookies = new CookiesUniversal();
		var cookieAnalitica = cookies.get('cookies_analisis_bizkaitik_mugitu');
		 
		if(cookieAnalitica ==="true"){
 			ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.oficinas'), tracking: 'defaultTracker' });

		}else if(cookieAnalitica ==="false") {
 			ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.oficinas'), tracking: 'anonymousTracker' });

		}
	}
	
	getFormattedListNombreOficina(contenido){
		let content; 
		
		const nestedContentFields = contenido.contentFields[0].nestedContentFields[1].nestedContentFields;
		for(let j=0; j <nestedContentFields.length; j++){
			 if(nestedContentFields[j].name==="nombreOficina"){
				content = nestedContentFields[j].contentFieldValue; 
			 }
		}
		return content.data;
	}
	
	getFormattedListHorario(contenido){
		let content; 
		const nestedContentFields = contenido.contentFields[0].nestedContentFields[1].nestedContentFields;
		for(let j=0; j <nestedContentFields.length; j++){
			 if(nestedContentFields[j].name==="horario"){
				content = nestedContentFields[j].contentFieldValue;
			 }
		}
		return content.data;
	}
	
	getFormattedListLocalizacion(contenido){
		let content; 
		const nestedContentFields = contenido.contentFields[0].nestedContentFields[1].nestedContentFields;
		for(let j=0; j <nestedContentFields.length; j++){
			 if(nestedContentFields[j].name==="lugar"){
				content = nestedContentFields[j].contentFieldValue;
			 }
		}
		return content.data;
	}
	
	getFormattedListDireccion(contenido){
	
		let content; 
		const nestedContentFields = contenido.contentFields[0].nestedContentFields[1].nestedContentFields;
		for(let j=0; j <nestedContentFields.length; j++){
			 if(nestedContentFields[j].name==="direccionMapa"){
				content = nestedContentFields[j].contentFieldValue;
			 }
		}
		return content.data;
	}
	
	getFormattedListOperador(contenido){
		const regex = /[^\w,\s]/gi;

		let content; 
		const nestedContentFields = contenido.contentFields[0].nestedContentFields[1].nestedContentFields;
		for(let j=0; j <nestedContentFields.length; j++){
			 if(nestedContentFields[j].name==="keyOperador"){
				var operadores = nestedContentFields[j].contentFieldValue.data.replace(regex, "")
  
			 }
		}
		
		return operadores.replace(",",", ");
	}
	
	numOficinas(contenidoOficinas){
		var totalOficinas = 0;
		for(let j=0; j <contenidoOficinas.length; j++){
			if(contenidoOficinas[j] !== false){
				totalOficinas = totalOficinas+1;
			}
		}
		return totalOficinas;
	}
	
	render() {
		const { t } = this.props;
		let contenidoOficinas;
		let id = "";
 
		if(this.props.match.params.operador !== undefined){
			id = this.props.match.params.operador;
		}
 
		if (this.state.contenidosLiferay !== undefined && id === ""){
			 contenidoOficinas = this.state.contenidosLiferay.map((contenido, index) =>( 	
					<li className="item" key={index}>
				    <div className="item_head" id={"heading"+index}>
				        <h3 className="subtitle">
				            <button type="button" id={"button"+index} className="collapsed" data-toggle="collapse" data-target={"#oficinas"+ index} aria-expanded={this.state.open} aria-controls={"oficinas"+index} >
                                <span className="text">
                                    <strong>{contenido.contentFields[0].nestedContentFields[0].contentFieldValue.data} ({this.getFormattedListOperador(contenido)})</strong>
                                    <br/>
                                    {this.getFormattedListNombreOficina(contenido)}
                                </span>
                                <span className="material-icons arrow" aria-hidden="true">expand_more</span>
                            </button>
                        </h3>
                    </div>

				    <div id={"oficinas"+index} className="collapse content_body" aria-labelledby={"heading"+index} data-parent="#accordion" >
				      	<div className="item_body">
                            <p>{t('keys.horarios')}</p>
                            {parse(this.getFormattedListHorario(contenido))}
				    		<p>{this.getFormattedListLocalizacion(contenido)}</p>
				    		<Link className="link_more" to={"/planificador_rutas/%20/"+this.getFormattedListDireccion(contenido)}>
				    		    {t('keys.comoLlegar')}
				    		    <img className="normal" src={IconRifgt} alt=""/>
                                <img className="hover" src={IconRifgtHover} alt=""/>
				    		</Link>
			    		</div>
				    </div>
				</li> 
		      ));
		    }else if(this.state.contenidosLiferay !== undefined && id !==""){
		    	contenidoOficinas = this.state.contenidosLiferay.map((contenido, index) =>(
		   
		    			id === this.getFormattedListOperador(contenido) &&
						<li className="item" key={index}>
						    <div className="item_head" id={"heading"+index}>
						        <h3 className="subtitle">
						            <button type="button" id={"button"+index} className="collapsed" data-toggle="collapse" data-target={"#operador"+ index} aria-expanded={this.state.open} aria-controls={"operador"+index} >
		                                <span className="text">
		                                    <strong>{contenido.contentFields[0].nestedContentFields[0].contentFieldValue.data}</strong>
		                                    <br/>
		                                    {this.getFormattedListNombreOficina(contenido)}
		                                </span>
		                                <span className="material-icons arrow" aria-hidden="true">expand_more</span>
		                            </button>
		                        </h3>
		                    </div>
						    <div id={"operador"+index} className="collapse content_body" aria-labelledby={"heading"+index} data-parent="#accordion" >
						      	<div className="item_body">
		                            <p>{t('keys.horarios')}</p>
		                            {parse(this.getFormattedListHorario(contenido))}
						    		<p>{this.getFormattedListLocalizacion(contenido)}</p>
						    		<Link className="link_more" to={"/planificador_rutas/%20/"+this.getFormattedListDireccion(contenido)}>
						    		    {t('keys.comoLlegar')}
						    		    <img className="normal" src={IconRifgt} alt=""/>
		                                <img className="hover" src={IconRifgtHover} alt=""/>
						    		</Link>
					    		</div>
						    </div>
						</li> 
			      ));
		    }

		    
		return(
			 <React.Fragment> 
				<Header handleToUpdate = {this.handlerModeView} action={this.handlerSelectionToogle} title={t('keys.oficinas')} onSelectLanguage={this.handleLanguage}/>
				<div className="section_list_offices" id="main-content">
				    <h1 className="sr-only">{t('keys.oficinas')}</h1>
				    <div className="container" >
                        <h2 className="title">{this.numOficinas(contenidoOficinas) +" "+ t('keys.oficinas')}</h2>
                        <ul id="accordion" className="section_collapse">
                            {contenidoOficinas}
                        </ul>
                        {
                        	this.numOficinas(contenidoOficinas) === 0 && this.props.location.state !== undefined &&
                        	<p className="text_description">No hay registro de oficinas para el operador <strong>{this.props.location.state.operador}</strong></p>
                        }
                    </div>
                </div>
                <AcessoRapido/>
                <Footer />
			</React.Fragment>
		);
	}
}
export default withTranslation()(OficinasClienteInfoComponent);
