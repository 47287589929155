import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';

import liferayAPI from '../../apiLiferay';

import IconTodos from '../../images/icons/icon_place_all.svg';
import IconTodosRed from '../../images/icons/icon_place_all_red.svg';
import IconCiudad from '../../images/icons/icon_place_city.svg';
import IconCiudadRed from '../../images/icons/icon_place_city_red.svg';
import IconCosta from '../../images/icons/icon_place_costa.svg';
import IconCostaRed from '../../images/icons/icon_place_costa_red.svg';
import IconMontana from '../../images/icons/icon_place_montain.svg';
import IconMontanaRed from '../../images/icons/icon_place_montain_red.svg';
import IconArrowCircleRed from '../../images/icons/icono_arrow_red_circle.svg';

class DescubreBizkaiaInfoComponent extends Component {
	constructor(props) {
		super(props);
		this.state = {
			version: "descubreBizkaia",
		   	lugaresInteres:[],
		   	locale: "eu_ES"    	  
		};
	}

	handleLanguage = (langValue) => {
		this.setState({locale: langValue});
	}

   	componentDidMount() {
        this.filter(this.state.categoryId);
	}
   	
   	filter(categoryId){

		liferayAPI.getArticlesLugarInteres(this.props.i18n.language, categoryId).then(response => this.setState({
        	lugaresInteres: response.items
        }));
		
		this.onActive(categoryId);
		
   	}
   	
   	onActive(categoryId){
   		var catId =""
   		if(categoryId === undefined){
   			catId = "0";
   		}else{
   			catId = categoryId;
   		}
   		
   		switch (catId) { 
	        case process.env.REACT_APP_CATEGORY_ID_COSTA:
	        	document.getElementById("c"+process.env.REACT_APP_CATEGORY_ID_COSTA).classList.add('active');
	        	document.getElementById("c"+process.env.REACT_APP_CATEGORY_ID_MONTANA).classList.remove('active');
	        	document.getElementById("c"+process.env.REACT_APP_CATEGORY_ID_BILBAO).classList.remove('active');
	        	document.getElementById("todos").classList.remove('active');
	            break;
	        case process.env.REACT_APP_CATEGORY_ID_MONTANA:
	        	document.getElementById("c"+process.env.REACT_APP_CATEGORY_ID_MONTANA).classList.add('active');
	        	document.getElementById("c"+process.env.REACT_APP_CATEGORY_ID_COSTA).classList.remove('active');
	        	document.getElementById("c"+process.env.REACT_APP_CATEGORY_ID_BILBAO).classList.remove('active');
	        	document.getElementById("todos").classList.remove('active'); 
	            break;
	        case process.env.REACT_APP_CATEGORY_ID_BILBAO:
	        	document.getElementById("c"+process.env.REACT_APP_CATEGORY_ID_BILBAO).classList.add('active');
	        	document.getElementById("c"+process.env.REACT_APP_CATEGORY_ID_MONTANA).classList.remove('active');
	        	document.getElementById("c"+process.env.REACT_APP_CATEGORY_ID_COSTA).classList.remove('active');
	        	document.getElementById("todos").classList.remove('active'); 		
	            break;   	 
	        default:
	        	document.getElementById("todos").classList.add('active');   	

	        	document.getElementById("c"+process.env.REACT_APP_CATEGORY_ID_BILBAO).classList.remove('active');
	        	document.getElementById("c"+process.env.REACT_APP_CATEGORY_ID_MONTANA).classList.remove('active');
	        	document.getElementById("c"+process.env.REACT_APP_CATEGORY_ID_COSTA).classList.remove('active');
	            break;
   		}

   	}
 
    
   render() {

		  const { t } = this.props; 
		  let contenidoLugaresInteres; 
		  
		  if (this.state.lugaresInteres !== undefined){
			  contenidoLugaresInteres = this.state.lugaresInteres.map((contenido, index) => (
					 <li className="item" key={index}>
						 <Link className="link_more" to={"/planificador_rutas/undefined/"+contenido.contentFields[2].contentFieldValue.data} rel="noreferrer">
						    <p className="categoria">{contenido.contentFields[1].contentFieldValue.data}</p>
						    <h3 className="title">{contenido.contentFields[0].contentFieldValue.data}</h3>
						    <img className="arrow_link" src={IconArrowCircleRed} alt=""/>
						 </Link> 
					 </li>
		      	));
		  } 
		
		return(

				  <React.Fragment>
                    <div className="places_interest">
                        <div className="container">
                            <h2 className="title">{parse(t('keys.lugaresInteres'))}</h2>
                            <h3 className="subtitle">{t('keys.utilizaNuestro')}</h3>
                            <ul className="list_items">
                                <li>
                                    <Link className="link active" to="/descubre_bizkaia" onClick={(text) => this.filter(0)} id="todos">
                                        <span className="image">
                                            <img className="normal" src={IconTodos} alt=""/>
                                            <img className="hover" src={IconTodosRed} alt=""/>
                                        </span>
                                        <span className="text">{t('keys.todos')}</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link className="link" to="/descubre_bizkaia" onClick={(text) => this.filter(process.env.REACT_APP_CATEGORY_ID_COSTA)} id={"c"+process.env.REACT_APP_CATEGORY_ID_COSTA}>
                                        <span className="image">
                                            <img className="normal" src={IconCosta} alt=""/>
                                            <img className="hover" src={IconCostaRed} alt=""/>
                                        </span>
                                        <span className="text">{t('keys.costa')}</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link className="link" to="/descubre_bizkaia" onClick={(text) => this.filter(process.env.REACT_APP_CATEGORY_ID_MONTANA)} id={"c"+process.env.REACT_APP_CATEGORY_ID_MONTANA}>
                                        <span className="image">
                                            <img className="normal" src={IconMontana} alt=""/>
                                            <img className="hover" src={IconMontanaRed} alt=""/>
                                        </span>
                                        <span className="text">{t('keys.montana')}</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link className="link" to="/descubre_bizkaia" onClick={(text) => this.filter(process.env.REACT_APP_CATEGORY_ID_BILBAO)} id={"c"+process.env.REACT_APP_CATEGORY_ID_BILBAO}>
                                        <span className="image">
                                            <img className="normal" src={IconCiudad} alt=""/>
                                            <img className="hover" src={IconCiudadRed} alt=""/>
                                        </span>
                                        <span className="text">{t('keys.ciudad')}</span>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="places_interest_content">
                        <div className="container">
                            <ul className="list_content">
                            	{contenidoLugaresInteres}
                            </ul>
                        </div>
                    </div>
				   </React.Fragment>
		  );
	  }
}
export default withTranslation()(DescubreBizkaiaInfoComponent);
