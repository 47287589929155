import React, { Component } from 'react';  
import {HashRouter, Route, Switch} from 'react-router-dom';
import { LoadScript } from '@react-google-maps/api';
import { withTranslation } from 'react-i18next';
import CookiesUniversal from 'universal-cookie';
import pushWooshAPI from './apiPushWoosh';
import $ from 'jquery';
import 'bootstrap';
import './css/App.css';
import 'bootstrap/dist/css/bootstrap.css';

import Home from './components/home/HomeComponent';
import AvisoIE from './components/avisoIE/AvisoIE';
import PlanificadorRuta from './components/planificador/PlanificadorComponent';
import PlanificadorDetalle from './components/planificador/PlanificadorDetalleComponent';
import PlanificadorMini from './components/desarrolladores/PlanificadorMiniComponent';
import LineasHorarios from './components/lineas/LineasInfoComponent';
import LineasDetalle from './components/lineas/LineasInfoDetalleComponent';
import ParadasDetalle from './components/lineas/ParadasDetalleComponent';
import TitulosTarifas from './components/titulos/TitulosTarifasComponent';
import Alertas from './components/alertas/AlertasComponent';
import AlertasDetalle from './components/alertas/AlertasDetalleComponent';
import DescubreBizkaia from './components/descubre/DescubreBizkaiaInfoComponent';
import Desarrolladores from './components/desarrolladores/MugituDesarrolladoresComponent';
import Ayuda from './components/ayuda/AyudaComponent';
import Mapas from './components/mapas/MapasComponent';
import Favoritos from './components/favoritos/FavoritosHomeComponent';
import Oficinas from './components/ayuda/OficinasClienteInfoComponent';
import Configuracion from './components/alertas/ConfiguracionComponent';
import Accesibilidad from './components/footer/AccesibilidadComponent';
import AyudaContacto from './components/footer/AyudaContactoComponent';
import AvisoLegal from './components/footer/AvisoLegalComponent';
import Cookies from './components/footer/CookiesComponent';
import CookiesModal from './components/general/CookiesComponent';
import Gtm from './Gtm';  
import ReactGA from 'react-ga4';
import i18n from 'i18next';

class App extends Component {

	constructor(props) {
        super(props); 
        this.state = {
        	cookie: false,
            width: window.innerWidth,
            locale: "eu_ES",
            loading: true,
            showLoader: false,
            version: "BizkaitikMugitu"
        };   
    }
	
	handleLanguage = (langValue) => {
		 this.setState({locale: langValue});
	}
	
    componentDidMount(){ 

    	const pathname = window.location.href;
		const cookies = new CookiesUniversal();
		var cookieAnalitica = cookies.get('cookies_analisis_bizkaitik_mugitu');
 		
		if(cookies.get('cookies_analisis_bizkaitik_mugitu') == "true"){
			ReactGA.initialize(process.env.REACT_APP_GA_ID, {gaOptions: {name: 'defaultTracker'}});
			ReactGA.set({ anonymizeIP: true }, ['defaultTracker']);
		}else{
			ReactGA.initialize(process.env.REACT_APP_GA_ANONYMOUS, {gaOptions: {name: 'anonymousTracker', storage:'none', clientId: localStorage.getItem('varSesionLiferayGTMncd')}});
			ReactGA.set({ anonymizeIP: true }, ['anonymousTracker']);
		}

        if(window.location.href.includes('planificador_mini')){
			console.log("TRUE")
    	}else{
    		console.log("FALSE")
    	}
       
    	pushWooshAPI.initPushWoosh();

    	if(cookieAnalitica === undefined){
		   $('#modalOpenCookies').modal('show') 
    	}
    } 
   
 	render() { 
 
 		var valor = "eu";
 		console.log("Valor inicial: "+valor)
 		if(i18n.language!==null && i18n.language!== undefined){
 			valor = i18n.language; 
 		} 

        return(
            <div className="App">
                <AvisoIE/>
                <HashRouter>
                    {
                        i18n.language!==null && i18n.language!== undefined &&
                        <LoadScript
                            id="script-loader"
                            googleMapsApiKey={process.env.REACT_APP_TOKEN}
                            libraries={["places"]}
                            language={console.log("Valor loadScript: "+valor),valor}
                            region="ES"
                        >

                            <Switch>
                                <Route path="/" exact component={Home} />
                                <Route path="/titulos" exact component={TitulosTarifas}/>
                                <Route path="/planificador_rutas/:origen?/:destino?/:dt?/:at?/:travelMode?/:routePreference?" exact component={PlanificadorRuta}/>
                                <Route path="/planificador_detalles/:dI/:or/:de/:dt?/:at?/:travelMode?/:routePreference?" exact component={PlanificadorDetalle}/>
                                <Route path="/planificador_mini/:origen?" exact component={PlanificadorMini}/>
                                <Route path="/lineas_horarios/:linea?" exact component={LineasHorarios} />
                                <Route path="/linea_detalle/:operador?/:linea?/:ruta?/:idRoute?/:routeType?/:tab?" exact component={LineasDetalle} />
                                <Route path="/parada_detalle/:operador?/:linea?/:idRoute?/:ruta?/:routeType?/:parada?/:stopId?/:directionId?/:stopLat?/:stopLng?/:headsign?/:twoWay?" exact component={ParadasDetalle} />
                                <Route path="/alertas" exact component={Alertas} />
                                <Route path="/alertas_detalle/:articleId" exact component={AlertasDetalle} />
                                <Route path="/configuracion" exact component={Configuracion} />
                                <Route path="/descubre_bizkaia" exact component={DescubreBizkaia} />
                                <Route path="/descubre_bizkaia/:category" exact component={DescubreBizkaia} />
                                <Route path="/desarrolladores" exact component={Desarrolladores}/>
                                <Route path="/ayuda" exact component={Ayuda} />
                                <Route path="/mis_lineas" exact component={Favoritos} />
                                <Route path="/mapas" exact component={Mapas} />
                                <Route path="/oficinas_info/:operador?" exact component={Oficinas} />
                                <Route path="/accesibilidad" exact component={Accesibilidad} />
                                <Route path="/ayuda_contacto" exact component={AyudaContacto} />
                                <Route path="/aviso_legal" exact component={AvisoLegal} />
                                <Route path="/cookies" exact component={Cookies} />
                            </Switch>
                        </LoadScript>
                    }
                 </HashRouter>
                 <CookiesModal/>
                 <Gtm/>
            </div>
        );
	}

} export default withTranslation()(App);