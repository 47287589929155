const groupId: string | undefined = process.env.REACT_APP_GROUP_ID;
const token: string | undefined = process.env.REACT_APP_LIFERAY_TOKEN;
const baseUrl: string | undefined = process.env.REACT_APP_BASEURL;

class apiLiferay {

	base64 = require('base-64');

	getArticlesContenidoLiferayByKey(locale : Locale, articleId: string){  
	   	return fetch(baseUrl + "sites/"+groupId+"/structured-contents/by-key/"+articleId,
			   	{
			   		headers: this.getHeaders(locale)
			   	})
		   	.then((response) => response.json())
		   
	}

	getArticlesContenidoAlertasLiferay(locale : Locale, structureId: string){ 
	   	return fetch(baseUrl + "sites/"+groupId+"/structured-contents/?flatten=true&filter=contentStructureId%20eq%20"+structureId+"&sort=datePublished:desc",
		   	{
		   		headers: this.getHeaders(locale)
		   	})
	   	.then((response) => response.json())
	} 
	
	getAlertasHome(locale : Locale, structureId: string, tipoAviso: string, startDate: string){ 
	   	return fetch(baseUrl + "content-structures/"+structureId+"/structured-contents/?flatten=true&filter=contains(contentFields/tipoAviso,'"+tipoAviso+"')%20and%20datePublished%20gt%20"+startDate+"&sort=datePublished:desc",
		   	{
		   		headers: this.getHeaders(locale)
		   	})
	   	.then((response) => response.json())
	} 
	
	checkAlerta(locale : Locale, structureId: string, line: string){ 
 	   	return fetch(baseUrl + "content-structures/"+structureId+"/structured-contents/?filter=contains(contentFields/line,'"+line+"')",
		   	{
		   		headers: this.getHeaders(locale)
		   	})
	   	.then((response) => response.json())
	} 
	
	getArticlesContenidoAlertasDetallesLiferay(locale : Locale, articleId: string){ 
	   	return fetch(baseUrl + "sites/"+groupId+"/structured-contents/by-key/"+articleId,
		   	{
		   		headers: this.getHeaders(locale)
		   	})
	   	.then((response) => response.json())
	}
	
	getArticlesContenidoLiferay(locale : Locale, structureId: string){ 
	   	return fetch(baseUrl + "sites/"+groupId+"/structured-contents/?flatten=true&filter=contentStructureId%20eq%20"+structureId,
		   	{
		   		headers: this.getHeaders(locale)
		   	})
	   	.then((response) => response.json())
	} 
	
	getArticlesContenidoLiferayOrder(locale : Locale, structureId: string){ 
	   	return fetch(baseUrl + "sites/"+groupId+"/structured-contents/?flatten=true&filter=contentStructureId%20eq%20"+structureId+"&sort=title:asc",
		   	{
		   		headers: this.getHeaders(locale)
		   	})
	   	.then((response) => response.json())
	} 
	
    getArticlesLugarInteres(locale : Locale, categoryId: int){
		if(categoryId!== undefined && categoryId!==0){
			return fetch(baseUrl+"/sites/"+groupId+"/structured-contents?flatten=true&filter=contentStructureId%20eq%20"+process.env.REACT_APP_STRUCTURE_ID_LUGARES_INTERES+"%20and%20taxonomyCategoryIds%2Fany%28t%3At%20eq%20"+categoryId+"%29",
					{
				headers: this.getHeaders(locale)
				   	})
			   	.then((response) => response.json())
		}else{
			return fetch(baseUrl + "sites/"+groupId+"/structured-contents/?flatten=true&filter=contentStructureId%20eq%20"+process.env.REACT_APP_STRUCTURE_ID_LUGARES_INTERES,
		   			{
				headers: this.getHeaders(locale)
			   	})
		   	.then((response) => response.json())
		}  	
	   	
	} 
   
	getHeaders(locale : Locale){
		const basicAuth = 'Basic ' + token;
		let headers = [];
		headers = {
				"Authorization": basicAuth,
				"Accept-Language": locale
		}
		return headers; 
	}
 
}

const liferayAPI = new apiLiferay();
export default liferayAPI;
