import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import liferayAPI from '../../apiLiferay';
import parse from 'html-react-parser';

import TitulosTransportes from '../home/TitulosTransportesComponent';
import Header from '../general/Header';
import Footer from '../general/Footer';
import ReactGA from 'react-ga4';
import CookiesUniversal from 'universal-cookie';
import $ from 'jquery';

class TitulosTarifasComponent extends Component {

	constructor(props) {
		super(props);
 
	    this.state = {  
	    		contenidosLiferay: [],
	    		version: "app_titulosTarifas",
	    		view: "no_list",
	    		showSelections: "none",
	    		linea:"",
	    		lineas: [],
	    		locale: "eu_ES"
	    };
	    this.handleSubmit = this.handleSubmit.bind(this);
	}
    
	handleLanguage = (langValue) => {
		this.setState({locale: langValue});
	}
	
	handlerModeView = (view) => {
		this.setState({ view: view});
	}

	handlerSelectionToogle = () => {
	    if(this.state.showSelections === "none"){
	      this.setState({ showSelections: "block" });
	    }else{
	      this.setState({ showSelections: "none" });
	    }
	}	
	
	componentDidMount(){
		
		const cookies = new CookiesUniversal();
 		if(cookies.get('cookies_analisis_bizkaitik_mugitu') === undefined){
			   $('#modalOpenCookies').modal('show') 
	    } 
		this.cookies()
		if(window.scrollY !== 0){
			 window.scrollTo( 0, 0 );
		}
		
		if(document.getElementById('planificador')!==null && document.getElementById('lineas')!==null){
			document.getElementById('planificador').classList.remove('active');
			document.getElementById('lineas').classList.remove('active');  
		}
		 
		liferayAPI.getArticlesContenidoLiferayByKey(this.props.i18n.language, process.env.REACT_APP_ARTICLE_ID_TITULOSTARIFAS).then(response =>  
			this.setState({contenidosLiferay: response})
		);
	}
	
	cookies(){
		var {t} = this.props;
	   	
		var dir = window.location.origin+"/"+"#";
		const pathname = window.location.href.substring(dir.length); 	
		const cookies = new CookiesUniversal();
		var cookieAnalitica = cookies.get('cookies_analisis_bizkaitik_mugitu');
	    
		if(cookieAnalitica ==="true"){
//			ReactGA.pageview(pathname, ['defaultTracker'], t('keys.menuTitulos')); 
			ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.menuTitulos'), tracking: 'defaultTracker' });
		 }else if(cookieAnalitica ==="false") {
//			ReactGA.pageview(pathname, ['anonymousTracker'], t('keys.menuTitulos'));
			ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.menuTitulos'), tracking: 'anonymousTracker' });

		 }
	}	
	
	handleSubmit(event){
		event.preventDefault(); 
		window.location.href = "/#/lineas_horarios/"+this.state.linea;
	}
	
	onLineaChange(value) {
		this.setState({
			linea: value,
		});
	}
	
	render() {
		const {t} = this.props;
	 
		return(
	    		<React.Fragment>
		    		<Header handleToUpdate = {this.handlerModeView} action={this.handlerSelectionToogle} title={t('keys.menuTitulos')} cabecera="true" onSelectLanguage={this.handleLanguage}/>
		    			
			    		<div className={this.state.version + " content_body"} id="main-content">
			                <div className="cabecera_titulos_tarifas">
			                    <div className="background"></div>
			                    <h1 className="title">{t('keys.menuTitulos')}</h1>
			                </div>
			
			                <div className="section_titulos_tarifas">
			                    <div className="container">
			                    {
			                    	this.state.contenidosLiferay !== undefined && this.state.contenidosLiferay.length !==0 &&
									parse(this.state.contenidosLiferay.contentFields[0].contentFieldValue.data)
								}
			                        <form className="select_linea" onSubmit={this.handleSubmit} action="/lineas_horarios">
			                        	<input type="text" id="line" placeholder={t('keys.introducirLinea')} className="pac-target-input" value={this.state.linea} onChange={e => this.onLineaChange(e.target.value)} aria-label={t('keys.seleccioneLinea')}/>
			                        	<button type="submit" className="button_buscar" id="line_buscar">{t('keys.buscarLinea')}</button>
			                        </form>
			
			                        <TitulosTransportes/>
			                    </div>
			                </div>
			
			    		</div>
		    		<Footer />
	    		</React.Fragment>
	    );
	  }
}export default  withTranslation()(TitulosTarifasComponent);