import React,  { Component } from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
 
import IconPlanificador from'../../images/icons/icon_mobile_planificador.svg';
import IconLinea from'../../images/icons/icon_mobile_horarios.svg';


import '../../css/App.css';

class AccesoRapidoComponent extends Component{
	constructor(props) {
		super(props);
		this.state = {
			width: window.innerWidth,
			planificador: IconPlanificador,
		    linea: IconLinea,
		    activePlanificador: "icono",
		    activeLineas: "icono",
		    version: "app_accesoRapido",
		    locale: "eu_ES"
		};
	}
	
	componentWillMount() {
		window.addEventListener('resize', this.handleWindowSizeChange);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleWindowSizeChange);
	}

	handleWindowSizeChange = () => {
		this.setState({ width: window.innerWidth });
	};

	componentDidMount(){
		
		
	}

	render() { 
		
		const {t} = this.props;
		const { width } = this.state;
		const breakpoint = 992;
		const isMobile = width <= breakpoint;

		if (isMobile) {
	      return(
	        <div className={this.state.version} id="accesoRapido">
		        <Link to="/planificador_rutas" className="btn_planificador" onClick={() =>{document.getElementById('planificador').classList.add('active');document.getElementById('lineas').classList.remove('active')}} id="planificador">
		              <div className="icono">
		                <img src={this.state.planificador} alt=""/>
		              </div>
		              <p className="icon-tag">{t('keys.menuPlanificador')}</p>
		        </Link>
	          	<Link to="/lineas_horarios" className="btn_lineas" onClick={() => {document.getElementById('lineas').classList.add('active');document.getElementById('planificador').classList.remove('active')}} id="lineas">
		            <div className="icono" >
		                <img src={this.state.linea} alt=""/>
		            </div>
		            <p className="icon-tag">{t('keys.menuLineas')}</p>
	            </Link>
	        </div>
	      );

	    } else {
	      return(
	        <div>
	        </div>
	      );
	    }
	}	
}

export default withTranslation()(AccesoRapidoComponent);
