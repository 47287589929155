import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import liferayAPI from '../../apiLiferay';

import Header from '../general/Header';
import Footer from '../general/Footer';
import AcessoRapido from '../general/AccesoRapidoComponent';

import PreguntasFrecuentes from './PreguntasFrecuentesComponent';
import ContactoGeneral from './ContactoGeneralComponent';
import OficinasCliente from './OficinasClienteComponent';
import ReactGA from 'react-ga4';
import CookiesUniversal from 'universal-cookie';
import $ from 'jquery';

class AyudaComponent extends Component {
	 constructor(props) {
		    super(props);
 
	        this.state = {
		      version: "app_ayuda",
		      view: "no_list",
		      showSelections: "none",
		      contenidosLiferay: [],
		      locale: "eu_ES"
		    	  
		    };
		  }

	handleLanguage = (langValue) => {
		 this.setState({locale: langValue});
	}
	
	handlerModeView = (view) => {
	    this.setState({ view: view});
	}

	handlerSelectionToogle = () => {
	    if(this.state.showSelections === "none"){
	      this.setState({ showSelections: "block" });
	    }else{
	      this.setState({ showSelections: "none" });
	    }
	  }	
	
	 componentDidMount(){
		const cookies = new CookiesUniversal();
	 	if(cookies.get('cookies_analisis_bizkaitik_mugitu') === undefined){
				   $('#modalOpenCookies').modal('show') 
		}
		this.cookies()
		if(window.scrollY !== 0){
			 window.scrollTo( 0, 0 );
		}
		 
		if(document.getElementById('planificador')!==null && document.getElementById('lineas')!==null){
			 document.getElementById('planificador').classList.remove('active');document.getElementById('lineas').classList.remove('active');  
		}	
	
		liferayAPI.getArticlesContenidoLiferayByKey(this.props.i18n.language, process.env.REACT_APP_ARTICLE_ID_BM).then(response => 
			this.setState({contenidosLiferay: response})
		);
	 }
	 
	 cookies(){
		var {t} = this.props;
		   	
		var dir = window.location.origin+"/"+"#";
		const pathname = window.location.href.substring(dir.length); 			 
		 
		const cookies = new CookiesUniversal();
		var cookieAnalitica = cookies.get('cookies_analisis_bizkaitik_mugitu');
		
		if(cookieAnalitica ==="true"){
				ReactGA.set({ anonymizeIP: true }, ['defaultTracker']);
 				ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.menuContacto'), tracking: 'defaultTracker' });

		}else if(cookieAnalitica ==="false") {
				ReactGA.set({ anonymizeIP: true }, ['anonymousTracker']);
 				ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.menuContacto'), tracking: 'anonymousTracker' });

		}
	}
		
	render() {
		const { t } = this.props;
		let contenidoBizkaitikMugitu = [];
		let contenidoBM;
		
		if (this.state.contenidosLiferay !== undefined && this.state.contenidosLiferay.length !==0){
			contenidoBizkaitikMugitu.push(this.state.contenidosLiferay)

			  contenidoBM = contenidoBizkaitikMugitu.map((contenido, index) =>(
					  <p className="text_description" key={index}>{parse(contenido.contentFields[0].contentFieldValue.data)}</p>
			  ));
		}
		
		return(
			 <React.Fragment>
				<Header handleToUpdate = {this.handlerModeView} action={this.handlerSelectionToogle} title={t('keys.menuContacto')}  cabecera="true" onSelectLanguage={this.handleLanguage}/>
			    	<div className={this.state.version+ " cabecera_contacto"} id="main-content">
			    	    <div className="background"></div>
						<h1 className="title">{t('keys.menuContacto')}</h1>
                    </div>

                    <div className="section_contacto">
                        <div className="container_desktop">
                            <div className="container">
                                <h2 className="title">{parse(t('keys.quienEsBM'))}</h2>
                                {contenidoBM}
                                <div className="section_preguntas_frecuentes">
                                	<PreguntasFrecuentes operador={"Ctb"}/>
                                </div>
                            </div>
                            <div className="section_contacta page">
                                <ContactoGeneral/>
                            </div>
                            <div className="container section_offices">
                                <OficinasCliente/>
                            </div>
                        </div>
					</div>
					<AcessoRapido/>
					<Footer />
			</React.Fragment>
		);
	}
}
export default withTranslation()(AyudaComponent);
