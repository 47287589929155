import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { GoogleMap, Marker, InfoWindow } from '@react-google-maps/api';

import { Link } from 'react-router-dom';

import Header from '../general/Header'; 
import Footer from '../general/Footer';

import apiLineas from '../../apiLineas';
import IconRifgt from '../../images/icons/icon_arrow_right_red.svg';
import IconRifgtHover from '../../images/icons/icono_arrow_red_circle_hover.svg';
import IconClose from '../../images/icons/close.png';
import CompartirLinea from './CompartirLineaComponent';
import GuardarLineas from '../lineas/GuardarLineaComponent';
import ImageMapaEjemplo from '../../images/STPUZB.png';
import pushWooshAPI from '../../apiPushWoosh';
 
import ReactGA from 'react-ga4';
import CookiesUniversal from 'universal-cookie';
import $ from 'jquery';

class ParadasDetalleComponent extends Component {
	constructor(props) {
	    super(props);
 
	    this.state = {  
	      valor:true,
	      contenidosLiferay:[],
	      datosHorarios:[],
	      version:"parada_detalle",
	      locale: "eu_ES",
	      directionId: this.props.match.params.directionId,
	      otherLines:[],
	      startDate:'',
	      startHour:'',
	      showHeadsign: this.props.match.params.headsign,
	   	  twoWay: this.props.match.params.twoWay
	    };
	    
	    this.setIda = this.setIda.bind(this);
	    this.setVuelta = this.setVuelta.bind(this);
	    this.searchHorario = this.searchHorario.bind(this);

	  }
	  
	componentDidMount(){
		const cookies = new CookiesUniversal();
 		if(cookies.get('cookies_analisis_bizkaitik_mugitu') === undefined){
			   $('#modalOpenCookies').modal('show') 
	    } 
 		
		this.cookies();
		if(window.scrollY !== 0){
			 window.scrollTo( 0, 0 );
		}
		
		this.searchHorario(this.state.directionId);
		console.log(this.state.showHeadsign);
		console.log(this.state.twoWay);
	
		var now = new Date();
		var month ="", day="", hour="";
	   	
	   	if((now.getMonth() + 1)<10){
	   		month ="0"+(now.getMonth() + 1);
	   	}else{
	   		month =now.getMonth() + 1;
	   	}
	   	
	   	if(now.getDate()<10){
	   		day ="0"+now.getDate();
	   	}else{
	   		day = now.getDate();
	   	}
	   	
	   	if(now.getHours()<10){
	   		hour = "0"+now.getHours();
	   	}else{
	   		hour = now.getHours();
	   	}
	   	
		document.getElementById("fechaSalida").defaultValue = now.getFullYear()+"-"+month+"-"+day;
	   	document.getElementById("horaSalida").defaultValue = hour+":"+now.getMinutes();
		
		apiLineas.getLineas("", -1, this.props.match.params.parada.replaceAll("%2F", "/")).then((response) => {
			this.setState({ 
				otherLines: response.contenido,
			 }, function () {
			 		console.log(this.state.otherLines);
			 	}
			);
		});
	}
	
	
	cookies(){
		var {t} = this.props;
	   	
		var dir = window.location.origin+"/"+"#";
		 const pathname = window.location.href.substring(dir.length); 	
		const cookies = new CookiesUniversal();
		var cookieAnalitica = cookies.get('cookies_analisis_bizkaitik_mugitu');
 
		if(cookieAnalitica ==="true"){
//			ReactGA.pageview(pathname, ['defaultTracker'], t('keys.menuParadas'));
			ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.menuParadas'), tracking: 'defaultTracker' });

		}else if(cookieAnalitica ==="false") {
//			ReactGA.pageview(pathname, ['anonymousTracker'], t('keys.menuParadas'));
			ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.menuParadas'), tracking: 'anonymousTracker' });

		}
	}
	
	handleLanguage = (langValue) => {
		 this.setState({locale: langValue});
	}
	handlerModeView = (view) => {
	    this.setState({ view: view}); 
	}

	handlerSelectionToogle = () => {
	    if(this.state.showSelections === "none"){
	      this.setState({ showSelections: "block" });
	    }else{
	      this.setState({ showSelections: "none" });
	    }
	}	
	
	mostrar(mas, menos, idButton){
		 var uno = document.getElementById("seeMore"+idButton);
 
		 this.state.valor?uno.innerText = menos:uno.innerText = mas;
		 this.state.valor?document.getElementById('listItemsOcultos'+idButton).classList.remove('oculto'):document.getElementById('listItemsOcultos'+idButton).classList.add('oculto');
		 this.state.valor=!this.state.valor
	}
	
	saveLine = (operador, linea) => {

		var lineas = localStorage.getItem('lineas');
        var setLineas = new Set();

        if(lineas !== null && lineas !== undefined) {
       		var linesObject = JSON.parse(lineas);
       		linesObject.forEach(element => setLineas.add(JSON.stringify(element)));
       		localStorage.removeItem('lineas');
        }

		var lineName;
		if (linea.trim() !== "") {
			lineName = linea;
		} else {
			lineName = this.props.match.params.ruta;
		}

		var lineObject = {
			operador: operador,
			nombreLinea: lineName
		}

		setLineas.add(JSON.stringify(lineObject));

		var arrayLineas = [];
		setLineas.forEach(x => arrayLineas.push(x));

        localStorage.setItem('lineas', '[' + arrayLineas + ']');
        pushWooshAPI.setPushWooshTags();
	}
	
	setIda(){
		this.state.directionId = 0;
		this.searchHorario(this.state.directionId);
		if (document.getElementById("time_vuelta") !== null && document.getElementById("time_vuelta") !== undefined) {
			document.getElementById("time_ida").classList.add("hidden"); 
			document.getElementById("time_vuelta").classList.remove("hidden");
			document.getElementById("time_vuelta_button").classList.remove("active"); 
			document.getElementById("time_ida_button").classList.add("active");
		}
	}
	
	setVuelta() {
		this.state.directionId = 1;
		this.searchHorario(this.state.directionId);
		document.getElementById("time_vuelta").classList.add("hidden"); 
		document.getElementById("time_ida").classList.remove("hidden");
		document.getElementById("time_ida_button").classList.remove("active"); 
		document.getElementById("time_vuelta_button").classList.add("active"); 
	}
	
	verMapa(){
		document.getElementById("content_detail_parada").classList.add("hidden"); 
		document.getElementById("content_detail_parada_mobile").classList.remove("hidden"); 
	}
	
	verHorario(){
		document.getElementById("content_detail_parada").classList.remove("hidden"); 
		document.getElementById("content_detail_parada_mobile").classList.add("hidden"); 
	}
	
	checkFecha(value){
		this.state.startDate = value; 
	}
	
	checkHora(value){
		this.state.startHour = value; 
	} 
	
	searchHorario(directionId) {
	
		var now = new Date();
		var fullDate;
		var mes, dia="";
			if(now.getDate()<10){
				mes = "0"+now.getDate();
			}else{
				mes = now.getDate()
			}
			
			if((now.getMonth()+1)<10){
				dia = "0"+(now.getMonth()+1);
			}else{
				dia = now.getMonth()+1
			}

		if (this.state.startDate === '' && this.state.startHour === '') {
			fullDate = now.getFullYear()+'/'+mes+'/'+dia+" "+now.getHours() + ":" + now.getMinutes();	 
		}else if (this.state.startDate === '' && this.state.startHour !== '') {
			this.state.startDate = document.querySelector('input[name="fechaSalida"]').value;
			fullDate = this.state.startDate.substring(0, 4)+'/'+this.state.startDate.substring(8, 10)+'/'+this.state.startDate.substring(5, 7)+" "+ (this.state.startHour === '' ? "00": this.state.startHour.substring(0, 2))+ ":" + (this.state.startHour === '' ? "00": this.state.startHour.substring(3, 5));
		}else {
			fullDate = this.state.startDate.substring(0, 4)+'/'+this.state.startDate.substring(8, 10)+'/'+this.state.startDate.substring(5, 7)+" "+ (this.state.startHour === '' ? "00": this.state.startHour.substring(0, 2))+ ":" + (this.state.startHour === '' ? "00": this.state.startHour.substring(3, 5));
		}

		this.state.directionId = directionId;

		apiLineas.getHorarios(this.props.match.params.operador, this.props.match.params.idRoute, this.props.match.params.stopId, parseInt(this.state.directionId), fullDate).then((horarios) => {
			if (!horarios.hasOwnProperty('status')) {
				this.setState(() => ({
					datosHorarios: horarios.contenido
				}));
			}
		});	
	}
	
    render() {
 
        const {t} = this.props;
        var horarios;
        var otherLines;

        let styles = [
            {
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "off"
                    }
                ]
            },
            {
                "featureType": "transit",
                "elementType": "labels.icon",
                "stylers": [
                    {
                        "visibility": "on"
                    }
                ]
            }
        ];
          
        if (this.state.datosHorarios!== undefined && this.state.datosHorarios.length >0){
            horarios = this.state.datosHorarios.map((horario, indexH) =>(
                <li key={indexH}>
                    { this.state.showHeadsign === "false" &&
                        <button type="button">{horario.arrivalTime.substring(0,5)}</button>
                    }
                    { this.state.showHeadsign === "true" &&
                        <button type="button">{horario.arrivalTime.substring(0,5)} ({horario.tripHeadSign})</button>
                    }
                </li>
            ));
        }else{
            horarios = "No hay horarios"
        }
 
        if (this.state.otherLines !== undefined && this.state.otherLines.length > 0){
            otherLines = this.state.otherLines.map((line, indexL) =>(
                <li className="item_linea" key={indexL}>
                    <Link to={"/linea_detalle/"+line.agencyName+"/"+line.routeShortName+"/"+line.routeLongName.replaceAll("/","%2F")+"/"+line.routeId+"/"+line.routeType+"/horarios"} className="link">
                        {
                            line.routeType === 3 &&
                            <span className="material-icons" aria-hidden="true">directions_bus</span>
                        }
                        {
                            line.routeType === 2 &&
                            <span className="material-icons" aria-hidden="true">directions_transit_filled</span>
                        }
                        {
                            line.routeType === 1 &&
                            <span className="material-icons" aria-hidden="true">directions_railway</span>
                        }
                        {
                            this.props.match.params.routeType === "7" &&
                            <span className="material-icons" aria-hidden="true">tram</span>
                        }
                        {line.agencyName}
                        <span className="number">{line.routeShortName === "" ? line.routeLongName : line.routeShortName}</span>
                    </Link>
                </li>
            ));
        } 
        var fecha = this.state.startDate.split("-");
    return(
    		
        <React.Fragment>
		<Header handleToUpdate = {this.handlerModeView} action={this.handlerSelectionToogle} title={t('keys.menuParadas')} onSelectLanguage={this.handleLanguage} 
		operador={this.props.match.params.operador} linea={this.props.match.params.linea} ruta={this.props.match.params.ruta} idRoute={this.props.match.params.idRoute}
		routeType={this.props.match.params.routeType}/>
        <div className="section_detail_line ocultar_mapa container" id="section_detail_line">
            <div className="row reverse">
                <div className="content_left col-12 col-lg-4">
                    {/* Cabecera parada */}
                    <div className={this.state.version+ " cabecera_detail_linea"} id="main-content">
                        <h1 className="sr-only no_mobile">{t('keys.lineas')}</h1>
                        <Link to={"/linea_detalle/"+this.props.match.params.operador+"/"+this.props.match.params.linea+"/"+this.props.match.params.ruta+"/"+this.props.match.params.idRoute+"/"+this.props.match.params.routeType+"/horarios"}
                         className="back_planificador">
                            <span className="material-icons">arrow_back</span>
                            <span className="sr-only">{t('keys.volver')}</span>
                        </Link>
                    </div>
                    {/* Contenido parada */}
                    <div className="content_detail_parada" id="content_detail_parada">
                        <div className="cabecera_detail_parada">
                            <h2 className="title">
                                <span className="mark_line" aria-hidden="true">
                                    <span className="line"></span>
                                </span>
                                <span className="text" id="titulo_parada">{this.props.match.params.parada.replaceAll("%2F", "/")}</span>
                            </h2>
                            <button type="button" className="link_more_static" onClick={this.verMapa}>
                                 {t('keys.verEnElMapa')}
                                 <img className="normal" src={IconRifgt} alt=""/>
                                 <img className="hover" src={IconRifgtHover} alt=""/>
                            </button>
                        </div>

                        <div className="summary_detail_parada">
                            <div className="title_body">
                                <h3 className="title">{(parse(t('keys.horariosPrevistos')))}</h3>
                                <p className="title_linea">
                                    {this.props.match.params.operador}
                                    <span className="number_linea">{this.props.match.params.linea.trim() === "" ? this.props.match.params.ruta : this.props.match.params.linea}</span>
                                </p>
                            </div>

                            <button type="button" className="select_date" data-toggle="modal" data-target="#modalOpenHora"><span className="material-icons" aria-hidden="true">calendar_today</span>                            
                            	{
                            		this.state.startDate !== "" && localStorage.getItem('i18nextLng') ==='eu' &&
                            		fecha[0]+"/"+fecha[1]+"/"+fecha[2]
                            	}
                            	{	
                            		this.state.startDate !== "" && localStorage.getItem('i18nextLng') ==='es' &&
                            		fecha[2]+"/"+fecha[1]+"/"+fecha[0]
                            	}
                            	{
                            		this.state.startDate === "" &&
                            		 t('keys.hoy')
                            	}
                            </button>

							{/* Modal open hora */}
	                        <div className="modal_bm modal fade" id="modalOpenHora" tabIndex="-1" role="dialog" aria-labelledby="modalOpenHora" aria-hidden="true">
	                            <div className="modal-dialog modal-dialog-centered" role="document">
	                              <div className="modal-content">
	                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
	                                    <img src={IconClose} alt=""/>
	                                </button>
	                              <form id="modal" className="form_bm" action="/parada_detalle">
	                                  <fieldset id="salida">
	                                      <legend className="title" id="modalOpenHora">{t('keys.horaSalida')}</legend>
	                                      <div className="item ">
	                                          <input id="fechaSalida" type="date" name="fechaSalida" className="form-control" onChange={event=> this.checkFecha(event.target.value)}/>
	                                      </div>
	
	                                      <div className="item ">
	                                         <input id="horaSalida" type="time" name="horaSalida" className="form-control" onChange={event=> this.checkHora(event.target.value)}/>
	                                      </div>
	                                  </fieldset>
	                                  <button type="button" className="button_modal" data-toggle="modal" data-target="#modalOpenHora" onClick={() => this.searchHorario(this.state.directionId)}>
	                                  {t('keys.aplicarFiltros')}
	                                  </button>
	                                  </form>
	                               </div>
	                            </div>
	                        </div>
                        </div>

                        <div className="body_detail_parada">
                            <div className="actions_parada">
                                <button type="button" id="time_ida_button" className="name_direction active" onClick={this.setIda}>
                                    {t('keys.ida')}
                                </button>
								{ this.state.twoWay === "true" &&
	                                <button type="button" id="time_vuelta_button" className="name_direction" onClick={this.setVuelta}>
	                                {t('keys.vuelta')}
	                                </button>
                                }
                            </div>

	                        <div id="time_ida" className="select_time">
                                { this.state.datosHorarios.length === 0 &&
                                    <p className="text_description_center">
                                    {t('keys.nohorarios')}
                                    </p>
                                }
                                { this.state.datosHorarios.length > 0 &&
                                    <React.Fragment>
                                        <ul className="list_items oculto" id="listItemsOcultosIda">
                                            {horarios}
                                        </ul>
                                        <button type="button" className="see_more" id="seeMoreIda" onClick={()=>this.mostrar(t('keys.mostrarMas'), t('keys.mostrarMenos'), "Ida")}>{t('keys.mostrarMas')}</button>
                                    </React.Fragment>
                                }
                            </div>
                            { this.state.twoWay === "true" &&
                                <div id="time_vuelta" className="select_time hidden">
                                    { this.state.datosHorarios.length === 0 &&
                                        <p className="text_description_center">
                                        	{t('keys.nohorarios')}
                                        </p>
                                    }
                                    { this.state.datosHorarios.length > 0 &&
                                        <React.Fragment>
                                            <ul className="list_items oculto" id="listItemsOcultosVuelta">
                                                {horarios}
                                            </ul>
                                            <button type="button" className="see_more" id="seeMoreVuelta" onClick={()=>this.mostrar(t('keys.mostrarMas'), t('keys.mostrarMenos'), "Vuelta")}>{t('keys.mostrarMas')}</button>
                                        </React.Fragment>
                                    }
                                </div>
                            } 

                        </div>
                    </div>

                    {/*Content mapa mobile*/}
                    <div className="content_detail_parada mobile_mapa hidden" id="content_detail_parada_mobile">
                        <div className="cabecera_detail_parada">
                            <h2 className="title">
                                <span className="mark_line" aria-hidden="true">
                                    <span className="line"></span>
                                </span>
                                <span className="text">{this.props.match.params.parada}</span>
                            </h2>
                            <Link to={()=>false} className="link_more_static" onClick={this.verHorario}>
                                 {t('keys.verHorario')}
                                 <img className="normal" src={IconRifgt} alt=""/>
                                 <img className="hover" src={IconRifgtHover} alt=""/>
                            </Link>
                        </div>
                        <div className="container_mapa">
                            <GoogleMap
                                mapContainerStyle={{
                                    }}
                                center={{
                                    lat: parseFloat(this.props.match.params.stopLat),
                                    lng: parseFloat(this.props.match.params.stopLng)
                                    }}
                                zoom={15}
                                options={{
                                  gestureHandling:'greedy',
                                  mapTypeControl: false,
                                  streetViewControl: false,
                                  clickableIcons: true,
                                  zoomControl: false,
                                  fullscreenControl:true,
                                  styles: styles
                                }}
                                onLoad={map => {
                                console.log('onLoad map: ', map)
                                }}
                                // optional
                                onUnmount={map => {
                                console.log('onUnmount map: ', map)
                                }}
                            >
                             <Marker
                                key={`marker-${this.props.match.params.stopName}`}
                                name={this.props.match.params.parada}
                                position={{lat: parseFloat(this.props.match.params.stopLat), lng: parseFloat(this.props.match.params.stopLng)}}
                              >
                              <InfoWindow
                                    options={{ pixelOffset: new window.google.maps.Size(0, -40) }}
                                    position={{lat: parseFloat(this.props.match.params.stopLat), lng: parseFloat(this.props.match.params.stopLng)}}
                                    key={`infowindow-${this.props.match.params.parada}`}
                                    visible={false}>
                                    <span>{this.props.match.params.parada.replaceAll("%2F","/")}</span>
                              </InfoWindow>
                              </Marker>
                            </GoogleMap>
                        </div>
                    </div>

                    <div className="block_color">
                         <div className="container">
                            <div className="other_lineas">
                                <h2 className="subtitle">
                                    {parse(t('keys.otrasLineas'))}
                                </h2>
                                 
                             	<ul className="list_other">
                                	{otherLines}
                                </ul>
                                     
                            </div>
                         </div>
                    </div>
                    
                 	{/* Bloque guardar y compartir */}
                    <div className="section_save_rute">
                        <button className="button_save" type="button" data-toggle="modal" data-target="#modalOpenLineaGuardada" onClick= {() => {this.saveLine(this.props.match.params.operador, this.props.match.params.linea)}} >{t('keys.guardarLinea')}</button>
                        <button className="button_share" type="button" data-toggle="modal" data-target="#modalOpenCompartirLinea">
                            <span className="sr-only">{t('keys.compartirLinea')}</span>
                            <span className="material-icons" aria-hidden="true">share</span>
                        </button>
                    </div>

                    {/* Modal compartir linea */}
                    <CompartirLinea section="lineas" operador={this.props.match.params.operador} line={this.props.match.params.linea.trim() !== "" ? this.props.match.params.linea : this.props.match.params.ruta} />

                    {/* Modal guardar linea */}
                    <GuardarLineas/>

                    {/* Modal ver linea */}
                    <div className="modal_bm big modal fade" id="openMapaLinea" tabIndex="-1" role="dialog" aria-labelledby="openMapaLinea" aria-hidden="true">
                      <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">

                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                              <img src={IconClose} alt=""/>
                            </button>

                            <div className="body_modal">
                                <h2 className="title">{t('keys.mapaRecorrido')}</h2>
                                <div className="content_image">
                                    <img src={ImageMapaEjemplo} alt={t('keys.mapaRecorrido')}/>
                                </div>
                            </div>

                         </div>
                      </div>
                	</div>
                </div>
                <div className="mapa_linea col-12 col-lg-8">

                    <GoogleMap
                        mapContainerStyle={{
                            }}
                        center={{
                            lat: parseFloat(this.props.match.params.stopLat),
                            lng: parseFloat(this.props.match.params.stopLng)
                            }}
                        zoom={15}
                        options={{
                          gestureHandling:'greedy',
                          mapTypeControl: false,
                          streetViewControl: false,
                          clickableIcons: true,
                          zoomControl: false,
                          fullscreenControl:true,
                          styles: styles
                        }}
                        onLoad={map => {
                        console.log('onLoad map: ', map)
                        }}
                        // optional
                        onUnmount={map => {
                        console.log('onUnmount map: ', map)
                        }}
                    >
                     <Marker
			            key={`marker-${this.props.match.params.stopName}`}
			            name={this.props.match.params.parada}
			            position={{lat: parseFloat(this.props.match.params.stopLat), lng: parseFloat(this.props.match.params.stopLng)}}
			          >
			          <InfoWindow
				            options={{ pixelOffset: new window.google.maps.Size(0, -40) }}
				            position={{lat: parseFloat(this.props.match.params.stopLat), lng: parseFloat(this.props.match.params.stopLng)}}
				            key={`infowindow-${this.props.match.params.parada}`}
				            visible={false}>
				            <span>{this.props.match.params.parada.replaceAll("%2F","/")}</span>
					  </InfoWindow>
			          </Marker>
                    </GoogleMap>

                </div>
            </div>
        </div>
        <Footer />
        </React.Fragment>
    		
    );
  }
}
export default  withTranslation()(ParadasDetalleComponent);
