import React, { Component } from 'react';
import { withTranslation } from 'react-i18next'; 

import pushWooshAPI from '../../apiPushWoosh';

import Header from '../general/Header';
import Footer from '../general/Footer';
import ReactGA from 'react-ga4';
import CookiesUniversal from 'universal-cookie';

class ConfiguracionComponent extends Component {

	constructor(props) {
        super(props);
        
        this.state = {
            version: "alertas",
            view: "no_list",
            showSelections: "none",
            locale: "eu_ES"
        };
        
    }

	componentDidMount(){
		this.cookies()
	    this.checkInputs();
	    pushWooshAPI.getPushwooshParams();
        pushWooshAPI.checkIsSuscribed().then((e) => {console.log(e)});

        if(window.scrollY !== 0){
             window.scrollTo( 0, 0 );
        }
              
	}

	 cookies(){
		var {t} = this.props;
			
		var dir = window.location.origin+"/"+"#";
		const pathname = window.location.href.substring(dir.length); 
		 
		const cookies = new CookiesUniversal();
		var cookieAnalitica = cookies.get('cookies_analisis_bizkaitik_mugitu');
		 
		if(cookieAnalitica ==="true"){
				ReactGA.set({ anonymizeIP: true }, ['defaultTracker']);
//				ReactGA.pageview(pathname, ['defaultTracker'], t('keys.menuConfiguracion')); 
				ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.menuConfiguracion'), tracking: 'defaultTracker' });

		}else if(cookieAnalitica ==="false") {
				ReactGA.set({ anonymizeIP: true }, ['anonymousTracker']);
//				ReactGA.pageview(pathname, ['anonymousTracker'], t('keys.menuConfiguracion'));
				ReactGA.send({ hitType: "pageview", page: pathname, title: t('keys.menuConfiguracion'), tracking: 'anonymousTracker' });

		}
	}
	
	checkInputs(){

        var inputIncidencias = localStorage.getItem('inputIncidencias');
        var inputInformacion = localStorage.getItem('inputInformacion');

        if(inputIncidencias !== undefined || inputIncidencias !== null){

            if(inputIncidencias === "true"){
                document.getElementById('inputIncidencias').checked = true;
            }else{
                document.getElementById('inputIncidencias').checked = false;
            }
        }

        if(inputInformacion !== undefined || inputInformacion !== null){

            if(inputInformacion === "true"){
                document.getElementById('inputInformacion').checked = true;
            }else{
                document.getElementById('inputInformacion').checked = false;
            }
        }

	}

    validate() {
        if (document.getElementById('remember').checked) {
            alert("checked");
        } else {
            alert("You didn't check it! Let me check it for you.");
        }
    }

	handleLanguage = (langValue) => {

        this.setState({locale: langValue});
	}

	handlerModeView = (view) => {

        this.setState({ view: view});
	}

	handlerSelectionToogle = () => {

	    if(this.state.showSelections === "none"){
	    	this.setState({ showSelections: "block" });
	    }else{
	    	this.setState({ showSelections: "none" });
	    }
	}

    checkNotificaciones = (tipoNotificacion) => {

        var checkbox = document.getElementById(tipoNotificacion);

        if(checkbox !== null || checkbox !== undefined){
            if (checkbox.checked){
               localStorage.setItem( tipoNotificacion, 'true');
            }else{
                localStorage.removeItem(tipoNotificacion);
            }
            pushWooshAPI.setPushWooshTags();

        }

        var inputIncidencias = localStorage.getItem('inputIncidencias');
        var inputInformacion = localStorage.getItem('inputInformacion');

        if(inputIncidencias === null && inputInformacion === null){
            pushWooshAPI.pushWooshDesuscribe();
        }else{
            pushWooshAPI.pushWooshSuscribe();
        }
	}

	render() {

        const { t } = this.props;

	    return(
            <React.Fragment>
                <Header handleToUpdate = {this.handlerModeView} action={this.handlerSelectionToogle} title={t('keys.menuConfiguracion')}  onSelectLanguage={this.handleLanguage} history={this.props.history} />
                <div className={this.state.version+ " section_content_alerts"} id="main-content">
                    <div className="container">
                       <h1 className="title_h1">{t('keys.eligeNotificaciones')}</h1>
                       <fieldset>
                           <legend className="sr-only">{t('keys.configuraNotificaciones')}</legend>
                           <ul className="list_configuracion">
                               <li className="item">
                                    <div className="text">
                                        <h2 className="subtitle">{t('keys.incidenciaRutas')}</h2>
                                        <p className="description">{t('keys.recibeAlertas')}</p>
                                    </div>
                                    <label className="switch">
                                      <input onClick= {() => {this.checkNotificaciones("inputIncidencias")} } id="inputIncidencias" type="checkbox"/>
                                      <span className="slider"></span>
                                    </label>
                               </li>
                               <li className="item">
                                    <div className="text">
                                        <h2 className="subtitle">{t('keys.informacionTransporte')}</h2>
                                        <p className="description">{t('keys.cambios')}</p>
                                    </div>
                                    <label className="switch">
                                      <input onClick= {() => {this.checkNotificaciones("inputInformacion")} } id="inputInformacion" type="checkbox"/>
                                      <span className="slider"></span>
                                    </label>
                               </li>
                           </ul>
                       </fieldset>
                    </div>
                </div>
                <Footer /> 
            </React.Fragment>
        );
    }
}
export default withTranslation()(ConfiguracionComponent);
