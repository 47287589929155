import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import parse from 'html-react-parser';
import { Link } from 'react-router-dom';

import IconRifgt from '../../images/icons/icon_arrow_right_red.svg';
import IconRifgtHover from '../../images/icons/icono_arrow_red_circle_hover.svg';
 import apiLineas from '../../apiLineas';

class LineasFavoritasComponent extends Component {
	constructor(props) {
	    super(props);

	    this.state = {
	    	version: "app_lineas_favoritas",
	      	locale: "eu_ES",
	   	  	lines: []
	    };
	    this.checkLines = this.checkLines.bind(this);
	  }

	componentDidMount(){

    	this.checkLines();
	}
	
	checkLines() {
		var lineasFav = JSON.parse(localStorage.getItem('lineas'));
		var lineasRec = JSON.parse(localStorage.getItem('lineasRec'));

		var lineasConcat = (...arrays) =>[].concat(...arrays.filter(Array.isArray));
		var concated = lineasConcat(lineasFav, lineasRec);

		if (concated !== null && concated !== undefined) {
		    var lineasSet = new Set();
			concated.forEach(element => (element != null) && lineasSet.add(JSON.stringify(element)));
		    var lineas = [];
			lineasSet.forEach(x => lineas.push(x));
		   	lineas = JSON.parse('[' + lineas + ']');

		  	var linesString = [];
		  	
		    for (let i = 0; i < lineas.length; i++) {
		    	linesString.push(lineas[i].operador + "-" + lineas[i].nombreLinea);	
		    }
		    
   			apiLineas.getLineasLS(linesString.toString()).then(response => {
   				if (!response.hasOwnProperty('status')) {
   					this.setState({lines: this.state.lines.concat(response.contenido)})
   				}
   			});
		}
	}
	
	deleteLine (lineShortName, lineLongName) {
		var lineasFav = JSON.parse(localStorage.getItem('lineas'));
	    var lineasRec = JSON.parse(localStorage.getItem('lineasRec'));
		var lineasFavLength = 0;
		var lineasRecLength = 0;
		
		if (lineasFav !== null && lineasFav !== undefined) {
			lineasFav = this.deleteLineFromArray(lineShortName, lineLongName, lineasFav);
			localStorage.setItem("lineas", JSON.stringify(lineasFav));
			lineasFavLength = lineasFav.length;
		}
		
		if (lineasRec !== null && lineasRec !== undefined) {
			lineasRec = this.deleteLineFromArray(lineShortName, lineLongName, lineasRec);
		    localStorage.setItem("lineasRec", JSON.stringify(lineasRec));
		    lineasRecLength = lineasRec.length;
	    }
	    
	    this.state.lines = [];
	    
	    if (lineasFavLength + lineasRecLength > 0) {
	    	this.checkLines();
	    } else {
	    	this.setState({lines: this.state.lines})
	    }
	}
	
	deleteLineFromArray(lineShortName, lineLongName, array) {
	    var indexToRemove = [];
	    array.forEach((element, index) => (element.nombreLinea === lineShortName ||  element.nombreLinea === lineLongName) && indexToRemove.push(index));
	    indexToRemove.forEach(element => array.splice(element, 1));
	    return array;
	}

	render() {

		const {t} = this.props;
	  	var lines;
	  	console.log(this.state.lines)
		if (this.state.lines.length >0) {
			lines = this.state.lines.map((line, indexLine) => (
			  	<li className="item" key={indexLine}>
			        <div>
			            <h3 className="subtitle">{line.agencyName} | {line.routeShortName}</h3>
			            <p className="description">
			            {
                			line.routeType === 3 &&
                			<span className="material-icons" title={t('keys.bus')} aria-hidden="true">directions_bus</span>
                		}
                    	{
                			line.routeType === 2 &&
                			<span className="material-icons" title={t('keys.tren')} aria-hidden="true">directions_transit_filled</span>
                		}
                    	{
                			line.routeType === 1 &&
                			<span className="material-icons" title={t('keys.metro')} aria-hidden="true">directions_railway</span>
                		}
                    	{
                			line.routeType === 7 || line.routeType === 0 &&
                			<span className="material-icons" title={t('keys.tranvia')} aria-hidden="true">tram</span>
                		}
			               	{line.routeLongName}
			            </p>
			        </div>
			        <Link className="link_all" rel="noreferrer" to={"/linea_detalle/"+line.agencyName+"/"+(line.routeShortName === ""?"%20":line.routeShortName)+"/"+line.routeLongName.replaceAll("/","-")+"/"+line.routeId+"/"+line.routeType+"/horarios"}>
			            <span className="sr-only">{t('keys.verInfo')}</span>
			        </Link>
			        <button type="button" className="link" id="dropdownMenuLineas" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
			            <span className="material-icons" aria-hidden="true">more_vert</span>
			            <span className="sr-only">Ver opciones</span>
			        </button>
			        <div className="dropdown_bm dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuLineas">
			            <button type="button" className="dropdown-item" onClick={() => {this.deleteLine(line.routeShortName, line.routeLongName)}}>
			                <span className="material-icons">delete</span>
			                {t('keys.borrar')}
			            </button>
			            <a className="dropdown-item" href="/#/configuracion">
			                <span className="material-icons">settings</span>
			                {t('keys.configurar')}
			            </a>
			        </div>
			    </li>
 			));
		}
    return(

		<div className={this.state.lines.length > 0 ? "section_lineas_favoritas container" : "section_lineas_favoritas container hidden"}>
			<h2 className="title" id="lineasGuardadas">{parse(t('keys.recientesLineas'))}</h2>
	        <ul className="lineas_list">
				{lines}
	        </ul>
	        <a className="link_more" href="/#/mis_lineas" rel="noreferrer" id="lineas_guardadas">
	        {t('keys.todasmisLineasGuardadas')}
	            <img className="normal" src={IconRifgt} alt=""/>
	            <img className="hover" src={IconRifgtHover} alt=""/>
	        </a>
		</div>
    );
  }
}
export default withTranslation()(LineasFavoritasComponent);